import './css/Components.css';

const stylesLink = {
    padding: '0px',
    color: 'white',
    textDecoration: 'none'
};

export const Footer = () => (
    <div className="foot" style={stylesLink}>
        
        <p>Copyright 2021 INVESTIM</p>
        
    </div>
);