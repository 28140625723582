import {arrayCashier} from './widgets/JsonData';
import {GeneratorItems} from './widgets/GeneratorItems';

const styles = {
    background: 'darkorange',
    padding: '25px',
    marginBottom: '55px',
    opacity: '1',
    textAlign: 'justify',
    borderRadius: '5px'
};

const headerr = {
    background: "darkorange",
    paddingTop: "10px",
    paddingBottom: "10px",
    textAlign: "center",
    borderRadius: "10px",
    fontSize: "large",
    color:"whitesmoke"
}

export const Cashregister = () => (
    <div className="maincontent">
        <div style={headerr}>
            <h2>Kasy fiskalne: </h2>
        </div>
        <div style={styles}>
            {GeneratorItems(arrayCashier)}
        </div>
    </div>
)