//cashiers
import bonoOnline from './sortofneedfromnovitu/ItemPhotos/onlineFis/bono300x300_0.png';
import deonOnline from './sortofneedfromnovitu/ItemPhotos/onlineFis/deon300x300.png';
import hdOnline from './sortofneedfromnovitu/ItemPhotos/onlineFis/hd300x300.png';
import hdOnlineWolny from './sortofneedfromnovitu/ItemPhotos/onlineFis/hd_online_ww1.jpg';
import linkOnline from './sortofneedfromnovitu/ItemPhotos/onlineFis/kasa_link3_b_0.png';
import linkOnlineGSM24 from './sortofneedfromnovitu/ItemPhotos/onlineFis/kasa_link3_b_0.png';
import malaOnline from './sortofneedfromnovitu/ItemPhotos/kasyfis/mala-plus-e_900px.jpg';
import sentoLanE from './sortofneedfromnovitu/ItemPhotos/kasyfis/sento-lan-e_900px.jpg';
import nanoE from './sortofneedfromnovitu/ItemPhotos/cashierE/nanoe_900x9005.png';


//cashiers better photos
import bonoOnline1 from './sortofneedfromnovitu/ItemPhotos/OnlineCashRegisters/bono_online_1.png';
import bonoOnline2 from './sortofneedfromnovitu/ItemPhotos/OnlineCashRegisters/bono_online_2.png';
import deonOnline01 from  './sortofneedfromnovitu/ItemPhotos/OnlineCashRegisters/Deon_online_01.png';
import deonOnline03_paragon from './sortofneedfromnovitu/ItemPhotos/OnlineCashRegisters/Deon_online_03_paragon.png';
import hdOnline01 from './sortofneedfromnovitu/ItemPhotos/OnlineCashRegisters/HD-online-(2018)---01.png';
import hdOnline02 from './sortofneedfromnovitu/ItemPhotos/OnlineCashRegisters/HD-online-(2018)---02.png';
import malaOnline01 from './sortofneedfromnovitu/ItemPhotos/OnlineCashRegisters/Mala_online_01.png';
import malaOnline03 from './sortofneedfromnovitu/ItemPhotos/OnlineCashRegisters/Mala_online_03.png';
import nanoOnline03 from './sortofneedfromnovitu/ItemPhotos/OnlineCashRegisters/Nano_online_03.png';
import nanoOnline03_paragon from './sortofneedfromnovitu/ItemPhotos/OnlineCashRegisters/Nano_online_03_paragon.png';
import oneOnline_biala7 from './sortofneedfromnovitu/ItemPhotos/OnlineCashRegisters/novitus_ONE_online_biala_7.png'
import oneOnline_biala8 from './sortofneedfromnovitu/ItemPhotos/OnlineCashRegisters/novitus_ONE_online_biala_8.png'
import oneOnline_czarna1 from './sortofneedfromnovitu/ItemPhotos/OnlineCashRegisters/novitus_ONE_online_czarna_1.png'
import oneOnline_czarna2 from './sortofneedfromnovitu/ItemPhotos/OnlineCashRegisters/novitus_ONE_online_czarna_2.png'
import sentoOnline01 from './sortofneedfromnovitu/ItemPhotos/OnlineCashRegisters/Sento_online_01.png'
import sentoOnline02 from './sortofneedfromnovitu/ItemPhotos/OnlineCashRegisters/Sento_online_02.png'



//cashiers online
import nano2Online from './sortofneedfromnovitu/ItemPhotos/onlineFis/nano2online_bialy_skos1_0.jpg';
import nanoOnline from './sortofneedfromnovitu/ItemPhotos/onlineFis/nano300x300_0.png';
import nanoGSM from './sortofneedfromnovitu/ItemPhotos/onlineFis/nano2online_czarny_skos1.jpg';
import nextOnline from './sortofneedfromnovitu/ItemPhotos/onlineFis/next_900px_4.jpg';
import oneOnline from './sortofneedfromnovitu/ItemPhotos/onlineFis/one1_300px.jpg';
import oneOnlineGSM24 from './sortofneedfromnovitu/ItemPhotos/onlineFis/one1_300px.jpg';
import sentoOnline from './sortofneedfromnovitu/ItemPhotos/onlineFis/sento300x300.png';

//printers
import bonoApteka from './sortofneedfromnovitu/ItemPhotos/drukarkifis/bono-apteka-e_900px_1.jpg';
import bonoLan from './sortofneedfromnovitu/ItemPhotos/drukarkifis/bono-lan-e_900px_0.jpg';
//import deonOnline from './sortofneedfromnovitu/ItemPhotos/onlineFis/deon300x300.png';
//import hdOnline from './sortofneedfromnovitu/ItemPhotos/drukarkifis/hd300x300.png';
import hdEApteka from './sortofneedfromnovitu/ItemPhotos/drukarkifis/hd-apteka-e_900px.jpg';

//terminals:
import nanoELexTerminal from "./sortofneedfromnovitu/ItemPhotos/payTerminals/50nano-e-lex-terminal.png";
import sentoETerminal from "./sortofneedfromnovitu/ItemPhotos/payTerminals/sento-e-terminal_1.png";
import terminal_ict220_em from "./sortofneedfromnovitu/ItemPhotos/terminals/terminal_ict220_em.png";
import iwl250_40mm_iwl250_inthehand_cartemdjpg from './sortofneedfromnovitu/ItemPhotos/paytele/iwl250-40mm-iwl250-inthehand-cartemdjpg.jpg'
import ict220_04w_1200_small from './sortofneedfromnovitu/ItemPhotos/paytele/ict220_04,w_1200,_small.jpg';
import desk3200_3w_1200_small from "./sortofneedfromnovitu/ItemPhotos/paytele/desk3200_3,w_1200,_small.jpg";
import lane3000_1w_1200_small from "./sortofneedfromnovitu/ItemPhotos/paytele/lane3000_1,w_1200,_small.jpg";

import nextNovitusBanner from "./sortofneedfromnovitu/ItemPhotos/payTerminals/nextNovitusBanner.jpg";
//export const ImagesData = () => { 

//Labellers:
import mx5500_0 from './sortofneedfromnovitu/ItemPhotos/Labellers/mx_5500_0.jpeg';
import mx_2616 from './sortofneedfromnovitu/ItemPhotos/Labellers/mx_2616.jpeg';
import meto_eagle_2026 from './sortofneedfromnovitu/ItemPhotos/Labellers/meto_eagle_2026.jpg';


//Scales:
import dibalW025t from "./sortofneedfromnovitu/ItemPhotos/Inne/dibal_w025t.jpg";
import dibalBH from "./sortofneedfromnovitu/ItemPhotos/Scales/dibal_bh.jpeg";
import dibalAHW_T from "./sortofneedfromnovitu/ItemPhotos/Scales/ahwT-Scale.jpg";
import dibal_CR from "./sortofneedfromnovitu/ItemPhotos/Scales/dibal_cr.jpeg";
import dibal_d_955db_7_cali from "./sortofneedfromnovitu/ItemPhotos/Scales/dibal_d-955db_7_cali.png";
import dibal_d_955db_12_cali from "./sortofneedfromnovitu/ItemPhotos/Scales/dibal_d-955db_12_cali.png";
import dibal_d_955s from "./sortofneedfromnovitu/ItemPhotos/Scales/dibal_d-955s.png";
import dibal_d_pos_wysiegnik_0 from "./sortofneedfromnovitu/ItemPhotos/Scales/dibal_d-pos_wysiegnik_0.jpg";
import dibal_dmi_610 from "./sortofneedfromnovitu/ItemPhotos/Scales/dibal_dmi-610.jpg";
import dibal_g_300__305 from "./sortofneedfromnovitu/ItemPhotos/Scales/dibal_g-300__305.jpeg";
import dibal_g_300 from "./sortofneedfromnovitu/ItemPhotos/Scales/dibal_g-300.jpeg";
import dibal_g_310 from "./sortofneedfromnovitu/ItemPhotos/Scales/dibal_g-310.jpeg";
import dibal_ks_400 from "./sortofneedfromnovitu/ItemPhotos/Scales/dibal_ks-400.png";
import dibal_m_525db from "./sortofneedfromnovitu/ItemPhotos/Scales/dibal_m-525db.jpg";
import dibal_m_525h from "./sortofneedfromnovitu/ItemPhotos/Scales/dibal_m-525h.jpg";
import dibal_m_525s_a from "./sortofneedfromnovitu/ItemPhotos/Scales/dibal_m-525s_a.jpg";
import dibal_m_525ss from "./sortofneedfromnovitu/ItemPhotos/Scales/dibal_m-525ss.jpg";
import dibal_m_525t_a from "./sortofneedfromnovitu/ItemPhotos/Scales/dibal_m-525t_a.jpg";
import dibal_m_525t from "./sortofneedfromnovitu/ItemPhotos/Scales/dibal_m-525t.jpg";
import dibal_pi_100s_rs from "./sortofneedfromnovitu/ItemPhotos/Scales/dibal_pi-100s_rs.png";
import dibal_pi_100t_rs from "./sortofneedfromnovitu/ItemPhotos/Scales/dibal_pi-100t_rs.png";
import dibal_ps_50 from "./sortofneedfromnovitu/ItemPhotos/Scales/dibal_ps-50.jpg";
import dibal_pvc_50 from "./sortofneedfromnovitu/ItemPhotos/Scales/dibal_pvc-50.jpeg";
import dibal_s_545db from "./sortofneedfromnovitu/ItemPhotos/Scales/dibal_s-545db.jpg";
import dibal_spc_s from "./sortofneedfromnovitu/ItemPhotos/Scales/dibal_spc-s.jpeg";
import dibal_spc_t from "./sortofneedfromnovitu/ItemPhotos/Scales/dibal_spc-t.jpg";
import dibal_w025s from "./sortofneedfromnovitu/ItemPhotos/Scales/dibal_w025s.jpg";
import dibal_w025t from "./sortofneedfromnovitu/ItemPhotos/Scales/dibal_w025t.jpg";
import neptun_2__magellan from "./sortofneedfromnovitu/ItemPhotos/Scales/neptun_2__magellan.jpg";
import saturn_2 from "./sortofneedfromnovitu/ItemPhotos/Scales/saturn_2.jpg";
import t_scale_ahc from "./sortofneedfromnovitu/ItemPhotos/Scales/t-scale_ahc.jpg";
import t_scale_bws_mala from "./sortofneedfromnovitu/ItemPhotos/Scales/t-scale_bws_-_mala.jpg";
import t_scale_bws_40_50 from "./sortofneedfromnovitu/ItemPhotos/Scales/t-scale_bws_40_50.jpg";
import t_scale_s29_0 from "./sortofneedfromnovitu/ItemPhotos/Scales/t-scale_s29_0.jpg";
import t_scale_tp_i from "./sortofneedfromnovitu/ItemPhotos/Scales/t-scale_tp-i.jpg";
import vega_2 from "./sortofneedfromnovitu/ItemPhotos/Scales/vega_2.jpg";
import waga_pris_ep200_1_ekran from "./sortofneedfromnovitu/ItemPhotos/Scales/waga_pris_ep200_1_ekran.png";
import waga_pris_ep200_4_ekran from "./sortofneedfromnovitu/ItemPhotos/Scales/waga_pris_ep200_4_ekran.png";
import waga_pris_ew200_2_ekran from "./sortofneedfromnovitu/ItemPhotos/Scales/waga_pris_ew200_2_ekran.png";

//Drawers
import drawer1 from "./sortofneedfromnovitu/ItemPhotos/drawers/drawer1.jpg";

import duza_szuflada from "./sortofneedfromnovitu/ItemPhotos/drawers/szyflada-duza.jpg";
import srednia_szuflada from "./sortofneedfromnovitu/ItemPhotos/drawers/szuflada_srednia.jpg";
import aktywna_szuflada_deon_e_nano_e from "./sortofneedfromnovitu/ItemPhotos/drawers/szuflada_aktywna_do_deon_e_nano_e_9.jpg";
import pasywna_szuflada_sentoLanE from "./sortofneedfromnovitu/ItemPhotos/drawers/szuflada_pasywna_sento_lan_e2.png";
import aktywna_srednia_szuflada from "./sortofneedfromnovitu/ItemPhotos/drawers/szuflada_srednia_aktywna4.jpg";
import flip_top_szuflada from "./sortofneedfromnovitu/ItemPhotos/drawers/szuflada_typu_flip_top_16_0.jpg";


//POS Storyous
import POS_ello from "./sortofneedfromnovitu/ItemPhotos/STORYOUS/ello.png";
import POS_kelnermobilny from "./sortofneedfromnovitu/ItemPhotos/STORYOUS/kelnermobilny.png";
import POS_kitchen_screen2x from "./sortofneedfromnovitu/ItemPhotos/STORYOUS/kitchen-screen2x.png";
import POS_kitchen_screen2x_1 from "./sortofneedfromnovitu/ItemPhotos/STORYOUS/kitchen-screen2x_1.png";
import POS_lenovo10 from "./sortofneedfromnovitu/ItemPhotos/STORYOUS/lenovo10-e1596785156810.png";

//POS  Soga
import SOGApos_tablet_zestaw_soga_900px from "./sortofneedfromnovitu/ItemPhotos/Soga/npos_tablet_zestaw-soga_900px.jpg";
import SOGApos_tablet_900px_1_700x700 from "./sortofneedfromnovitu/ItemPhotos/Soga/npos_tablet_900px_1-700x700.jpg";
import SOGApos_zdj_quant2_thumb_SMALL from "./sortofneedfromnovitu/ItemPhotos/Soga/zdj_quant2_thumb_SMALL.jpg";
import SOGApos_zdj_quant2_900px_0_MEDIUM from "./sortofneedfromnovitu/ItemPhotos/Soga/zdj_quant2_900px_0_MEDIUM.jpg"
import SOGApos_zdj_quant_thumb_LARGE from "./sortofneedfromnovitu/ItemPhotos/Soga/zdj_quant_900px_0_LARGE.jpg";
import SOGApos__zdj_quant_thumb_MEGA from "./sortofneedfromnovitu/ItemPhotos/Soga/_zdj_quant_thumb_MEGA.jpg";

//Pagers:
import PAGER_base from "./sortofneedfromnovitu/ItemPhotos/accesories_gastro/sc15-3_baza.jpg";
import PAGER_white_client from "./sortofneedfromnovitu/ItemPhotos/accesories_gastro/przywolywacz_bialy_przycisk_klienta.jpg";
import PAGER_sticker_client from "./sortofneedfromnovitu/ItemPhotos/accesories_gastro/przywolywacz_(number_sticker).jpg";
import PAGER_transmiter from "./sortofneedfromnovitu/ItemPhotos/accesories_gastro/programator(transmiter).jpg";
import PAGER_charger from "./sortofneedfromnovitu/ItemPhotos/accesories_gastro/ladowarka1.jpg";
import PAGER_watch_client from "./sortofneedfromnovitu/ItemPhotos/accesories_gastro/ape6900-3.jpg";

//Maps:
import MapContact_gif from "./sortofneedfromnovitu/maps/mapa.gif";

//Scanners
import Datalogic_QUICKSCAN_I_QM2131 from "./sortofneedfromnovitu/ItemPhotos/product_scanners/Datalogic_QUICKSCAN_I_QM2131.jpg";
import Datalogic_QUICKSCAN_I_QM2400 from "./sortofneedfromnovitu/ItemPhotos/product_scanners/Datalogic_QUICKSCAN_I_QM2400.jpg";
import Datalogic_GRYPHON_GM4400_2D from "./sortofneedfromnovitu/ItemPhotos/product_scanners/gm_4400_2d-blf-cradleup_Datalogic_GRYPHON_GM4400_2D.jpg";
import Datalogic_GRYPHON_GM4100 from "./sortofneedfromnovitu/ItemPhotos/product_scanners/gryphon-gm4100_Datalogic_GRYPHON_GM4100.jpg";

import NEWLAND_HR1550_3E_WAHOO from "./sortofneedfromnovitu/ItemPhotos/product_scanners/hr1550_wahoo_NEWLAND_HR1550-3E_WAHOO.jpg";
import Zebra_DS2278 from "./sortofneedfromnovitu/ItemPhotos/product_scanners/ds2278_in_cradle_135-web-72dpi_Zebra_DS2278.jpeg";
import NEWLAND_HR3260_CS_MARLIN from "./sortofneedfromnovitu/ItemPhotos/product_scanners/hr3260_marlin_NEWLAND_HR3260-CS_MARLIN.jpg";
import black_Zebra_LI4278_dawniej_Motorola from "./sortofneedfromnovitu/ItemPhotos/product_scanners/li4278_linear_imager_-_black_Zebra_LI4278-dawniej_Motorola.jpg";
import Datalogic_GRYPHON_GM4500_2D from "./sortofneedfromnovitu/ItemPhotos/product_scanners/plp-gryphongbt_gm4500-in-stand-black-left-facing_0_Datalogic_GRYPHON_GM4500_2D.jpg";
import Datalogic_QUICKSCAN_I_QBT2400_2D from "./sortofneedfromnovitu/ItemPhotos/product_scanners/qbt2131_czarny_lewy_profil_Czytnik_Datalogic_QUICKSCAN_I_QBT2400_(2D).jpg";
import qbt2131_czarny_lewy_profil from "./sortofneedfromnovitu/ItemPhotos/product_scanners/qbt2131_czarny_lewy_profil.jpg";
import Honeywell_Voyager_1202g from "./sortofneedfromnovitu/ItemPhotos/product_scanners/voyager_1202g_p_01small_Honeywell_Voyager_1202g.jpg";
import Honeywell_Xenon_1902 from "./sortofneedfromnovitu/ItemPhotos/product_scanners/xenon_1902_product_01small_Honeywell_Xenon_1902.jpg";
import Cipherlab_1664 from "./sortofneedfromnovitu/ItemPhotos/product_scanners/111226pl_1664_72dpi_small_Cipherlab_1664.jpg";
import bs8050_2v_NEWLAND_BS8050_2V_2D_i_BS8050_3V_1D_PIRANHA from "./sortofneedfromnovitu/ItemPhotos/product_scanners/bs8050-2v_NEWLAND_BS8050-2V_(2D)_i_BS8050-3V_(1D)_PIRANHA.png";
import Cipherlab_1564A from "./sortofneedfromnovitu/ItemPhotos/product_scanners/Cipherlab_1564A.jpg";
import cipherlab_1660 from "./sortofneedfromnovitu/ItemPhotos/product_scanners/cipherlab_1660.jpg";
import cipherlab_15601_Cipherlab_1560P from "./sortofneedfromnovitu/ItemPhotos/product_scanners/cipherlab_15601_Cipherlab_1560P.jpg";
//import MapContact_gif from "./sortofneedfromnovitu/maps/mapa.gif";


//to implement in future probe
function addImportImages(srcPathArray){
   let outputImportsWithIndex = srcPathArray.map((file, index)=>{

    });
    return outputImportsWithIndex;
};

export const ImagesOnlineRegistersGoodQualityPhotosPng = {
    bonoOnline1,
    bonoOnline2,
    deonOnline01,
    deonOnline03_paragon,
    hdOnline01,
    hdOnline02,
    malaOnline01,
    malaOnline03,
    nanoOnline03,
    nanoOnline03_paragon,
    oneOnline_biala7,
    oneOnline_biala8,
    oneOnline_czarna1,
    oneOnline_czarna2,
    sentoOnline01,
    sentoOnline02,
}

export const ImageBanners = {
    nextNovitusBanner
};

export const ImagesCashiers = {
    bonoOnline,
    deonOnline,
    hdOnline,
    hdOnlineWolny,
    linkOnline,
    linkOnlineGSM24,
    malaOnline,
    sentoLanE,
    nextOnline,
    nanoE
};

export const ImagesOnline = {
    nano2Online,
    nanoOnline,
    nanoGSM,
    nextOnline,
    oneOnline,
    oneOnlineGSM24,
    sentoOnline,
    malaOnline,
    linkOnline,
    linkOnlineGSM24
};

export const ImagesPrinters = {
    bonoOnline,
    deonOnline,
    hdOnline,
    hdOnlineWolny,
    bonoApteka,
    bonoLan,
    hdEApteka
};

export const ImagesPayTerminals = {
    nanoELexTerminal,
    sentoETerminal,
    terminal_ict220_em,
    iwl250_40mm_iwl250_inthehand_cartemdjpg,
    ict220_04w_1200_small,
    desk3200_3w_1200_small,
    lane3000_1w_1200_small
}; 

export const ImagesScales = {
    dibalW025t,
    dibalBH,
    dibalAHW_T,
    dibal_CR,
    dibal_d_955db_7_cali,
    dibal_d_955db_12_cali,
    dibal_d_955s,
    dibal_d_pos_wysiegnik_0,
    dibal_dmi_610,
    dibal_g_300__305,
    dibal_g_300, 
    dibal_g_310,
    dibal_ks_400,
    dibal_m_525db,
    dibal_m_525h,
    dibal_m_525s_a,
    dibal_m_525ss,
    dibal_m_525t_a,
    dibal_m_525t, 
    dibal_pi_100s_rs,
    dibal_pi_100t_rs,
    dibal_ps_50,
    dibal_pvc_50,
    dibal_s_545db,
    dibal_spc_s, 
    dibal_spc_t,
    dibal_w025s,
    dibal_w025t,
    neptun_2__magellan,
    saturn_2,
    dibal_w025s,
    t_scale_ahc,
    t_scale_bws_mala, 
    t_scale_bws_40_50,
    t_scale_s29_0, 
    t_scale_tp_i,
    vega_2,
    waga_pris_ep200_1_ekran,
    waga_pris_ep200_4_ekran,
    waga_pris_ew200_2_ekran
};

export const ImagesLabellers = {
    mx5500_0,
    mx_2616,
    meto_eagle_2026
}; 

export const ImageDrawers = {
    drawer1,
    duza_szuflada,
    srednia_szuflada, 
    aktywna_szuflada_deon_e_nano_e,
    pasywna_szuflada_sentoLanE, 
    aktywna_srednia_szuflada,
    flip_top_szuflada

};

export const ImagePOS = {
    //Storyous:
    POS_ello,
    POS_kelnermobilny,
    POS_kitchen_screen2x,
    POS_kitchen_screen2x_1,
    POS_lenovo10,
    //SOGA
    SOGApos_tablet_zestaw_soga_900px,
    SOGApos_tablet_900px_1_700x700,
    SOGApos_zdj_quant2_thumb_SMALL,
    SOGApos_zdj_quant2_900px_0_MEDIUM,
    SOGApos_zdj_quant_thumb_LARGE,
    SOGApos__zdj_quant_thumb_MEGA
};

export const ImagePagers = {
    PAGER_base,
    PAGER_white_client,
    PAGER_sticker_client,
    PAGER_transmiter,
    PAGER_charger,
    PAGER_watch_client
};

export const ImagesMaps = {
    MapContact_gif
};

export const ImagesScaners = {
    Cipherlab_1664,
    Cipherlab_1564A,
    cipherlab_1660,
    cipherlab_15601_Cipherlab_1560P,

    Datalogic_QUICKSCAN_I_QM2131,
    Datalogic_QUICKSCAN_I_QM2400,
    Datalogic_GRYPHON_GM4400_2D,
    Datalogic_GRYPHON_GM4100,
    Datalogic_GRYPHON_GM4500_2D,
    Datalogic_QUICKSCAN_I_QBT2400_2D,
    qbt2131_czarny_lewy_profil,

    Honeywell_Voyager_1202g,
    Honeywell_Xenon_1902,

    NEWLAND_HR1550_3E_WAHOO,
    NEWLAND_HR3260_CS_MARLIN,
    bs8050_2v_NEWLAND_BS8050_2V_2D_i_BS8050_3V_1D_PIRANHA,
    Zebra_DS2278,
    black_Zebra_LI4278_dawniej_Motorola
}