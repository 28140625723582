import investimlogo from "./widgets/sortofneedfromnovitu/logo/investimlogo.jpg";
import { Link } from 'react-router-dom';
const styles = {
    height: '100%',
    width: '172px'
};
const styles2 ={
    position: 'absolute',
    left: '-59px'
};

export function Logo(){
    return (<div style={styles2}>
              <Link to="/"><img alt="logo" style={styles} src={investimlogo}/></Link>
            </div>)
}