import React from 'react';
//import './css/Burgermenu.css';
import {Link} from 'react-router-dom';
//import {BurgerToggle} from './BurgerToggle';
import {Home} from '../Home';
import {useState, useEffect} from 'react';

const styleBurgerButton = {
    right: '25px',
    position: 'absolute',
    minHeight: '50px',
    minWidth: '80px'
};

const styleTopBurgerNavbar = {
    top:'0px',
    width: '100%',
    minHeight: '50px',
    position: 'fixed',
    borderRadius: '0px'
};

const none = {
    display: 'none'
};


export const BurgerNav = () => {

  useEffect(()=>{

    //let element = document.getElementById('');
    //element.addEventListener('click',()=>{
    //}); 
  
//    (function () {
//       var hamburger = {
//           navToggle: document.querySelector(".navbar-toggler"),
//           nav: document.querySelector("navbar"),
//           doToggle: function (e) {
//               e.preventDefault();
//               this.navToggle.classList.toggle("expanded");
//               this.nav.classList.toggle("expanded");
//           },
//       };
//       hamburger.navToggle.addEventListener("click", function (e) {
//           hamburger.doToggle(e);
//       });
//       hamburger.nav.addEventListener("click", function (e) {
//           hamburger.doToggle(e);
//       });
//   })();
  
   
    let element = document.querySelector('.navbar-toggler');
    let element2 = document.querySelector(".navbar");
    let element3 = document.querySelector(".navbar-burger");

    element.addEventListener('click', (e)=>{
          e.preventDefault();
          element.classList.toggle("expanded");
          //element2.classList.toggle("expanded");
          console.log("bip bippp!");
    });
     
    element2.addEventListener('click', (e)=>{
       e.preventDefault();
       //element.classList.toggle("expandede");
       element2.classList.toggle("expanded");
       console.log("bi biii!");
    });

    element.addEventListener('click', (e)=>{
      e.preventDefault();
      //element.classList.toggle("expandede");
      element3.classList.toggle("expanded");
      console.log("bi biii biii!");
    });

  });

return( 
  <div className="navbar" style={styleTopBurgerNavbar}>
  <button style={styleBurgerButton} className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent15" aria-controls="navbarSupportedContent15" aria-expanded="false" aria-label="Toggle navigation">
    <span className="navbar-toggler-icon">|||</span>
  </button>
    <ul className="navbar-burger">
      <Link to="/productslist2">Produkty</Link>
      <Link to="/cash_register2">Kasy fiskalne</Link>
      <Link to="/fiscal_printers2">Drukarki fiskalne</Link>
      <Link to="/online2">Kasy i drukarki Online</Link>
      <Link to="/payment_terminal2">Terminale</Link>
      <Link to="/scales">Wagi</Link>
      <Link to="/payment_terminal2">Szuflady</Link>
      <Link to="/payment_terminal2">Czytniki</Link>
      <Link to="/payment_terminal2">Metkownice</Link>
      <Link to="/new_products2">Nowości</Link>
      <Link to="/contact2">Kontakt</Link>
    </ul>  
  </div>
)};