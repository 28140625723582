import './css/Navbar1.css';
import './widgets/css/Burgermenu.css';
import { NavLink } from 'react-router-dom';
import Navbar from 'react-bootstrap/Nav';
import { Products } from './widgets/Toggles';
import {Logo} from './Logo'; 
//import {BurgerNav} from './widgets/BurgerNav';
import {SearcherHooks} from './widgets/SearcherHooks';

const stylesLink = {
        padding: '0px',
        color: 'white',
        textDecoration: 'none'
};

const nav = {
        padding: '0px',
        weight: '100%',
        minWidth: '380px'
};

const styleNav = {
        overflow: 'hidden'
};

const clear = {
    //clear: "both"
}

export const Navbar1 = () => 
{
return(
    <Navbar className="navbar" style={nav}>
        <NavLink to="/" style={stylesLink}></NavLink>
        <Logo />
        <ul className="nav-links" style={styleNav}>
            <span style={stylesLink}><Products /></span>
            <NavLink to="/contact" style={stylesLink}><li>Kontakt</li></NavLink>           
        </ul>
        <span style={clear}>{SearcherHooks()}</span>
    </Navbar>
)};
//<NavLink to="/news" style={stylesLink}><li>Aktualności</li></NavLink>