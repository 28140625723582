import { useEffect, useState } from 'react';
import Fuse from 'fuse.js';
import {allArraysOfProducts, allArraysOfProductsUnique, arrayCashier, arrayCashierOnline, 
        arrayCzytniki1, arrayDrawers, 
        arrayGastroAccesories, arrayInfoForLaw, 
        arrayLabellers, arrayMetkownice, 
        arrayPayTerminals, arrayPOS, 
        arrayPrinters, arrayScales} from './JsonData';
import { Link } from 'react-router-dom';

function SearcherHooks(){
   
   
    const {} = arrayPrinters;
    const [query, updateQuery] = useState('');
    //const [isBottomShow, setShowBottom] = useState(false);
    const fuse = new Fuse(allArraysOfProductsUnique, {
      keys: [
        'name',
        'describe',
        'url'
      ],
      threshold: 0.6,
      includeMatches: true,
      //includeScore: true,
      //useExtendedSearch: true
      //isCaseSensitive: true
    });
    const empty = [];
    const fuseSearched = fuse.search(query);

    function validateProductResult(query){
        let productItem = [];
        let empty = [];
        if(query){
            productItem = fuseSearched.map((product)=>{
                return product.item;
            });
        }else{
            absoluteStyle.overflowX = "hidden";
            productItem = empty;
        };
        return productItem;
    };

/*
    const productResults = query ? fuseSearched.map((product) =>{    
        return product.item
    }):empty;// : arrayPrinters; 
    //const queryResults = query ? results.map(product => product.item) : array;
*/
    
    function onSearch({ currentTarget }) {
        updateQuery(currentTarget.value ? currentTarget.value : "");
        //check:
        //console.log(query);
    }

    function onExit({ currentTarget }){
        updateQuery(currentTarget.value ? currentTarget.values="" : "");
        //setShowBottom()
        //updateQuery(currentTarget.style.display='block' ? currentTarget.style.display="none" : currentTarget.style.display='block');
    }

    function forData(items){
        let content = [];
        for (let idx in items){
         //const item = items[idx];
          content.push(<li style={listStyles} key={idx}>{items[idx]}</li>);
        }
        //console.log("contento:"+content);
        return content;
    };

 
    function objCompare( thisObj, nextObj ) {
        return Object.keys( thisObj ).sort().toString() === Object.keys( nextObj ).sort().toString() && 
               Object.values( thisObj ).sort().toString() === Object.values( nextObj ).sort().toString();
    }
    
    function filterDuplicates( array ) {
        return array.filter( ( element, index ) => {
            return index === array.findIndex( toCompare => 
                                             objCompare( element, toCompare ) );
        });
    }

    const searchBarStyle = {
        width: "15%",
        right: "5%",
        float: "right",
        color: "whitesmoke",
        margin: "auto",
        position: "absolute",
        top: "10px",
        zIndex: "250"
    }

    const absoluteStyle = {
        position: "absolute",
        //backgroundColor: "whitesmoke",
        zIndex: "10",
        width: "235%",
        margin: "auto",
        textAlign: "center",
        paddingRight: "15px",
        color: "darkblue",
        display: "flex",
        //right: "62vw",
        left: "-625%",
        //left: "-58vw",
        overflowX: "scroll",
        //width: "initial",
        //width: "-moz-available",
        width: "767%",
        //overflowX: "hidden",
        //background: "#81B622",
        background: "#94C973",
        //backgroundColor:"#ECF87F",
        borderRadius: "5px",
        top: "70px"
    }

    const listStyles1 = {
        listStyleType: "none",
        textAlign: "justify",
        //backgroundColor: "whitesmoke",
        zIndex:"100"
    }

    const listStyles = {
        listStyleType: "none",
        //textAlign: "justify",
        backgroundColor: "whitesmoke",
        zIndex:"100",
        opacity: "1",
        borderRadius: "5px",
        cursor: 'pointer',
        textDecoration:"none"
        //'&:hover' : {
        //    cursor: 'pointer',
        //    backgroundColor: "silver",
        //}
    }

    const divStyles = {
        listStyle: "none",
        textAlign: "justify",
        color:'green',
        //textDecoration:'none',
        //backgroundColor: "whitesmoke",
        zIndex:"100"
    }

    const imgStyles = {
        width: "250px",
        paddingRight:"0px",
        paddingLeft:"0px",
        border: 'double gainsboro 5px'
    }
    
    const relativeStyleProduct = {
        width:'90%',
        padding: '5px',
        margin:'auto',
        backgroundColor: 'orange',
        position: "relative", /* Stay in place */
    }
    const buttomStyle = {
            width: '144px',
            border: 'solid gold',
            borderRadius: '110px',
            backgroundColor: 'orange'
    }
    const linkDecoration = {
        textDecoration: 'none'
    }

    function getDoTabbed(){

    }
    function for2Imgs(url){
        if(Array.isArray(url)){
            return url[0];
        }else if(typeof(url)!==[]){
            return url;
        }
    }
    const productResults = validateProductResult(query);
    const FilterSwitchByTypeOfProduct =
                          ( terminal, 
                            scales,
                            printer_register,
                            cash_register,
                            online,
                            new_product,
                            labeller,
                            scanner,
                            other,
                            drawer,
                            pos ) => {
                                if(terminal === true){
                                    return '/payment_terminal'
                                }else if(scales === true){
                                    return '/scales'
                                }else if(printer_register === true) {
                                    return '/fiscal_printers'   
                                }else if(cash_register === true){
                                    return '/cash_register'
                                }else if(online === true){
                                    return '/online'
                                }else if (new_product === true){
                                    return '/news'
                                }else if (labeller === true){
                                    return '/labellers'
                                }else if (scanner === true){
                                    return '/scanners'
                                }else if(other === true){
                                    return '/other'
                                }else if(drawer === true){
                                    return '/drawers'
                                }else if(pos === true){
                                    return '/pos';                
                                }else{
                                    return "/"
                                }                                           
                        }


    return(
        
        <div style={searchBarStyle}>
        {document.onclick = onExit}        
                <label>Wyszukiwacz</label>
                <input type="text" id="texto" placeholder="Tu wpisz szukany..." value={query} onChange={onSearch}/>
                <input type="button" id="exit_button" value="oczyść wyszukiwanie" style={buttomStyle}/> 
                
                <div id="blockSearched" style={absoluteStyle} >
                    
                    {filterDuplicates(productResults.slice(0, 8))
                    .map((product, index)=>{
                       const {name, 
                        describe, 
                        terminal,
                        scales,
                        printer_register,
                        cash_register,
                        online,
                        new_product,
                        labeller,
                        scanner, 
                        other,
                        drawer,
                        pos,
                        url} = product;

                        return(
                            <ul>           
                                <Link style={linkDecoration} 
                                      
                                      to={FilterSwitchByTypeOfProduct(
                                          terminal, scales, 
                                          printer_register, cash_register, 
                                          online, new_product,
                                          labeller, scanner, 
                                          other, drawer, pos)}>                  
                                    <li style={listStyles} key={name+"_"+index} className={product}>
                                        <span style={listStyles}>
                                            <strong style={divStyles}>{ name }</strong>
                                        </span>    
                                        <span style={listStyles}>
                                            <img src={for2Imgs(url)} alt={product+"alt"+index} style={imgStyles}/>
                                        </span>
                                    </li>
                                </Link>
                            </ul>
                        )
                    })}
            </div>
        </div>
    )
     
}
 
export {SearcherHooks};
