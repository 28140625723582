import { useEffect } from 'react';
import './css/Advert.css';
//import GastroJpg from './sortofneedfromnovitu/Gastro.jpg';
import LinkJpg from './sortofneedfromnovitu/Link.jpg';
import NanowifiJpg from './sortofneedfromnovitu/Nanowifi.jpg';
import kasanatechnologie from './sortofneedfromnovitu/kasanatechnologie.jpg'
import GastroKasy_jpg from './sortofneedfromnovitu/gastroKasy_.jpg'
//import TxeJpg from './sortofneedfromnovitu/novitus_slider_txe.jpg';
//Advert module is a like class for display photo/photos to inject into queue:
export const Advert = () => {

    //Place to add new photo, banners, for automatic display in queue below:
    const imgs = [
        GastroKasy_jpg,
        LinkJpg,
        NanowifiJpg,
        kasanatechnologie,
                    //<--place to add into array below or modify some item/items of pics/photo/jpg/png etc...:
    ];

    const stylee = {
        width: '100%',
        top: '14vh',
        position: 'static',
        indexZ: '1'
    }
    const stylee2 = {
        width: '100%',
        top: '15vh',
        position: 'static',
        indexZ: '2'
    }

    ///////////////////////
    //If you will need:
    //const clearboth = {clear: 'both'};
    
    const Slider = () => {
        let inputRatio = imgs.map((img, i)=>{ 
            return(<label style={stylee2}>
                        <input id={"b"+i} name="_b_ers" checked className={"_b_ers"} type="radio" value="on" onChange={(e)=>e.target.imgs1.className} />
                   </label>);
        });

        //maybe if that's good solution, describe it in some article about it:
        let imgs1 = imgs.map((img, i)=>{      
            return(<img alt="foto" src={img} id={"image_"+i} style={stylee} name="images"></img>)
        });
        //let one = OneChange.bind(OneChange(imgs));
        //let imgs1f = imgs1.filter((abc)=>{
            //to fix and more precise complete and correction this one below example:- probably this is great idea:
            //let anotherBetterProbably = abc.props.checked ? <img abc.props... > : <img abc.props... >;
            //let odp = abc.props.checked ? abc.props.className="banner-on" : abc.props.className="banner-off"; 
        //});
        
        return (
            <div id="mi_dia_ba_r">
            {imgs1}
            {inputRatio}
            </div>
        )
    };

    useEffect(()=>{

        //THIS IS IT!! Slidechecker2() is great part of automation and validate a queue of pics's display:
        const Slidechecker2 = () => {
            let imgsIter=(id, howdisplay) => {
                let getHowDisplay = imgs.map((img, index)=>{
                   return document.getElementById(id+""+index).style.display = howdisplay;
                })
                return [id, howdisplay, getHowDisplay];
            } 
            let banners = document.getElementsByName("_b_ers");
            for (var i = 0; i < banners.length; ++i) {
                if (banners[i].checked === true) {
                    if (i === banners.length - 1) // If the last radio is selected 
                    {
                        i = 0;
                        banners[i].checked = true;
                        imgsIter("image_", "none");
                        ////////////////////////////////////////////////////
                        //This imgsIter() is the same like not with map() solution below:
                        // document.getElementById("image_"+0).style.display = "none";
                        //document.getElementById("image_"+1).style.display = "none";
                        //document.getElementById("image_"+2).style.display = "none";
                        document.getElementById("image_"+0).style.display = "flex";
                        
                    } else {
                        i =i+1;
                        banners[i].checked = true;
                        imgsIter("image_", "none");
                        ////////////////////////////////////////////////////
                        //This imgsIter() is the same like not with map() solution below:
                        //document.getElementById("image_"+0).style.display = "none";
                        //document.getElementById("image_"+1).style.display = "none";
                        //document.getElementById("image_"+2).style.display = "none";
                        document.getElementById("image_"+i).style.display = "flex";
                    }
                    break;
                }
            }
        }        

        let elementsImgs = imgs.map((img, index)=>{
           let imgs = document.getElementById("image_"+index);
           return imgs;
        });

        /////////////////////////////////////////////////////////////////////////////////////
        //That is not the greatest solution for that display, 
        //this is for modify for more flexibility and automatically without redundancy of code:
        //At now if you want to resize array of the images to display new one item,
        //If you need to change/add new line and modify next elementy[with_index], you will add code like below to do it:
        let elementy = elementsImgs.map((el, index)=>{
                    el.style.display = "none";
                return el
        });
                if (elementy[0].style.display === "none"){
                    document.getElementById("b"+0).addEventListener("click", () =>{
                        elementy[2].style.display = "none";
                        elementy[1].style.display = "none";
                        elementy[3].style.display = "none";
                        elementy[0].style.display = "flex";
                    });
                }
                if (elementy[1].style.display === "none"){
                      document.getElementById("b"+1).addEventListener("change", () =>{
                          elementy[0].style.display = "none";
                          elementy[2].style.display = "none";
                          elementy[3].style.display = "none";
                          elementy[1].style.display = "flex";
                      });
                  }
                  if (elementy[2].style.display === "none"){
                      document.getElementById("b"+2).addEventListener("change", () =>{
                          elementy[1].style.display = "none";
                          elementy[0].style.display = "none";
                          elementy[3].style.display = "none";
                          elementy[2].style.display = "flex";
                      });
                  }
                  if (elementy[3].style.display === "none"){
                    document.getElementById("b"+3).addEventListener("change", () =>{
                        elementy[1].style.display = "none";
                        elementy[0].style.display = "none";
                        elementy[2].style.display = "none";
                        elementy[3].style.display = "flex";
                    });
                }
                
                //First slide onload when website loaded:
                if (!window.onload) {
                    window.onload = Slidechecker2();
                }
                //If onload website on start first time then do sequence change like interval in slides in queue by option, 
                //"7000" is example" time:
                setInterval(function () {
                    Slidechecker2();
                }, 7000); 
    });
    return( 
        <div id="me" className="mi_dia">
            <Slider/>
        </div>
   );
};//end of Advert();