import investimlogo from "./widgets/sortofneedfromnovitu/logo/investimlogo.jpg";
//import styled from 'styled-components';
import { Link } from 'react-router-dom';
const styles = {
    background: 'antiquewhite',
    padding: '25px',
    marginBottom: '55px',
    //opacity: '0.85',
    textAlign: 'justify',
    borderRadius: '5px'
};
const h1title = {
    color: 'whitesmoke',
    backgroundColor: 'darkorange',
    textAlign:'center',
    padding: '5px'
}

const invstyle = {
    margin: 'auto',
    display: 'flex',
    width: '-webkit-fill-available',
    width: '-moz-available',
};
const hidden = {
    display: "none"
};
const buttonCenter = {
    marginLeft:'auto',
    marginRight:'auto',
    textDecoration: 'none',
}
const button = {
    display: 'block',
    //marginLeft: 'auto',
    //marginRight: 'auto',
    padding: '15px',
    background: 'rgb(148, 201, 115)',
    borderRadius: '10px',
    border: 'grey 3px solid'
    //listStyleType: "none",
    
}
const container1={
    display: 'flex',
    marginBottom:'5px'
}
const textDecorationNone={
    textDecoration: 'none',
}
export const Home = () => (
    <div className="maincontent">
        <Link to="productslist" style={textDecorationNone}>
        <h1 style={h1title}>INVESTIM Gdynia, Trójmiasto - Kasy i Drukarki Fiskalne Novitus i terminale płatnicze od Paytel</h1>
        <h2 style={h1title}>Serwis Kas Fiskalnych, premium partner marki Novitus grupy Comp S.A.</h2>
        <h2 style={h1title}>Zaufany partner Paytel S.A</h2>
        <h2 style={h1title}>Cash registers, printers and service partner</h2>
        <div style={container1}>
            <Link type="button" to="/productslist" style={buttonCenter} /*onClick={sayHello}*/>
                <input type="button" style={button} value="PRZEJDŹ DO PRODUKTÓW/GO TO PRODUCTS"></input>
            </Link>
        </div>
        <div style={styles}>
            <img style={invstyle} alt="logo" src={investimlogo}/>           
        </div>
        </Link>
    </div>
)