import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import {Home} from './components/Home';
import {Advert} from './components/widgets/Adverts';
import {BurgerNav} from './components/widgets/BurgerNav';
import {Navbar1, State} from './components/Navbar';
import {Footer} from './components/Footer';
import {Cashregister as cash_register} from './components/Cashregister';
import {Contact as contact} from './components/Contact';
import {BrowserRouter, Route, Switch, /*HashRouter, useLocation*/} from 'react-router-dom';
//import {News} from './components/News';
import {ProductsList as productslist} from './components/ProductsList';
import * as serviceWorker from './serviceWorker';
import {GeneratorItems as home_test} from './components/widgets/GeneratorItems';
//import {/*Printers,*//*Online,*//* Payment_terminals as payment_terminal,*/ New_products as new_products} from './components/About';
import {PaymentTerminals as payment_terminal} from './components/PayTerminals';
import {Online as online} from './components/Online';
import {Printers as fiscal_printers} from './components/Printers';
import {Scales} from './components/Scales';
import {Labellers} from './components/Labellers';
import {Drawers} from './components/Drawers';
import {Pos} from './components/Pos';
import {Scanners} from './components/Scanners';
import {Other} from './components/Other';
import {CookiesPolicies} from './components/widgets/Cookies';

//only to test buttom modal - todo do usefull ->import {ProxyModal} from './components/widgets/proxyModal';
//import Modal from "./components/widgets/Modal";
//import useModal from './components/widgets/useModal';
//import {createBrowserHistory} from 'history';
//import {SearcherHooks} from './components/widgets/SearcherHooks';

//import {useHistory} from 'react-router-dom';
//import {Googleanalytics_website_} from './components/widgets/Googleanalytics_website_';
//import {RouteChangeTracker_} from './components/widgets/RouteChangeTracker_';
//import { SearcherHooks } from './components/widgets/SearcherHooks';

//import {Googlanalystics_website} from './components/widgets/Googlanalytics_website';

function Ape(){
//class content background-color: whitesmoke;padding-bottom: 25%;padding-top: 25%;opacity: 0.4;margin: 3%;
  //const stylo = {
  //  position:'relative',
  //  top:'225px'
  //}

const NotFound = () => <div>Not found</div>
const clearboth = {
    clear: 'both'
};

const components = [
                Home,
                productslist,
                cash_register,
                fiscal_printers,
                online,
                payment_terminal,
                Scales,
                Labellers,
                Drawers,
                Scanners,
                Pos,
                Other,
                contact,
                home_test,
                CookiesPolicies,
];

const routes = [
                "/",
                "productslist",
                "cash_register",
                "fiscal_printers",
                "online",
                "payment_terminal",
                "scales",
                "labellers",
                "drawers",
                "scanners",
                "pos",
                "other",
                "contact",
                "home_test",
                "cookies"
               ]
                .map((route, index) => (
                <Route exact
                       key={route+""+index} 
                       path={`/${route}`}
                       component={components[index]} />
                ));


ReactDOM.render(
    <BrowserRouter /*history={useHistory()}*/ basename="">
      <div className="main"> 
                <Navbar1>
                   <BurgerNav />
                </Navbar1>
                <Advert />
      </div>  
        <div style={clearboth}></div>
      <div className="content">
          <Switch>
            {routes}  
            <Route component={Home} />
          </Switch>
      </div>
      
    
      <Footer />
      {/*<Googleanalytics_website_ name='UA-205288928-1'/>*/}
      {/*<RouteChangeTracker_/>*/}
    </BrowserRouter>
  ,
  document.getElementById('root')
);
//<Googlanalystics_website_ name='UA-205288928-1'/>
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
}
//<Googlanalystics_website_ name='UA-205288928-1'/>
//<RouteChangeTracker history={useHistory()}/>
Ape();
//Googlanalystics_website();
// <Route path="/home_test" component={GeneratorItems}></Route>
