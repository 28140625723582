//import { get } from "object-path";
//import { useEffect } from "react";
//import {react} from 'react';
//import {arrayCashier, arrayCashierOnline, arrayPrinters} from './JsonData';
//import { renderIntoDocument } from 'react-dom/cjs/react-dom-test-utils.production.min';
import './css/GeneratorItems.css';
import { Zoom } from './getLinkZoom';
import {useEffect} from 'react';
import Modal from "./Modal";
import useModal from './useModal';
import "./css/Modals.css";

export const GeneratorItems = (arr=[]) =>{
   
    //Implemented in project!!:
    const WrapTxtInDiv = (arr) => {
        let stylee = {
            width:'90%',
            margin: 'auto',
            opacity: '0.9',
            padding: '15px',
            backgroundColor: 'whitesmoke',
            marginBottom:'5px',
            borderRadius:'5px'
        };

        let imagee = {
            width: "350px",
            cursor: 'pointer',
            //height: 'inherit'
        };

        let postLine = {
            listStyle: "none",
            //textDecoration: "none",
            //whiteSpace: "pre-line"  
        };

    /////      
    //const modal_background = {
    //    display: 'block',
    //    indexZ: '1',
    //    position: 'fixed',
    //    left: '0',
    //    top: '0',
    //    width: '100%',
    //    height: '100%',
    //    overflow: 'auto',
    //    backgroundColor: 'rgba(0,0,0,0.4)'
    //};
    
    /* The Modal (background) */
    const modale = {
        display: "block", /* Hidden by default */
        position: "fixed", /* Stay in place */
        indexZ: "1", /* Sit on top */
        paddingTop: "100px", /* Location of the box */
        left: "0",
        top: "0",
        width: "70%", /* Full width */
        height: "70%", /* Full height */
        overflow: "auto", /* Enable scroll if needed */
        backgroundColor: "rgb(0,0,0)", /* Fallback color */
        backgroundColor: "rgba(0,0,0,0.4)" /* Black w/ opacity */
    }

    const modal_content = {
        display: 'block',
        backgroundColor: 'white',
        margin: '20% auto',
        padding: '20px',
        borderStyle: 'solid',
        borderWidth: '2px',
        borderColor: 'black',
        width: '70%'
    };

    const Close = {
        color: 'grey',
        float: 'right',
        fontSize: '20px',
        fontWeight: 'bold'
    };
    
    const close = {
        '&:hover' : {
            color: 'black',
            cursor: 'pointer'
        }
    }

    const styleBackground = {
        backgroundColor: '#94C973',
        paddingTop: '5px',
        paddingBottom: '5px',
        borderRadius: '5px',
        border: 'dotted 2px grey'
    }

    const { isShowing, toggle } = useModal();
    const getDo = (index) => {
        //let gett = document.getElementById("thumbnail_"+index);
        //    gett.addEventListener("click", ()=>{                
                toggle();
         //   });
    }                    

    const getDoTabbed = (event) => {
        var expandImg = document.getElementById("expandedImg");
        var imgText = document.getElementById("imgtext");
        expandImg.src = event.src;
        imgText.innerHTML = event.alt;
        expandImg.parentElement.style.display = "block";
    }  

    function forData(items){
        let content = [];
        for (let idx in items){
         //const item = items[idx];
          content.push(<li style={postLine} key={idx}>{items[idx]}</li>);
        }
        //console.log("contento:"+content);
        return content;
    };

    function forImgs(url){
        let content = [];
        let index = 0;
        index++;
        
        if(Array.isArray(url)){
            for (let idx in url){
                //const item = items[idx];
                content.push(<img style={imagee} id={idx+"thumbnail_"} alt={"img_thumbnail_"+idx} src={url[idx]}></img>);
            }
            //console.log("contento:"+content);
            return content.map((img)=>{
                return img;
            });
        }
        return <img style={imagee} id={index+"thumbnail_"} alt={"img_thumbnail_"+index} src={url}></img>;
    };

    function forImgs2(url){
        if(typeof(url) == []){
            let imges = url.map((img, index)=>{
                return (<img style={imagee} id={index+"thumbnail_"} alt={"img_thumbnail_"+index} src={img}></img>);
            });
            return imges;
        }else if(typeof(url)!==[]){
            return url;
        }
        
        //else{
           // <img style={imagee} id={name+"thumbnail_"} alt={"img_thumbnail_"+index} src={img} onClick={(e)=>{getDoTabbed(e)}}></img>
        //}
    }

    const createHtmlDivFrom = (arr) => arr.map((data, index) =>{
        const {name, describe, url} = data;    
        return(
                
                <div className="products" style={stylee}>
                    <div>
                        
                        {/*forImgs(url)*/}
                        {/*forImgs2(url)*/}
                        {/*<img style={imagee} id={name+"thumbnail_"} alt={"img_thumbnail_"+index} src={url} onClick={()=>{getDo(index)}}></img>*/}  
                        {forImgs(url)}
                    </div>

                    <div style={{width: 'inherit'}} className="describes" id={"txt_"+index}>
                                <h3 id={"head"+index}>{data.describe[0]}</h3>
                                <p style={postLine}>{forData(describe)}</p>
                    </div>
                </div>
            )}        
        );
        return (
            <div className="contentsTxt" style={styleBackground}>
                {createHtmlDivFrom(arr)}
                {/*<Modal isShowing={isShowing} hide={toggle}/>*/}
                {/*windowOnClick()*/}
                
            </div>
            
        );
    };

    useEffect(()=>{

    });
        //let zoom = Zoom(arr);
        //zoom[0].addEventListener("click", ()=>{
        //
        //});
        //console.log(zoom);
     
        return (
            <div className="contents">
                {WrapTxtInDiv(arr)}
            </div>
        );

};//finish GeneratoItems