import {ImagesCashiers,
        ImagesPrinters,
        ImagesOnline, 
        ImagesScales, 
        ImagesPayTerminals, 
        ImagesLabellers, 
        ImageDrawers,
        ImagePOS, 
        ImagePagers,
        ImagesScaners,
        ImagesOnlineRegistersGoodQualityPhotosPng
        } from './Images';

        const {
               bonoOnline1,
               bonoOnline2, 
               deonOnline01, 
               deonOnline03_paragon,
               hdOnline01,
               malaOnline01,
               malaOnline03,
               nanoOnline03,
               nanoOnline03_paragon, 
               oneOnline_biala7, 
               oneOnline_biala8, 
               oneOnline_czarna1, 
               oneOnline_czarna2, 
               sentoOnline01, 
               sentoOnline02
               } = ImagesOnlineRegistersGoodQualityPhotosPng;
        const {bonoOnline, bonoLan, bonoApteka} = ImagesPrinters;
        


//drukarki
export const arrayPrinters = [
    {
      name: "Bono Online",
      printer_register: true,
      cash_register: false,
      online: true,
      new_product: false,
      describe: {
        "0":"BONO ONLINE",
        "1":"",
        "2":"porównaj produkty",
        "3":"",
        "4":"Możliwości drukarki Novitus BONO Online są ogromne, mimo jej niewielkich gabarytów. Jest to urządzenie online dedykowane do małych i średnich punktów handlowych, gdzie sprzedaż prowadzona jest z programu komputerowego, a drukarka będzie uzupełnieniem stanowiska sprzedaży. Dzięki szerokiej gamie wydruków niefiskalnych, wydrukujesz wszystko co  konieczne do funkcjonowania sklepu lub firmy bez konieczności stosowania dodatkowych drukarek.",
        "5":"",
        "6":"Drukarka fiskalna BONO Online umożliwia połączenie i przesyłanie danych do Centralnego Repozytorium Kas za pośrednictwem sieci teleinformatycznej.  Oznacza to, że spełnia wymagania Rozporządzenia Ministra Przedsiębiorczości i Technologii w sprawie kryteriów i warunków technicznych, którym muszą odpowiadać kasy rejestrujące oraz Ustawy o VAT. Funkcje te są praktycznie bezobsługowe – użytkownik nie musi wykonywać żadnych dodatkowych operacji, jedynie zapewnić drukarce połączenie z właściwie skonfigurowaną siecią internetową. Drukarka z Centralnym Repozytorium Kas może się łączyć za pomocą sieci LAN, WLAN po dołożeniu opcjonalnego konwertera USB-WiFi lub GSM po dołożeniu wewnętrznego modemu GSM lub zewnętrznego konwertera USB-GSM.",
        "7":"",
        "8":"Drukarka posiada dwa wyświetlacze graficzne LCD, na których w sposób przejrzysty wyświetla nazwy sprzedawanych towarów i usług lub komunikaty komentujące pracę drukarki. 60-cio metrowa rolka papieru pozwala na długą nieprzerwaną pracę. Dowolne wydruki niefiskalne, również z grafiką, logo graficzne w nagłówku i stopce, faktury VAT, NIP nabywcy na paragonie – to możliwości drukarki, na które warto zwrócić uwagę.",
        "9":""
      },
      url: [bonoOnline1, bonoOnline2, bonoOnline],
      //url: ImagesPrinters.bonoOnline  
      //url: "src/components/widgets/sortofneedfromnovitu/ItemPhotos/onlineFis/bono300x300_0.png"
    },
    {
        name: "Deon Online",
        printer_register: true,
        cash_register: false,
        online: true,
        new_product: false,
        describe:{
            "0":"DEON ONLINE",
            "1":"",
            "2":"porównaj produkty",
            "3":"",
            "4":"Drukarka fiskalna DEON Online to najnowsze urządzenie z rodziny sprawdzonych urządzeń fiskalnych DEON. Cechuje ją przede wszystkim ergonomia i praktyczność. Sprawdzi się w punktach prowadzących ruchomą sprzedaż z użyciem komputera, laptopa czy palmtopa, lub tradycyjną, gdzie lada sklepowa ma ograniczoną powierzchnię. Wytrzyma nawet najtrudniejsze warunki atmosferyczne: wysokie i niskie temperatury. Drukarka posiada również wbudowane i specjalnie przystosowane zasilanie akumulatorowe tak, aby można było na niej pracować nawet kilka dni bez zasilania zewnętrznego. DEON Online pozwala użytkownikowi na wydruk szerokiej gamy wydruków niefiskalnych - wydrukujesz wszystko co  konieczne do funkcjonowania sklepu lub firmy bez konieczności stosowania dodatkowych drukarek.",
            "5":"",
            "6":"Drukarka fiskalna DEON Online umożliwia połączenie i przesyłanie danych do Centralnego Repozytorium Kas za pośrednictwem sieci teleinformatycznej.  Oznacza to, że spełnia wymagania Rozporządzenia Ministra Przedsiębiorczości i Technologii w sprawie kryteriów i warunków technicznych, którym muszą odpowiadać urządzenia fiskalne rejestrujące oraz Ustawy o VAT. Funkcje te są praktycznie bezobsługowe – użytkownik nie musi wykonywać żadnych dodatkowych operacji, jedynie zapewnić drukarce połączenie z właściwie skonfigurowaną siecią internetową. Drukarka z Centralnym Repozytorium Kas może się łączyć za pomocą sieci LAN, WLAN po dołożeniu opcjonalnego konwertera USB-WiFi lub GSM po dołożeniu wewnętrznego modemu GSM lub zewnętrznego konwertera USB-GSM.",
        },
            url: [ImagesOnlineRegistersGoodQualityPhotosPng.deonOnline03_paragon]
            //url: ImagesPrinters.deonOnline
            //url: "src/components/widgets/sortofneedfromnovitu/ItemPhotos/onlineFis/deon300x300.png"
    },
    {
        name: "HD Online",
        printer_register: true,
        cash_register: false,
        online: true,
        new_product: false,
        describe:{
            "0":"HD ONLINE",
            "1":"",
            "2":"porównaj produkty",
            "3":"",
            "4":"Drukarka Novitus HD online to pierwsza na polskim rynku drukarka z kolorowym wyświetlaczem klienta pozwalającym wyświetlać komunikaty oraz grafikę w trybie High Color, obsługująca jednocześnie komunikację z Centralnym Repozytorium Kas, zgodnie z wymogami Rozporządzenia Ministra Przedsiębiorczości i Technologii z dnia 28 maja 2018 r. w sprawie kryteriów i warunków technicznych, którym muszą odpowiadać kasy rejestrujące. Drukarka Novitus HD online zbudowana jest w oparciu o niezawodny, profesjonalny, bardzo trwały i szybki mechanizm drukujący japońskiej firmy Citizen, który oprócz paragonów może drukować również faktury VAT z danymi Nabywcy oraz Odbiorcy. W standardzie posiada profesjonalny obcinacz papieru. Drukarka współpracuje z programami sprzedaży na najpopularniejszym protokole komunikacyjnym na polskim rynku, zgodnym ze wszystkimi poprzednimi modelami drukarek Novitus. Wersja online drukarki HD to zbiór sprawdzonej technologii z poprzednich modeli połączony z najnowocześniejszymi standardami na rynku drukarek fiskalnych. Z Centralnym Repozytorium Kas drukarka łączy się za pomocą sieci LAN",
            "5":"",
           
        },
            url: [ImagesOnlineRegistersGoodQualityPhotosPng.hdOnline01, ImagesOnlineRegistersGoodQualityPhotosPng.hdOnline01] 
            //url: ImagesPrinters.hdOnline
            //url: "src/components/widgets/sortofneedfromnovitu/ItemPhotos/onlineFis/hd300x300.png"
    },
    {
        name: "HD Online (wolnostojący wyświetlacz)",
        printer_register: true,
        cash_register: false,
        online: true,
        new_product: false,
        describe:{
            "0":"HD ONLINE z wolnostojącym wyświetlaczem",
            "1":"",
            "2":"porównaj produkty",
            "3":"",
            "4":"Drukarka Novitus HD online to pierwsza na polskim rynku drukarka z kolorowym wyświetlaczem klienta pozwalającym wyświetlać komunikaty oraz grafikę w trybie High Color, obsługująca jednocześnie komunikację z Centralnym Repozytorium Kas, zgodnie z wymogami Rozporządzenia Ministra Przedsiębiorczości i Technologii z dnia 28 maja 2018 r. w sprawie kryteriów i warunków technicznych, którym muszą odpowiadać kasy rejestrujące. Drukarka Novitus HD online zbudowana jest w oparciu o niezawodny, profesjonalny, bardzo trwały i szybki mechanizm drukujący japońskiej firmy Citizen, który oprócz paragonów może drukować również faktury VAT z danymi Nabywcy oraz Odbiorcy. W standardzie posiada profesjonalny obcinacz papieru. Drukarka współpracuje z programami sprzedaży na najpopularniejszym protokole komunikacyjnym na polskim rynku, zgodnym ze wszystkimi poprzednimi modelami drukarek Novitus. Wersja online drukarki HD to zbiór sprawdzonej technologii z poprzednich modeli połączony z najnowocześniejszymi standardami na rynku drukarek fiskalnych. Z Centralnym Repozytorium Kas drukarka łączy się za pomocą sieci LAN. Ta wersja drukarki posiada wyświetlacz wolnostojący, niezintegrowany z obudową. Pozwala to na postawienie drukarki w miejscu wygodnym dla obsługi a wyświetlacza w takim miejscu, aby był dobrze widoczny przez obsługiwanego klienta.",
            "5":"",

        },
            //url: ImagesOnlineRegistersGoodQualityPhotosPng.hdOnline02
            url: [ImagesPrinters.hdOnlineWolny]
            //url: "src/components/widgets/sortofneedfromnovitu/ItemPhotos/onlineFis/hd_online_ww1.jpg"
    }
];

///////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////////    
//kasyOnlne
export const arrayCashierOnline = [
    {
        name: "Nano Online",
        printer_register: false,
        cash_register: true,
        online: true,
        new_product: false,
        describe: {
            "0":"NANO ONLINE",
            "1":"",
            "2":"porównaj produkty",
            "3":"",
            "4":"Mobilna kasa fiskalna NANO online to kwintesencja ergonomii i designu,  przeznaczona do pracy w mobilnych punktach handlowych, gastronomicznych i usługowych oraz wszędzie tam, gdzie trudno zmieścić większe urządzenia fiskalne. Jest to najmniejsza kasa fiskalna w ofercie NOVITUS, która jednocześnie obsługuje komunikację z Centralnym Repozytorium Kas (CRK), zgodnie z wymogami Rozporządzenia Ministra Przedsiębiorczości i Technologii z dnia 28 maja 2018 r. w sprawie kryteriów i warunków technicznych, którym muszą odpowiadać kasy rejestrujące. Kopia wydruku dla użytkownika przechowywana jest w pamięci chronionej urządzenia, a dostęp do niej jest realizowany poprzez dedykowaną aplikację.",
            "5":"Kasa NANO online jest najlepiej zaprojektowaną i przemyślaną kasą na polskim rynku! Swój ergonomiczny kształt oraz optymalne rozmiary, urządzenie zawdzięcza wieloletniej pracy i doświadczeniu naszych inżynierów. Wersja online łączy w sobie wszystkie zalety sprawdzonego projektu oraz nowoczesne technologiczne serce, napędzające to urządzenie.",
            "6":"",
            "7":"POWER DUO - Kasa NANO Online  z PIN Padem Paytel",
            "8":"",
            "9":"Najlepszy sposób na płatności bezgotówkowe w Twojej firmie - Kasa łączy się jednym kablem USB z PIN Padem Paytel i współpracuje bez specjalnej konfiguracji.  Oferuj swoim klientom wygodne transakcje bezgotówkowe i nie trać czasu na wprowadzanie kwoty na terminalu – kasa zrobi to za Ciebie. Wyeliminujesz w ten sposób błędy i przyśpieszysz obsługę w puncie sprzedaży. Nie płać przez rok za użytkowanie PIN Pada w ramach akcji „Polska Bezgotówkowa”, a później ciesz się nawet o połowę mniejszymi kosztami dzierżawy PIN Pada!",
            "10":"",

        },
            url: [ImagesOnlineRegistersGoodQualityPhotosPng.nanoOnline03_paragon]
            //url: ImagesOnline.nanoOnline
            //url: "src/components/widgets/sortofneedfromnovitu/ItemPhotos/onlineFis/nano300x300_0.png"
    },
    {
        name: "Nano Online GSM",
        printer_register: false,
        cash_register: true,
        online: true,
        new_product: false,
        describe: {
            "0":"NANO ONLINE GSM",
            "1":"",
            "2":"porównaj produkty",
            "3":"",
            "4":"Mobilna kasa fiskalna NANO online jest dostępna w wersji z wbudowanym modemem GSM. to kwintesencja ergonomii i designu,  przeznaczona do pracy w mobilnych punktach handlowych, gastronomicznych i usługowych oraz wszędzie tam, gdzie trudno zmieścić większe urządzenia fiskalne. Jest to najmniejsza kasa fiskalna w ofercie NOVITUS, która jednocześnie obsługuje komunikację z Centralnym Repozytorium Kas (CRK), zgodnie z wymogami Rozporządzenia Ministra Przedsiębiorczości i Technologii z dnia 28 maja 2018 r. w sprawie kryteriów i warunków technicznych, którym muszą odpowiadać kasy rejestrujące. Kopia wydruku dla użytkownika przechowywana jest w pamięci chronionej urządzenia, a dostęp do niej jest realizowany poprzez dedykowaną aplikację.",
            "5":"Kasa NANO online + modem GSM jest najlepiej zaprojektowaną i przemyślaną kasą na polskim rynku! Swój ergonomiczny kształt oraz optymalne rozmiary, urządzenie zawdzięcza wieloletniej pracy i doświadczeniu naszych inżynierów. Wersja online łączy w sobie wszystkie zalety sprawdzonego projektu oraz nowoczesne technologiczne serce, napędzające to urządzenie.",
            "6":"",
            "7":"POWER DUO - Kasa NANO Online z PIN Padem Paytel",
            "8":"",
            "9":"Najlepszy sposób na płatności bezgotówkowe w Twojej firmie - Kasa łączy się jednym kablem USB z PIN Padem Paytel i współpracuje bez specjalnej konfiguracji.  Oferuj swoim klientom wygodne transakcje bezgotówkowe i nie trać czasu na wprowadzanie kwoty na terminalu – kasa zrobi to za Ciebie. Wyeliminujesz w ten sposób błędy i przyśpieszysz obsługę w puncie sprzedaży. Nie płać przez rok za użytkowanie PIN Pada w ramach akcji „Polska Bezgotówkowa”, a później ciesz się nawet o połowę mniejszymi kosztami dzierżawy PIN Pada!",
            "10":"",

        },
            url: [ImagesOnlineRegistersGoodQualityPhotosPng.nanoOnline03_paragon]
            //url: ImagesOnline.nanoOnline
            //url: "src/components/widgets/sortofneedfromnovitu/ItemPhotos/onlineFis/nano300x300_0.png"
    },
    {
        name: "Nano II Online",
        printer_register: false,
        cash_register: true,
        online: true,
        new_product: false,
        describe:  {
            "0":"NANO II ONLINE",
            "1":"",
            "2":"porównaj produkty",
            "3":"",
            "4":"Następca najpopularniejszej i najczęściej sprzedawanej na polskim rynku kasy fiskalnej Novitus NANO. Doskonałe rozwiązanie zarówno w sprzedaży mobilnej jak i na tradycyjnym stanowisku sprzedaży. Model zachował najlepsze cechy pierwowzoru, czyli niewielkie rozmiary i ergonomiczny kształt, wodoodporną podświetlaną klawiaturę. Jednak nowy design i dodatkowe klawisze funkcyjne to szybsza i wygodniejsza obsługa oraz bardziej intuicyjna praca. Busy, wypożyczalnie, gabinety lekarskie, parkingi, gabinety kosmetyczne, sklepy - kasa NANO II zawsze dostosuje się do prowadzonego biznesu. Kasa Nano II Online w standardzie ma zamontowane łącze WLAN - po wyjęciu z pudełka od razu może łączyć się z sieciami WiFi, w których zasięgu się znajduje.",
            "5":"",
            "6":"POWER DUO - Kasa NANO II Online  z PIN Padem Paytel",
            "7":"",
            "8":"Najlepszy sposób na płatności bezgotówkowe w Twojej firmie - Kasa łączy się tylko jednym kablem USB z PIN Padem Paytel i pracuje bez specjalnej konfiguracji.  Oferuj swoim klientom wygodne transakcje bezgotówkowe i nie trać czasu na wprowadzanie kwoty na terminalu – kasa zrobi to za Ciebie. Wyeliminujesz w ten sposób błędy i przyśpieszysz obsługę w puncie sprzedaży. Nie płać przez rok za użytkowanie PIN Pada w ramach akcji „Polska Bezgotówkowa”, a później ciesz się nawet o połowę mniejszymi kosztami dzierżawy PIN Pada!",
            "9":"",

        },  //url: ImagesOnlineRegistersGoodQualityPhotosPng. <- do uzupelnienia znalezienia odpowiedniego zdjecia
            url: ImagesOnline.nano2Online
            //url: "src/components/widgets/sortofneedfromnovitu/ItemPhotos/onlineFis/nano2online_bialy_skos1_0.jpg"
    },
    {
        name: "Nano II Online GSM",
        printer_register: false,
        cash_register: true,
        online: true,
        new_product: false,
        describe:  {
            "0":"NANO II ONLINE GSM",
            "1":"",
            "2":"porównaj produkty",
            "3":"",
            "4":"Następca najpopularniejszej i najczęściej sprzedawanej na polskim rynku kasy fiskalnej Novitus NANO. Doskonałe rozwiązanie zarówno w sprzedaży mobilnej jak i na tradycyjnym stanowisku sprzedaży. Model zachował najlepsze cechy pierwowzoru, czyli niewielkie rozmiary i ergonomiczny kształt, wodoodporną podświetlaną klawiaturę. Jednak nowy design i dodatkowe klawisze funkcyjne to szybsza i wygodniejsza obsługa oraz bardziej intuicyjna praca. Busy, wypożyczalnie, gabinety lekarskie, parkingi, gabinety kosmetyczne, sklepy - kasa NANO II zawsze dostosuje się do prowadzonego biznesu. Kasa Nano II Online w standardzie ma zamontowane łącze WLAN - po wyjęciu z pudełka od razu może łączyć się z sieciami WiFi, w których zasięgu się znajduje.",
            "5":"",
            "6":"POWER DUO - Kasa NANO II Online  z PIN Padem Paytel",
            "7":"",
            "8":"Najlepszy sposób na płatności bezgotówkowe w Twojej firmie - Kasa łączy się tylko jednym kablem USB z PIN Padem Paytel i pracuje bez specjalnej konfiguracji.  Oferuj swoim klientom wygodne transakcje bezgotówkowe i nie trać czasu na wprowadzanie kwoty na terminalu – kasa zrobi to za Ciebie. Wyeliminujesz w ten sposób błędy i przyśpieszysz obsługę w puncie sprzedaży. Nie płać przez rok za użytkowanie PIN Pada w ramach akcji „Polska Bezgotówkowa”, a później ciesz się nawet o połowę mniejszymi kosztami dzierżawy PIN Pada!",
            "9":"",

        },
            //url: ImagesOnlineRegistersGoodQualityPhotosPng. <- do uzupelnienia znalezienia odpowiedniego zdjecia
            url: ImagesOnline.nano2Online
            //url: "src/components/widgets/sortofneedfromnovitu/ItemPhotos/onlineFis/nano2online_bialy_skos1_0.jpg"
    },
    {
        name: "Link Online",
        printer_register: false,
        cash_register: true,
        online: true,
        new_product: false,
        describe:  {
            "0":"LINK ONLINE",
            "1":"",
            "2":"porównaj produkty",
            "3":"",
            "4":"Kasa Novitus LINK Online to nowy produkt z dobrze znanej i uznanej na rynku gamy produktów Onlineowych marki Novitus. Optymalne połączenie wyśrubowanych parametrów technicznych oraz zaawansowanych funkcji to znak rozpoznawczy kasy dedykowanej do małych i średnich punktów sprzedaży i usług.",
            "5":"",
            "6":"LINK Online to kasa fiskalna umożliwiająca połączenie i przesyłanie danych do Centralnego Repozytorium Kas za pośrednictwem sieci teleinformatycznej.  Oznacza to, że spełnia wymagania Rozporządzenia Ministra Przedsiębiorczości i Technologii w sprawie kryteriów i warunków technicznych, którym muszą odpowiadać kasy rejestrujące oraz Ustawy o VAT. Do poprawnej współpracy z CRK wystarczy jedynie połączenie z właściwie skonfigurowaną siecią internetową. Mała i poręczna obudowa kasy kryje w sobie bardzo zaawansowaną technologię i funkcjonalność spotykaną wcześniej w segmencie dużych kas systemowych. Bardzo duża baza towarowa, precyzyjne nazewnictwo towarów, komunikacja on-line z komputerem, wiele złącz komunikacyjnych, w tym złącza LAN, RS232 i USB pozwalają stworzyć zaawansowany system sprzedaży dla średniego sklepu. Ergonomiczna budowa sprawia, że wielogodzinna praca na tej kasie będzie wygodna i efektywna.",
            "7":"",
            "8":"POWER DUO - Kasa LINK Online  z PIN Padem Paytel",
            "9":"",
            "10":"Najlepszy sposób na płatności bezgotówkowe w Twojej firmie - Kasa łączy się jednym kablem USB z PIN Padem Paytel i współpracuje bez specjalnej konfiguracji.  Oferuj swoim klientom wygodne transakcje bezgotówkowe i nie trać czasu na wprowadzanie kwoty na terminalu – kasa zrobi to za Ciebie. Wyeliminujesz w ten sposób błędy i przyśpieszysz obsługę w puncie sprzedaży. Nie płać przez rok za użytkowanie PIN Pada w ramach akcji „Polska Bezgotówkowa”, a później ciesz się nawet o połowę mniejszymi kosztami dzierżawy PIN Pada!",
            "11":"",

        },
            //url: ImagesOnlineRegistersGoodQualityPhotosPng.<-do znalezienia odpowiedniego zdjecia
            url: ImagesOnline.linkOnline
            //url: "src/components/widgets/sortofneedfromnovitu/ItemPhotos/onlineFis/kasa_link3_b_0.png"
    },
    {
        name: "Link Online GSM24",
        printer_register: false,
        cash_register: true,
        online: true,
        new_product: false,
        describe:  {
            "0":"LINK ONLINE GSM24",
            "1":"",
            "2":"porównaj produkty",
            "3":"",
            "4":"Kasa Novitus LINK Online to nowy produkt z dobrze znanej i uznanej na rynku gamy produktów Onlineowych marki Novitus. Optymalne połączenie wyśrubowanych parametrów technicznych oraz zaawansowanych funkcji to znak rozpoznawczy kasy dedykowanej do małych i średnich punktów sprzedaży i usług. Kasa w wersji GSM24 wyróżnia się od wersji standardowej zamontowanym fabrycznie wewnętrznym modemem GSM z zamontowaną kartą SIM operatora Orange i transmisją danych przez 24 miesiące. Kasa jest od razu skonfigurowana do połączenia z siecią Internet.",
            "5":"",
            "6":"LINK Online to kasa fiskalna umożliwiająca połączenie i przesyłanie danych do Centralnego Repozytorium Kas za pośrednictwem sieci teleinformatycznej. Oznacza to, że spełnia wymagania Rozporządzenia Ministra Przedsiębiorczości i Technologii w sprawie kryteriów i warunków technicznych, którym muszą odpowiadać kasy rejestrujące oraz Ustawy o VAT. Do poprawnej współpracy z CRK wystarczy jedynie połączenie z właściwie skonfigurowaną siecią internetową. Mała i poręczna obudowa kasy kryje w sobie bardzo zaawansowaną technologię i funkcjonalność spotykaną wcześniej w segmencie dużych kas systemowych. Bardzo duża baza towarowa, precyzyjne nazewnictwo towarów, komunikacja on-line z komputerem, wiele złącz komunikacyjnych, w tym złącza LAN, RS232 i USB pozwalają stworzyć zaawansowany system sprzedaży dla średniego sklepu. Ergonomiczna budowa sprawia, że wielogodzinna praca na tej kasie będzie wygodna i efektywna.",
            "7":"",
            "8":"POWER DUO - Kasa LINK Online  z PIN Padem Paytel",
            "9":"",
            "10":"Najlepszy sposób na płatności bezgotówkowe w Twojej firmie - Kasa łączy się jednym kablem USB z PIN Padem Paytel i współpracuje bez specjalnej konfiguracji.  Oferuj swoim klientom wygodne transakcje bezgotówkowe i nie trać czasu na wprowadzanie kwoty na terminalu – kasa zrobi to za Ciebie. Wyeliminujesz w ten sposób błędy i przyśpieszysz obsługę w puncie sprzedaży. Nie płać przez rok za użytkowanie PIN Pada w ramach akcji „Polska Bezgotówkowa”, a później ciesz się nawet o połowę mniejszymi kosztami dzierżawy PIN Pada!",
            "11":"",
        },
            //url: ImagesOnlineRegistersGoodQualityPhotosPng.<-do znalezienia odpowiedniego zdjecia
            url: ImagesOnline.linkOnlineGSM24
            //url: "src/components/widgets/sortofneedfromnovitu/ItemPhotos/onlineFis/kasa_link3_b_0.png"
    },
    {
        name: "Next Online",
        printer_register: false,
        cash_register: true,
        online: true,
        new_product: false,
        describe:  {
            "0":"NEXT ONLINE",
            "1":"",
            "2":"porównaj produkty",
            "3":"",
            "4":"Kasa fiskalna NEXT Online to urządzenie najnowszej generacji. Dzięki zastosowaniu w niej dotykowego tabletu praca na kasie stała się jeszcze bardziej intuicyjna i prostsza dla jej użytkownika, co przełoży się na brak problemów w jej obsłudze przez personel i zapewni szybkie wdrożenie. Kasa posiada także klawiaturę fizyczną tak,  aby użytkownik miał pełną dowolność obsługi. W NEXT Online zastosowano podzespoły najwyższej jakości m.in. szybki mechanizm drukujący, oprogramowanie Android czy wodoodporną klawiaturę, tak aby kasa spełniała wymagania każdego, kto zdecyduje się na jej zakup. Kasa posiada nieograniczoną limitami bazę towarową, dzięki czemu użytkownik nie musi obawiać się o jej zapełnienie w przyszłości. Działalność NEXT Online nie ogranicza się tylko do prowadzenia sprzedaży. Dzięki zastosowanemu oprogramowaniu Android została doposażona w funkcjonalne aplikacje takie jak przeglądarka internetowa, skrzynka e-mail, co umożliwi szybką komunikację wewnętrzną oraz z klientami którym można przesyłać paragony i faktury. Usługa NoviCloud pozwoli na monitoring sprzedaży i kontrolę biznesu bez ponoszenia inwestycji przez internet. Kasa NEXT Online, dzięki opcji pełnej dowolności konfiguracji okna sprzedaży znajdzie zastosowanie w każdej dziedzinie biznesu. To urządzenie dla nowoczesnego przedsiębiorcy, który idzie z duchem czasu.",
            "5":"",
            "6":"POWER DUO - Kasa NEXT Online  z PIN Padem Paytel",
            "7":"",
            "8":"Najlepszy sposób na płatności bezgotówkowe w Twojej firmie - Kasa łączy się jednym kablem USB z PIN Padem Paytel i współpracuje bez specjalnej konfiguracji.  Oferuj swoim klientom wygodne transakcje bezgotówkowe i nie trać czasu na wprowadzanie kwoty na terminalu – kasa zrobi to za Ciebie. Wyeliminujesz w ten sposób błędy i przyśpieszysz obsługę w puncie sprzedaży. Nie płać przez rok za użytkowanie PIN Pada w ramach akcji „Polska Bezgotówkowa”, a później ciesz się nawet o połowę mniejszymi kosztami dzierżawy PIN Pada!",
            "9":"",

        },
            //url: ImagesOnlineRegistersGoodQualityPhotosPng.
            url: ImagesOnline.nextOnline
            //url: "src/components/widgets/sortofneedfromnovitu/ItemPhotos/onlineFis/next_900px_4.jpg"
    },
    {
        name: "One Online",
        printer_register: false,
        cash_register: true,
        online: true,
        new_product: false,
        describe: {
            "0":"ONE ONLINE",
            "1":"",
            "2":"Idealne połączenie dużej mocy, dotykowego ekranu oraz niewielkiej wagi i niezwykle małych wymiarów. Zmieści się na najmniejszym stanowisku sprzedaży, oferując jednocześnie niezwykłą elastyczność pracy oraz programowalny ekran sprzedaży. Wystawia faktury i korekty, dokumenty zwrotu, obsługuje dodawanie notatek i ID klienta. Łatwe raportowanie, stany magazynowe, otwarte rachunki oraz współpraca z chmurowym systemem NoviCloud.",
            "3":"",
            "4":"Skonfiguruj dowolną skrzynkę mailową, przeglądaj korespondencję, wysyłaj faktury i utrzymuj kontakty ze swoimi kontrahentami. W chwili przerwy korzystaj z kasy ONE jak z każdego innego tabletu, surfuj po Internecie, oglądaj Youtube i słuchaj muzyki, używaj  narzędzi  systemu  Android  -  zegar,  kalkulator,  kontakty,  galeria i kalendarz. Na urządzeniu można zainstalować także dodatkowe aplikacje sprzedażowe, przeznaczone np. dla gastronomii.",
            "5":"",
            "6":"POWER DUO - Kasa ONE Online z PIN Padem Paytel",
            "7":"",
            "8":"Najlepszy sposób na płatności bezgotówkowe w Twojej firmie - Kasa łączy się jednym kablem USB z PIN Padem Paytel i współpracuje bez specjalnej konfiguracji.  Oferuj swoim klientom wygodne transakcje bezgotówkowe i nie trać czasu na wprowadzanie kwoty na terminalu – kasa zrobi to za Ciebie. Wyeliminujesz w ten sposób błędy i przyśpieszysz obsługę w puncie sprzedaży. Nie płać przez rok za użytkowanie PIN Pada w ramach akcji „Polska Bezgotówkowa”, a później ciesz się nawet o połowę mniejszymi kosztami dzierżawy PIN Pada!",
            "9":"",

        },  
            url: ImagesOnlineRegistersGoodQualityPhotosPng.oneOnline_czarna1
            //url: ImagesOnline.oneOnline
            //url: "src/components/widgets/sortofneedfromnovitu/ItemPhotos/onlineFis/one1_300px.jpg"
    },
    {
        name: "One Online GSM24",
        printer_register: false,
        cash_register: true,
        online: true,
        new_product: false,
        describe: {
            "0":"ONE ONLINE GSM24",
            "1":"",
            "2":"porównaj produkty",
            "3":"",
            "4":"Idealne połączenie dużej mocy, dotykowego ekranu oraz niewielkiej wagi i niezwykle małych wymiarów. Zmieści się na najmniejszym stanowisku sprzedaży, oferując jednocześnie niezwykłą elastyczność pracy oraz programowalny ekran sprzedaży. Wystawia faktury i korekty, dokumenty zwrotu, obsługuje dodawanie notatek i ID klienta. Łatwe raportowanie, stany magazynowe, otwarte rachunki oraz współpraca z chmurowym systemem NoviCloud.",
            "5":"",
            "6":"Skonfiguruj dowolną skrzynkę mailową, przeglądaj korespondencję, wysyłaj faktury i utrzymuj kontakty ze swoimi kontrahentami. W chwili przerwy korzystaj z kasy ONE jak z każdego innego tabletu, surfuj po Internecie, oglądaj Youtube i słuchaj muzyki, używaj  narzędzi  systemu  Android  -  zegar,  kalkulator,  kontakty,  galeria i kalendarz. Na urządzeniu można zainstalować także dodatkowe aplikacje sprzedażowe, przeznaczone np. dla gastronomii.",
            "7":"",
            "8":"Kasa ONE ONLINE GSM24 w standardzie ma zamontowane łącze GPRS - wewnętrzny modem GSM wraz z kartą SIM sieci Orange, umożliwijącą transmisję danych poprzez siec komórkową przez okres 24 miesięcy. Oznacza to, że po wyjęciu z pudełka od razu łączy się z secią GSM jeśli znajduje się w jej zasięgu i może transmitować dane do serwerów CRK. Łącze GSM może służyć rónież do łączenia się kasy z NoviCloud oraz internetem.",
            "9":"",
            "10":"POWER DUO - Kasa ONE Online z PIN Padem Paytel",
            "11":"",
            "12":"Najlepszy sposób na płatności bezgotówkowe w Twojej firmie - Kasa łączy się jednym kablem USB z PIN Padem Paytel i współpracuje bez specjalnej konfiguracji.  Oferuj swoim klientom wygodne transakcje bezgotówkowe i nie trać czasu na wprowadzanie kwoty na terminalu – kasa zrobi to za Ciebie. Wyeliminujesz w ten sposób błędy i przyśpieszysz obsługę w puncie sprzedaży. Nie płać przez rok za użytkowanie PIN Pada w ramach akcji „Polska Bezgotówkowa”, a później ciesz się nawet o połowę mniejszymi kosztami dzierżawy PIN Pada!",
            "13":"",

        },
            url: ImagesOnlineRegistersGoodQualityPhotosPng.oneOnline_biala8
            //url: ImagesOnline.oneOnlineGSM24
            //url: "src/components/widgets/sortofneedfromnovitu/ItemPhotos/onlineFis/one1_300px.jpg"
    },
    {
        name: "Sento Online",
        printer_register: false,
        cash_register: true,
        online: true,
        new_product: false,
        describe:{
            "0":"SENTO ONLINE",
            "1":"",
            "2":"porównaj produkty",
            "3":"",
            "4":"Kasa Novitus SENTO Online to następca legendarnych kas z rodziny SENTO. Optymalne połączenie wyśrubowanych parametrów technicznych oraz zaawansowanych funkcji to jej znak rozpoznawczy. Dzisiaj i za wiele lat taka sama wydajność pracy jest gwarantowana.",
            "5":"",
            "6":"SENTO Online to kasa fiskalna umożliwiająca połączenie i przesyłanie danych do Centralnego Repozytorium Kas za pośrednictwem sieci teleinformatycznej.  Oznacza to, że spełnia wymagania Rozporządzenia Ministra Przedsiębiorczości i Technologii w sprawie kryteriów i warunków technicznych, którym muszą odpowiadać kasy rejestrujące oraz Ustawy o VAT. Do poprawnej współpracy z CRK wystarczy jedynie połączenie z właściwie skonfigurowaną siecią internetową.  Kasa jest dedykowana małym i średnim punktom handlowo – usługowym. Mała i poręczna obudowa kasy kryje w sobie zaawansowaną technologię i funkcjonalność spotykaną wcześniej w segmencie dużych kas systemowych. Bardzo duża baza towarowa, precyzyjne nazewnictwo towarów, komunikacja on-line z komputerem, wiele złącz komunikacyjnych, w tym złącza RS232 i USB pozwalają stworzyć zaawansowany system sprzedaży dla średniego sklepu. Ergonomiczna budowa sprawia, że wielogodzinna praca na tej kasie będzie wygodna i efektywna.",
            "7":"",
            "8":"POWER DUO - Kasa SENTO Online  z PIN Padem Paytel",
            "9":"",
            "10":"Najlepszy sposób na płatności bezgotówkowe w Twojej firmie - Kasa łączy się jednym kablem USB z PIN Padem Paytel i współpracuje bez specjalnej konfiguracji.  Oferuj swoim klientom wygodne transakcje bezgotówkowe i nie trać czasu na wprowadzanie kwoty na terminalu – kasa zrobi to za Ciebie. Wyeliminujesz w ten sposób błędy i przyśpieszysz obsługę w puncie sprzedaży. Nie płać przez rok za użytkowanie PIN Pada w ramach akcji „Polska Bezgotówkowa”, a później ciesz się nawet o połowę mniejszymi kosztami dzierżawy PIN Pada!",
            "11":"",

        },  
            url: ImagesOnlineRegistersGoodQualityPhotosPng.sentoOnline01
            //url: ImagesOnline.sentoOnline
            //url: "src/components/widgets/sortofneedfromnovitu/ItemPhotos/onlineFis/sento300x300.png"
    },
    {
        name: "Mala Online",
        printer_register: false,
        cash_register: true,
        online: true,
        new_product: false,
        describe:  {
            "0":"MAŁA ONLINE",
            "1":"",
            "2":"porównaj produkty",
            "3":"",
            "4":"Kasa fiskalna Mała Plus E sprawdziła się w swojej roli w małych punktach sprzedaży. Wraz z postępem czasu postanowiliśmy stworzyć jej jeszcze lepszego następcę – kasę MAŁA Online. Większa baza towarowa pozwoli na pomieszczenie wszystkich oferowanych towarów i usług. Większa ilość grup towarowych pomoże utrzymać porządek w bazie. Dodaliśmy możliwość komplementacji towarów. Poszerzyliśmy możliwości płatności o najnowsze metody, dzięki czemu Twój klient jeszcze wygodniej zapłaci za towar lub usługę. Całość została dopełniona możliwością połączenia i przesyłania danych do Centralnego Repozytorium Kas za pośrednictwem sieci teleinformatycznej.  Oznacza to, że spełnia wymagania Rozporządzenia Ministra Przedsiębiorczości i Technologii w sprawie kryteriów i warunków technicznych, którym muszą odpowiadać kasy rejestrujące oraz Ustawy o VAT. Funkcje te są praktycznie bezobsługowe – użytkownik nie musi wykonywać żadnych dodatkowych operacji, jedynie zapewnić drukarce połączenie z właściwie skonfigurowaną siecią internetową. Nowa MAŁA Online to współczesna technologia dedykowana dla małych punktów sprzedaży ze względu na swoją ergonomię. Dzięki niewielkim wymiarom kasa nie zabierze dużo miejsca na – często ograniczonej – powierzchni stanowiska sprzedaży.",
            "5":"",
            "6":"POWER DUO - Kasa Mała Online  z PIN Padem Paytel",
            "7":"",
            "8":"Najlepszy sposób na płatności bezgotówkowe w Twojej firmie - Kasa łączy się jednym kablem USB z PIN Padem Paytel i współpracuje bez specjalnej konfiguracji.  Oferuj swoim klientom wygodne transakcje bezgotówkowe i nie trać czasu na wprowadzanie kwoty na terminalu – kasa zrobi to za Ciebie. Wyeliminujesz w ten sposób błędy i przyśpieszysz obsługę w puncie sprzedaży. Nie płać przez rok za użytkowanie PIN Pada w ramach akcji „Polska Bezgotówkowa”, a później ciesz się nawet o połowę mniejszymi kosztami dzierżawy PIN Pada!",
            "9":"",

        },
            url: ImagesOnlineRegistersGoodQualityPhotosPng.malaOnline03
            //url: ImagesOnline.malaOnline
            //url: "src/components/widgets/sortofneedfromnovitu/ItemPhotos/kasyfis/mala-plus-e_900px.jpg"
    }
];
     
//kasy
export const arrayCashier = [
    {
        name: "Nano Online",
        printer_register: false,
        cash_register: true,
        online: true,
        new_product: false,
        describe: {
            "0":"NANO ONLINE",
            "1":"",
            "2":"porównaj produkty",
            "3":"",
            "4":"Mobilna kasa fiskalna NANO online to kwintesencja ergonomii i designu,  przeznaczona do pracy w mobilnych punktach handlowych, gastronomicznych i usługowych oraz wszędzie tam, gdzie trudno zmieścić większe urządzenia fiskalne. Jest to najmniejsza kasa fiskalna w ofercie NOVITUS, która jednocześnie obsługuje komunikację z Centralnym Repozytorium Kas (CRK), zgodnie z wymogami Rozporządzenia Ministra Przedsiębiorczości i Technologii z dnia 28 maja 2018 r. w sprawie kryteriów i warunków technicznych, którym muszą odpowiadać kasy rejestrujące. Kopia wydruku dla użytkownika przechowywana jest w pamięci chronionej urządzenia, a dostęp do niej jest realizowany poprzez dedykowaną aplikację.",
            "5":"Kasa NANO online jest najlepiej zaprojektowaną i przemyślaną kasą na polskim rynku! Swój ergonomiczny kształt oraz optymalne rozmiary, urządzenie zawdzięcza wieloletniej pracy i doświadczeniu naszych inżynierów. Wersja online łączy w sobie wszystkie zalety sprawdzonego projektu oraz nowoczesne technologiczne serce, napędzające to urządzenie.",
            "6":"",
            "7":"POWER DUO - Kasa NANO Online  z PIN Padem Paytel",
            "8":"",
            "9":"Najlepszy sposób na płatności bezgotówkowe w Twojej firmie - Kasa łączy się jednym kablem USB z PIN Padem Paytel i współpracuje bez specjalnej konfiguracji.  Oferuj swoim klientom wygodne transakcje bezgotówkowe i nie trać czasu na wprowadzanie kwoty na terminalu – kasa zrobi to za Ciebie. Wyeliminujesz w ten sposób błędy i przyśpieszysz obsługę w puncie sprzedaży. Nie płać przez rok za użytkowanie PIN Pada w ramach akcji „Polska Bezgotówkowa”, a później ciesz się nawet o połowę mniejszymi kosztami dzierżawy PIN Pada!",
            "10":"",

        },
        url: ImagesOnlineRegistersGoodQualityPhotosPng.nanoOnline03_paragon,
            //url: ImagesOnline.nanoOnline
            //url: "src/components/widgets/sortofneedfromnovitu/ItemPhotos/onlineFis/nano300x300_0.png"
    },
    {
        name: "Nano Online GSM",
        printer_register: false,
        cash_register: true,
        online: true,
        new_product: false,
        describe: {
            "0":"NANO ONLINE GSM",
            "1":"",
            "2":"porównaj produkty",
            "3":"",
            "4":"Mobilna kasa fiskalna NANO online jest dostępna w wersji z wbudowanym modemem GSM. to kwintesencja ergonomii i designu,  przeznaczona do pracy w mobilnych punktach handlowych, gastronomicznych i usługowych oraz wszędzie tam, gdzie trudno zmieścić większe urządzenia fiskalne. Jest to najmniejsza kasa fiskalna w ofercie NOVITUS, która jednocześnie obsługuje komunikację z Centralnym Repozytorium Kas (CRK), zgodnie z wymogami Rozporządzenia Ministra Przedsiębiorczości i Technologii z dnia 28 maja 2018 r. w sprawie kryteriów i warunków technicznych, którym muszą odpowiadać kasy rejestrujące. Kopia wydruku dla użytkownika przechowywana jest w pamięci chronionej urządzenia, a dostęp do niej jest realizowany poprzez dedykowaną aplikację.",
            "5":"Kasa NANO online + modem GSM jest najlepiej zaprojektowaną i przemyślaną kasą na polskim rynku! Swój ergonomiczny kształt oraz optymalne rozmiary, urządzenie zawdzięcza wieloletniej pracy i doświadczeniu naszych inżynierów. Wersja online łączy w sobie wszystkie zalety sprawdzonego projektu oraz nowoczesne technologiczne serce, napędzające to urządzenie.",
            "6":"",
            "7":"POWER DUO - Kasa NANO Online z PIN Padem Paytel",
            "8":"",
            "9":"Najlepszy sposób na płatności bezgotówkowe w Twojej firmie - Kasa łączy się jednym kablem USB z PIN Padem Paytel i współpracuje bez specjalnej konfiguracji.  Oferuj swoim klientom wygodne transakcje bezgotówkowe i nie trać czasu na wprowadzanie kwoty na terminalu – kasa zrobi to za Ciebie. Wyeliminujesz w ten sposób błędy i przyśpieszysz obsługę w puncie sprzedaży. Nie płać przez rok za użytkowanie PIN Pada w ramach akcji „Polska Bezgotówkowa”, a później ciesz się nawet o połowę mniejszymi kosztami dzierżawy PIN Pada!",
            "10":"",

        },
            url: ImagesOnlineRegistersGoodQualityPhotosPng.nanoOnline03_paragon,
            //url: ImagesOnline.nanoOnline
            //url: "src/components/widgets/sortofneedfromnovitu/ItemPhotos/onlineFis/nano300x300_0.png"
    },
    {
        name: "Nano II Online",
        printer_register: false,
        cash_register: true,
        online: true,
        new_product: false,
        describe:  {
            "0":"NANO II ONLINE",
            "1":"",
            "2":"porównaj produkty",
            "3":"",
            "4":"Następca najpopularniejszej i najczęściej sprzedawanej na polskim rynku kasy fiskalnej Novitus NANO. Doskonałe rozwiązanie zarówno w sprzedaży mobilnej jak i na tradycyjnym stanowisku sprzedaży. Model zachował najlepsze cechy pierwowzoru, czyli niewielkie rozmiary i ergonomiczny kształt, wodoodporną podświetlaną klawiaturę. Jednak nowy design i dodatkowe klawisze funkcyjne to szybsza i wygodniejsza obsługa oraz bardziej intuicyjna praca. Busy, wypożyczalnie, gabinety lekarskie, parkingi, gabinety kosmetyczne, sklepy - kasa NANO II zawsze dostosuje się do prowadzonego biznesu. Kasa Nano II Online w standardzie ma zamontowane łącze WLAN - po wyjęciu z pudełka od razu może łączyć się z sieciami WiFi, w których zasięgu się znajduje.",
            "5":"",
            "6":"POWER DUO - Kasa NANO II Online  z PIN Padem Paytel",
            "7":"",
            "8":"Najlepszy sposób na płatności bezgotówkowe w Twojej firmie - Kasa łączy się tylko jednym kablem USB z PIN Padem Paytel i pracuje bez specjalnej konfiguracji.  Oferuj swoim klientom wygodne transakcje bezgotówkowe i nie trać czasu na wprowadzanie kwoty na terminalu – kasa zrobi to za Ciebie. Wyeliminujesz w ten sposób błędy i przyśpieszysz obsługę w puncie sprzedaży. Nie płać przez rok za użytkowanie PIN Pada w ramach akcji „Polska Bezgotówkowa”, a później ciesz się nawet o połowę mniejszymi kosztami dzierżawy PIN Pada!",
            "9":"",

        },
            url: ImagesOnline.nano2Online
            //url: "src/components/widgets/sortofneedfromnovitu/ItemPhotos/onlineFis/nano2online_bialy_skos1_0.jpg"
    },
    {
        name: "Nano II Online GSM",
        printer_register: false,
        cash_register: true,
        online: true,
        new_product: false,
        describe:  {
            "0":"NANO II ONLINE GSM",
            "1":"",
            "2":"porównaj produkty",
            "3":"",
            "4":"Następca najpopularniejszej i najczęściej sprzedawanej na polskim rynku kasy fiskalnej Novitus NANO. Doskonałe rozwiązanie zarówno w sprzedaży mobilnej jak i na tradycyjnym stanowisku sprzedaży. Model zachował najlepsze cechy pierwowzoru, czyli niewielkie rozmiary i ergonomiczny kształt, wodoodporną podświetlaną klawiaturę. Jednak nowy design i dodatkowe klawisze funkcyjne to szybsza i wygodniejsza obsługa oraz bardziej intuicyjna praca. Busy, wypożyczalnie, gabinety lekarskie, parkingi, gabinety kosmetyczne, sklepy - kasa NANO II zawsze dostosuje się do prowadzonego biznesu. Kasa Nano II Online w standardzie ma zamontowane łącze WLAN - po wyjęciu z pudełka od razu może łączyć się z sieciami WiFi, w których zasięgu się znajduje.",
            "5":"",
            "6":"POWER DUO - Kasa NANO II Online  z PIN Padem Paytel",
            "7":"",
            "8":"Najlepszy sposób na płatności bezgotówkowe w Twojej firmie - Kasa łączy się tylko jednym kablem USB z PIN Padem Paytel i pracuje bez specjalnej konfiguracji.  Oferuj swoim klientom wygodne transakcje bezgotówkowe i nie trać czasu na wprowadzanie kwoty na terminalu – kasa zrobi to za Ciebie. Wyeliminujesz w ten sposób błędy i przyśpieszysz obsługę w puncie sprzedaży. Nie płać przez rok za użytkowanie PIN Pada w ramach akcji „Polska Bezgotówkowa”, a później ciesz się nawet o połowę mniejszymi kosztami dzierżawy PIN Pada!",
            "9":"",

        },
            url: ImagesOnline.nano2Online
            //url: "src/components/widgets/sortofneedfromnovitu/ItemPhotos/onlineFis/nano2online_bialy_skos1_0.jpg"
    },
    {
        name: "One Online",
        printer_register: false,
        cash_register: true,
        online: true,
        new_product: false,
        describe: {
            "0":"ONE ONLINE",
            "1":"",
            "2":"Idealne połączenie dużej mocy, dotykowego ekranu oraz niewielkiej wagi i niezwykle małych wymiarów. Zmieści się na najmniejszym stanowisku sprzedaży, oferując jednocześnie niezwykłą elastyczność pracy oraz programowalny ekran sprzedaży. Wystawia faktury i korekty, dokumenty zwrotu, obsługuje dodawanie notatek i ID klienta. Łatwe raportowanie, stany magazynowe, otwarte rachunki oraz współpraca z chmurowym systemem NoviCloud.",
            "3":"",
            "4":"Skonfiguruj dowolną skrzynkę mailową, przeglądaj korespondencję, wysyłaj faktury i utrzymuj kontakty ze swoimi kontrahentami. W chwili przerwy korzystaj z kasy ONE jak z każdego innego tabletu, surfuj po Internecie, oglądaj Youtube i słuchaj muzyki, używaj  narzędzi  systemu  Android  -  zegar,  kalkulator,  kontakty,  galeria i kalendarz. Na urządzeniu można zainstalować także dodatkowe aplikacje sprzedażowe, przeznaczone np. dla gastronomii.",
            "5":"",
            "6":"POWER DUO - Kasa ONE Online z PIN Padem Paytel",
            "7":"",
            "8":"Najlepszy sposób na płatności bezgotówkowe w Twojej firmie - Kasa łączy się jednym kablem USB z PIN Padem Paytel i współpracuje bez specjalnej konfiguracji.  Oferuj swoim klientom wygodne transakcje bezgotówkowe i nie trać czasu na wprowadzanie kwoty na terminalu – kasa zrobi to za Ciebie. Wyeliminujesz w ten sposób błędy i przyśpieszysz obsługę w puncie sprzedaży. Nie płać przez rok za użytkowanie PIN Pada w ramach akcji „Polska Bezgotówkowa”, a później ciesz się nawet o połowę mniejszymi kosztami dzierżawy PIN Pada!",
            "9":"",

        },  
            url: ImagesOnlineRegistersGoodQualityPhotosPng.oneOnline_czarna2
            //url: ImagesOnline.oneOnline
            //url: "src/components/widgets/sortofneedfromnovitu/ItemPhotos/onlineFis/one1_300px.jpg"
    },
    {
        name: "One Online GSM24",
        printer_register: false,
        cash_register: true,
        online: true,
        new_product: false,
        describe: {
            "0":"ONE ONLINE GSM24",
            "1":"",
            "2":"porównaj produkty",
            "3":"",
            "4":"Idealne połączenie dużej mocy, dotykowego ekranu oraz niewielkiej wagi i niezwykle małych wymiarów. Zmieści się na najmniejszym stanowisku sprzedaży, oferując jednocześnie niezwykłą elastyczność pracy oraz programowalny ekran sprzedaży. Wystawia faktury i korekty, dokumenty zwrotu, obsługuje dodawanie notatek i ID klienta. Łatwe raportowanie, stany magazynowe, otwarte rachunki oraz współpraca z chmurowym systemem NoviCloud.",
            "5":"",
            "6":"Skonfiguruj dowolną skrzynkę mailową, przeglądaj korespondencję, wysyłaj faktury i utrzymuj kontakty ze swoimi kontrahentami. W chwili przerwy korzystaj z kasy ONE jak z każdego innego tabletu, surfuj po Internecie, oglądaj Youtube i słuchaj muzyki, używaj  narzędzi  systemu  Android  -  zegar,  kalkulator,  kontakty,  galeria i kalendarz. Na urządzeniu można zainstalować także dodatkowe aplikacje sprzedażowe, przeznaczone np. dla gastronomii.",
            "7":"",
            "8":"Kasa ONE ONLINE GSM24 w standardzie ma zamontowane łącze GPRS - wewnętrzny modem GSM wraz z kartą SIM sieci Orange, umożliwijącą transmisję danych poprzez siec komórkową przez okres 24 miesięcy. Oznacza to, że po wyjęciu z pudełka od razu łączy się z secią GSM jeśli znajduje się w jej zasięgu i może transmitować dane do serwerów CRK. Łącze GSM może służyć rónież do łączenia się kasy z NoviCloud oraz internetem.",
            "9":"",
            "10":"POWER DUO - Kasa ONE Online z PIN Padem Paytel",
            "11":"",
            "12":"Najlepszy sposób na płatności bezgotówkowe w Twojej firmie - Kasa łączy się jednym kablem USB z PIN Padem Paytel i współpracuje bez specjalnej konfiguracji.  Oferuj swoim klientom wygodne transakcje bezgotówkowe i nie trać czasu na wprowadzanie kwoty na terminalu – kasa zrobi to za Ciebie. Wyeliminujesz w ten sposób błędy i przyśpieszysz obsługę w puncie sprzedaży. Nie płać przez rok za użytkowanie PIN Pada w ramach akcji „Polska Bezgotówkowa”, a później ciesz się nawet o połowę mniejszymi kosztami dzierżawy PIN Pada!",
            "13":"",

        },
            url: ImagesOnlineRegistersGoodQualityPhotosPng.oneOnline_biala7
            //url: ImagesOnline.oneOnlineGSM24
            //url: "src/components/widgets/sortofneedfromnovitu/ItemPhotos/onlineFis/one1_300px.jpg"
    },
    {
        name: "Link Online",
        printer_register: false,
        cash_register: true,
        online: true,
        new_product: false,
        describe:  {
            "0":"LINK ONLINE",
            "1":"",
            "2":"porównaj produkty",
            "3":"",
            "4":"Kasa Novitus LINK Online to nowy produkt z dobrze znanej i uznanej na rynku gamy produktów Onlineowych marki Novitus. Optymalne połączenie wyśrubowanych parametrów technicznych oraz zaawansowanych funkcji to znak rozpoznawczy kasy dedykowanej do małych i średnich punktów sprzedaży i usług.",
            "5":"",
            "6":"LINK Online to kasa fiskalna umożliwiająca połączenie i przesyłanie danych do Centralnego Repozytorium Kas za pośrednictwem sieci teleinformatycznej.  Oznacza to, że spełnia wymagania Rozporządzenia Ministra Przedsiębiorczości i Technologii w sprawie kryteriów i warunków technicznych, którym muszą odpowiadać kasy rejestrujące oraz Ustawy o VAT. Do poprawnej współpracy z CRK wystarczy jedynie połączenie z właściwie skonfigurowaną siecią internetową. Mała i poręczna obudowa kasy kryje w sobie bardzo zaawansowaną technologię i funkcjonalność spotykaną wcześniej w segmencie dużych kas systemowych. Bardzo duża baza towarowa, precyzyjne nazewnictwo towarów, komunikacja on-line z komputerem, wiele złącz komunikacyjnych, w tym złącza LAN, RS232 i USB pozwalają stworzyć zaawansowany system sprzedaży dla średniego sklepu. Ergonomiczna budowa sprawia, że wielogodzinna praca na tej kasie będzie wygodna i efektywna.",
            "7":"",
            "8":"POWER DUO - Kasa LINK Online  z PIN Padem Paytel",
            "9":"",
            "10":"Najlepszy sposób na płatności bezgotówkowe w Twojej firmie - Kasa łączy się jednym kablem USB z PIN Padem Paytel i współpracuje bez specjalnej konfiguracji.  Oferuj swoim klientom wygodne transakcje bezgotówkowe i nie trać czasu na wprowadzanie kwoty na terminalu – kasa zrobi to za Ciebie. Wyeliminujesz w ten sposób błędy i przyśpieszysz obsługę w puncie sprzedaży. Nie płać przez rok za użytkowanie PIN Pada w ramach akcji „Polska Bezgotówkowa”, a później ciesz się nawet o połowę mniejszymi kosztami dzierżawy PIN Pada!",
            "11":"",

        },
            url: ImagesCashiers.linkOnline
            //url: "src/components/widgets/sortofneedfromnovitu/ItemPhotos/onlineFis/kasa_link3_b_0.png"
    },
    {
        name: "Link Online GSM24",
        printer_register: false,
        cash_register: true,
        online: true,
        new_product: false,
        describe:  {
            "0":"LINK ONLINE GSM24",
            "1":"",
            "2":"porównaj produkty",
            "3":"",
            "4":"Kasa Novitus LINK Online to nowy produkt z dobrze znanej i uznanej na rynku gamy produktów Onlineowych marki Novitus. Optymalne połączenie wyśrubowanych parametrów technicznych oraz zaawansowanych funkcji to znak rozpoznawczy kasy dedykowanej do małych i średnich punktów sprzedaży i usług. Kasa w wersji GSM24 wyróżnia się od wersji standardowej zamontowanym fabrycznie wewnętrznym modemem GSM z zamontowaną kartą SIM operatora Orange i transmisją danych przez 24 miesiące. Kasa jest od razu skonfigurowana do połączenia z siecią Internet.",
            "5":"",
            "6":"LINK Online to kasa fiskalna umożliwiająca połączenie i przesyłanie danych do Centralnego Repozytorium Kas za pośrednictwem sieci teleinformatycznej. Oznacza to, że spełnia wymagania Rozporządzenia Ministra Przedsiębiorczości i Technologii w sprawie kryteriów i warunków technicznych, którym muszą odpowiadać kasy rejestrujące oraz Ustawy o VAT. Do poprawnej współpracy z CRK wystarczy jedynie połączenie z właściwie skonfigurowaną siecią internetową. Mała i poręczna obudowa kasy kryje w sobie bardzo zaawansowaną technologię i funkcjonalność spotykaną wcześniej w segmencie dużych kas systemowych. Bardzo duża baza towarowa, precyzyjne nazewnictwo towarów, komunikacja on-line z komputerem, wiele złącz komunikacyjnych, w tym złącza LAN, RS232 i USB pozwalają stworzyć zaawansowany system sprzedaży dla średniego sklepu. Ergonomiczna budowa sprawia, że wielogodzinna praca na tej kasie będzie wygodna i efektywna.",
            "7":"",
            "8":"POWER DUO - Kasa LINK Online  z PIN Padem Paytel",
            "9":"",
            "10":"Najlepszy sposób na płatności bezgotówkowe w Twojej firmie - Kasa łączy się jednym kablem USB z PIN Padem Paytel i współpracuje bez specjalnej konfiguracji.  Oferuj swoim klientom wygodne transakcje bezgotówkowe i nie trać czasu na wprowadzanie kwoty na terminalu – kasa zrobi to za Ciebie. Wyeliminujesz w ten sposób błędy i przyśpieszysz obsługę w puncie sprzedaży. Nie płać przez rok za użytkowanie PIN Pada w ramach akcji „Polska Bezgotówkowa”, a później ciesz się nawet o połowę mniejszymi kosztami dzierżawy PIN Pada!",
            "11":"",

        },
            url: ImagesCashiers.linkOnlineGSM24
            //url: "src/components/widgets/sortofneedfromnovitu/ItemPhotos/onlineFis/kasa_link3_b_0.png"
    },
    {
        name: "Novitus Next",
        printer_register: false,
        cash_register: true,
        online: true,
        new_product: false,
        describe:{
            "0":"NOVITUS NEXT",
            "1":"",
            "2":"porównaj produkty",
            "3":"",
            "4":"Przedstawiamy Państwu NOVITUS NEXT - system fiskalny następnej generacji.",
            "5":"",
            "6":"Nadeszła przyszłość kas fiskalnych. Novitus od zawsze wyznacza standardy, tak jest i tym razem. Przedstawiamy Novitus next, pierwszą kasę fiskalną opartą o dotykowy tablet z systemem Android oraz zaawansowane, intuicyjne oprogramowanie. Kasa zaprojektowana wg najnowszych technologii przez doświadczonych, najlepszych w branży inżynierów. Rozpoczynamy nową erę kas fiskalnych.",
            "7":"",
            "8":"Zobacz specjalną witrynę www.next.novitus.pl na której dowiesz się wszystkiego, co powinieneś wiedzieć o kasie następnej generacji - NOVITUS NEXT",
            "9":"",
            "10":"Akcesoria dedykowane dla kasy NEXT dostępne są na stronie: Akcesoria",
            "11":"",

        },
            url: ImagesCashiers.nextOnline
            //url: "src/components/widgets/sortofneedfromnovitu/ItemPhotos/kasyfis/next_900px_4.jpg",
    },
    {
        name: "Mala Online",
        printer_register: false,
        cash_register: true,
        online: true,
        new_product: false,
        describe:  {
            "0":"MAŁA ONLINE",
            "1":"",
            "2":"porównaj produkty",
            "3":"",
            "4":"Kasa fiskalna Mała Plus E sprawdziła się w swojej roli w małych punktach sprzedaży. Wraz z postępem czasu postanowiliśmy stworzyć jej jeszcze lepszego następcę – kasę MAŁA Online. Większa baza towarowa pozwoli na pomieszczenie wszystkich oferowanych towarów i usług. Większa ilość grup towarowych pomoże utrzymać porządek w bazie. Dodaliśmy możliwość komplementacji towarów. Poszerzyliśmy możliwości płatności o najnowsze metody, dzięki czemu Twój klient jeszcze wygodniej zapłaci za towar lub usługę. Całość została dopełniona możliwością połączenia i przesyłania danych do Centralnego Repozytorium Kas za pośrednictwem sieci teleinformatycznej.  Oznacza to, że spełnia wymagania Rozporządzenia Ministra Przedsiębiorczości i Technologii w sprawie kryteriów i warunków technicznych, którym muszą odpowiadać kasy rejestrujące oraz Ustawy o VAT. Funkcje te są praktycznie bezobsługowe – użytkownik nie musi wykonywać żadnych dodatkowych operacji, jedynie zapewnić drukarce połączenie z właściwie skonfigurowaną siecią internetową. Nowa MAŁA Online to współczesna technologia dedykowana dla małych punktów sprzedaży ze względu na swoją ergonomię. Dzięki niewielkim wymiarom kasa nie zabierze dużo miejsca na – często ograniczonej – powierzchni stanowiska sprzedaży.",
            "5":"",
            "6":"POWER DUO - Kasa Mała Online  z PIN Padem Paytel",
            "7":"",
            "8":"Najlepszy sposób na płatności bezgotówkowe w Twojej firmie - Kasa łączy się jednym kablem USB z PIN Padem Paytel i współpracuje bez specjalnej konfiguracji.  Oferuj swoim klientom wygodne transakcje bezgotówkowe i nie trać czasu na wprowadzanie kwoty na terminalu – kasa zrobi to za Ciebie. Wyeliminujesz w ten sposób błędy i przyśpieszysz obsługę w puncie sprzedaży. Nie płać przez rok za użytkowanie PIN Pada w ramach akcji „Polska Bezgotówkowa”, a później ciesz się nawet o połowę mniejszymi kosztami dzierżawy PIN Pada!",
            "9":"",

        },
            url: ImagesOnlineRegistersGoodQualityPhotosPng.malaOnline01
            //url: ImagesCashiers.malaOnline
            //url: "src/components/widgets/sortofneedfromnovitu/ItemPhotos/kasyfis/mala-plus-e_900px.jpg"
    },
    {
        name: "Nano E",
        printer_register: false,
        cash_register: true,
        online: true,
        new_product: false,
        describe: {
            "0": "Kasa Nano E to nowoczesna technologia, zamknięta w poręcznej i ergonomicznej obudowie dedykowana dla osób prowadzących ruchomą sprzedaż (przenośną), lub tradycyjną, gdzie lada sklepowa ma ograniczoną powierzchnię.",
            "1": "",
            "2": "Kasa Nano E występuje w wersjach: \"med\" - dedykowanej dla lekarzy i dentystów, \"lex\" - dedykowane dla prawników, \"moto\" - dedykowanej dla warsztatów naprawy samochodów, opon i stacji diagnostycznych oraz  „L” – dedykowanej ośrodkom szkolenia kierowców. Jako jedyna kasa fiskalna otrzymała rekomendację Stowarzyszenia „KIEROWCA.PL”. Wersje kasy Nano E „med” – dla lekarzy i „lex” dla prawników, były najczęściej wybieranymi urządzeniami przez tych podatników w okresie pierwszego etapu fiskalizacji tych grup w 2011 roku.",
            "3": "",
            "4": "Nowoczesna technologia, zamknięta w poręcznej i ergonomicznej obudowie dedykowana dla osób prowadzących ruchomą sprzedaż (przenośną), lub tradycyjną, gdzie lada sklepowa ma ograniczoną powierzchnię.",
            "5": "",
            "6": "Cechy wyróżniające kasę NANO E:",
            "7": "Niewielkie rozmiary i lekka konstrukcja",
            "8": "Ergonomiczna obudowa z plastiku odpornego na uderzenia",
            "9": "Możliwość pracy w trudnych warunkach atmosferycznych: wysokie i niskie temperatury",
            "10": "Pierwsza kasa przenośna z elektroniczną kopią wydruku",
            "11": "Zapis elektronicznej kopii na karcie SD o dowolnej pojemności, dostępnej dla użytkownika",
            "12": "Dostęp do danych zapisanych na karcie SD z poziomu kasy (z możliwością wydruku) Dostęp do danych zapisanych na karcie SD z komputera z możliwością ich przeglądania, raportowania kopiowania",
            "13": "Dostarczony wraz z kasą program komputerowy do odczytu danych z karty SD z funkcją raportowania i wyszukiwania oraz do odczytu danych z pamięci fiskalnej z wykonaniem autoryzowanego raportu okresowego i miesięcznego fiskalnego",
            "14": "Zaawansowane zarządzanie energią",
            "15": "Bardzo wydajny wewnętrzny akumulator litowo-jonowy",
            "16": "Możliwość wydrukowania nawet 10 000 paragonów bez zasilania zewnętrznego. Po pojawieniu się komunikatu o rozładowaniu akumulatora można wydrukować jeszcze do 300 paragonów",
            "17": "Akumulator wewnętrzny wykonany w formie wymiennego pakietu",
            "18": "Możliwość ładowania akumulatora zasilaczem poza kasą",
            "19": "Alfanumeryczne LCD wyświetlacze dla kasjera i klienta, 2x16 znaków w kolorze niebieskim",
            "20": "Podświetlana klawiatura swithowa, precyzyjnie działająca, pokryta silikonową nakładką zabezpieczającą przez zalaniem",
            "21": "Klawiatura alfanumeryczna ułatwiająca obsługę i programowanie kasy oraz podawanie zakresów dat i numerów dla raportów okresowych",
            "22": "Programowalne klawisze funkcyjne F1, F2, F3, F4.",
            "23": "Złącza komunikacyjne RS232 oraz USB",
            "24": "Współpraca z większością programów sprzedaży przy użyciu najpopularniejszego protokołu komunikacyjnego na rynku",
            "25": "Praca ON-Line z komputerem, pojemność bufora 2000 pozycji",
            "26": "Funkcja obsługi parkingów",
            "27": "Złącza komunikacyjne oraz karty SD zabezpieczone gumowymi zatyczkami zabezpieczającymi przed zabrudzeniem oraz wilgocią",
            "28": "Specjalne miejsce na zamontowanie zewnętrznego moduły bluetooth do bezprzewodowej komunikacji z komputerem",
            "29": "Wewnętrzny moduł Bluetooth może współpracować z bezprzewodowym czytnikiem kodów kreskowych",
            "30": "Nazwa towaru lub usługi do 40 znaków",
            "31": "Bardzo szybki wydruk",
            "32": "Jeden mechanizm clamshell – bardzo łatwa i szybka wymiana papieru, brak konieczności wymiany kopii paragonu",
            "33": "Zaawansowany system oszczędzania papieru (zagęszczanie wydruku przy krótszych nazwach, raportach itp.)",
            "34": "Papier szerokości 57 mm",
            "35": "Wydruki potwierdzeń akceptacji kart płatniczych oraz zakupu pre-paidów",
            "36": "Możliwość drukowania logo graficznego w nagłówku paragonu",
            "37": "Możliwość drukowania grafiki w stopce paragonu",
            "38": "Możliwość drukowania NIP NABYWCY na paragonie fiskalnym",
            "39": "Drukowanie dowolnych wydruków niefiskalnych (jak na drukarkach fiskalnych)",
            "40": "Nowe funkcje usług BUS-owych",
            "41": "Raporty akcyzowe",
            "42": "Sterowanie drukarką zamówień (kuchenną)",
            "43": "",

        },
            url: ImagesCashiers.nanoE
            //url: "src/components/widgets/sortofneedfromnovitu/ItemPhotos/onlineFis/nano300x300_0.png"
    },
    {
        name: "Sento LanE, SentoLanE+",
        printer_register: false,
        cash_register: true,
        online: true,
        new_product: false,
        describe:{
            "0":"SENTO LAN E i SENTO LAN E +",
            "1":"",
            "2":"porównaj produkty",
            "3":"",
            "4":"Z przyjemnością prezentujemy Państwu nasza najnowsza kasę fiskalną Sento LAN E oraz jej rozbudowaną wersję SENTO LAN E +. Myli się ten, kto twierdzi, że jest to jedynie modyfikacja wcześniejszego modelu. Sento LAN E to zupełnie nowa kasa, z nową elektroniką opartą o nowoczesny procesor. Sento LAN E wykorzystuje obudowę znanej już i bardzo dobrze przyjętej przez rynek kasy Sento E, posiada wszystkie funkcje, które miała kasa Sento E, jednak jej parametry techniczne oraz nowe funkcjonalności przenoszą kasę Sento LAN E w zupełnie inny, wyższy segment rynku kas fiskalnych. Sento LAN E + posiada parametry spotykane jedynie w kasach systemowych: posiada bazę towarową 50.000 towarów.",
            "5":"",
            "6":"",
            "7":"",
            "8":"Akcesoria dedykowane dla kasy Sento LAN E przedstawione są na stronie: Akcesoria",
            "9":"",

        },
            url: ImagesCashiers.sentoLanE
            //url: "src/components/widgets/sortofneedfromnovitu/ItemPhotos/kasyfis/sento-lan-e_900px.jpg",
        }
];

//terminalePlatncze
export const arrayPayTerminals = [
    {
        name: "Ingenico IWL250/IWL220 (Terminal płatniczy)",
        terminal: true,
        scales: false,
        printer_register: false,
        cash_register: false,
        online: false,
        new_product: false,
        describe:{
            "0": "Terminal płatniczy Ingenico IWL250/IWL220",
            "1": "MobilnyIngenico IWL250/IWL220",
            "2": "Terminal płatniczy Ingenico IWL250 jest jednym z popularniejszych modeli dostępnych na rynku.",
            "3": "",
            "4": "Uwielbiany przed przedsiębiorców, ale również przez klientów. Bezprzewodowy IWL250/IWL220 oferuje pełen zakres sposobów płatności",
            "5": "",
            "6": "obsługa kart z paskiem magnetycznym,",
            "7": "obsługa kart wyposażonych w chip,",
            "8": "płatności zbliżeniowe,",
            "9": "płatności BLIK.",
            "10": "IWL250/IWL220 – bezprzewodowa efektywność płatności",
            "11": "Terminal Ingenico IWL250/IWL220 jest wyposażony przez producenta w kartę SIM, zapewniając stałe i bezprzewodowe połączenie z internetem za pomocą GPRS.",
            "12": "",
            "13": "Nie ogranicza Cię miejsce – mobilny IWL250 finalizuje płatności wszędzie, gdzie znajdzie się Twój klient.",
            "14": "",
            "15": "Płatności bezgotówkowe",
            "16": "Ingenico IWL250/IWL220 nie ogranicza użytkowników w elektronicznych sposobach płatności. Dzięki zainstalowanej aplikacji płatniczej PayTel możliwa jest płatność kartami:",
            "17": "",
            "18": "MasterCard,",
            "19": "VISA.",
            "20": "Bezpieczeństwo płatności",
            "21": "Terminal Ingenico IWL250/IWL220 został stworzony w zgodzie z najwyższymi standardami bezpieczeństwa oraz szyfrowania danych.",
            "22": "",
            "23": "Efektywność i wygoda przeprowadzania transakcji idzie w parze z pełnym ich bezpieczeństwem."
        },
        url: ImagesPayTerminals.iwl250_40mm_iwl250_inthehand_cartemdjpg,
        //url: "src/components/widgets/sortofneedfromnovitu/ItemPhotos/paytele/iwl250-40mm-iwl250-inthehand-cartemdjpg.jpg",
    },
    {
        name: "Ingenico iCT220 (Stacjonarny terminal płatniczy)",
        terminal: true,
        scales: false,
        printer_register: false,
        cash_register: false,
        online: false,
        new_product: false,
        describe:{
            "0": "Terminal płatniczy Ingenico iCT220",
            "1": "StacjonarnyIngenico iCT220",
            "2": "Terminal Ingenico ICT220 to idealne rozwiązanie dla przedsiębiorców, którym zależy na pełnej funkcjonalności opakowanej w nowoczesne oprogramowanie z zachowanymi najwyższymi standardami bezpieczeństwa.",
            "3": "",
            "4": "Terminal ICT220 wyposażony jest w potężny procesor ARM11, który umożliwia przetwarzanie procesów szyfrowania danych, procesów deszyfrowania danych w bardzo sprawny i szybki sposób.",
            "5": "",
            "6": "Terminal ICT220 możliwy jest w dwóch wariantach.",
            "7": "Pierwszym z wariantów jest ICT220 Ethernet, który gwarantuje wygodne, szybkie przetwarzanie transakcji z wykorzystaniem połączenia internetowego. Terminal ICT220 szczególnie polecany jest placówkom handlowym, które cechuje stałe połączenie internetowe.",
            "8": "",
            "9": "Drugi wariant terminala ICT220 wyposażony jest w sieć komórkową GPRS. ICT220 (GPRS) proponowany jest placówkom handlowym, które nie mają dostępu do stałego połączenia internetowego.",
            "10": "",
            "11": "Zalety terminala ICT220",
            "12": "Oszczędność miejsca przy kasie oraz wygoda w użyciu.",
            "13": "Płatności zbliżeniowe przy użyciu wewnętrznego czytnika lub zewnętrznego PIN Pada.",
            "14": "Zintegrowana drukarka termiczna z ergonomicznym zasobnikiem eliminuje problemy z zacięciem papieru.",
            "15": ""
        },
        url: ImagesPayTerminals.ict220_04w_1200_small
        //url: "src/components/widgets/sortofneedfromnovitu/ItemPhotos/paytele/ict220_04,w_1200,_small.jpg",
    },
    {
        name: "Ingenico Desk 3200 (Terminal płatniczy)",
        terminal: true,
        scales: false,
        printer_register: false,
        cash_register: false,
        online: false,
        new_product: false,
        describe:{
            "0": "Terminal płatniczy Ingenico Desk 3200",
            "1": "StacjonarnyIngenico Desk 3200",
            "2": "Terminal POS spod znaku Ingenico jest doskonałym wyborem dla każdego sklepu czy przedsiębiorstwa, dla którego funkcjonalność i niezawodność mają najwyższy priorytet.",
            "3": "",
            "4": "Zaczynając od aspektów wizualnych, kończąc na kwestiach systemowych, Ingenico Desk 3200 nie ma sobie równych.",
            "5": "",
            "6": "Design terminala pozwala na bezproblemowe płatności, przy nienagannym i bardzo ergonomicznym kształcie i wyglądzie. Niezależnie, czy wykonywana jest płatność mobilna, czy klasyczna przy pomocy paska magnetycznego – Desk 3200 nie zawodzi.",
            "7": "",
            "8": "Pełen wachlarz możliwych sposobów płatności sprawia, że terminal ten jest doskonałym wyborem do każdego sklepu czy marketu.",
            "9": "",
            "10": "Interfejs Desk 3200 pozwala na eksploatacje urządzenia bez żadnego problemu, nawet przy pierwszym użyciu terminala, a wysoki standard zabezpieczeń i szyfrowania zapewnia bezpieczeństwo każdej przeprowadzanej płatności.",
            "11": "",
            "12": "Zalety terminala Ingenico Desk 3200:",
            "13": "Najwyższy standard bezpieczeństwa PCI-PTS,",
            "14": "Nieograniczenia w obsłudze każdej metod,y płatności,",
            "15": "Intuicyjny i przyjazny interfejs,",
            "16": "Obsługa płatności NFC,",
            "17": "Maksymalna dostępność połączeń – GPRS, 3G, Dual Sim."
        },                
        url: ImagesPayTerminals.desk3200_3w_1200_small
        //url: "src/components/widgets/sortofneedfromnovitu/ItemPhotos/paytele/desk3200_3,w_1200,_small.jpg",
    },
    {
        name: "Ingenico Lane 3000 (Terminal płatniczy)",
        terminal: true,
        scales: false,
        printer_register: false,
        cash_register: false,
        online: false,
        new_product: false,
        describe:{
            "0": "Terminal płatniczy Ingenico Lane 3000",
            "1": "StacjonarnyIngenico Lane 3000",
            "2": "Przyciągający uwagę designem Pin-Pad Ingenico Lane 3000 zachwyca nie tylko wizualnie.",
            "3": "",
            "4": "Został on stworzony by sprostać wszystkim wymaganiom zarówno bezpieczeństwa, jak i wydajności.",
            "5": "",
            "6": "Pin-Pad Ingenico Lane 3000",
            "7": "Ultranowoczesny wygląd modelu Lane 3000, stojąc na ladzie, przyciągnie uwagę każdego klienta. Bardzo wyraźny i kolorowy wyświetlacz sprawia, że zwyczajna płatność przynosi odrobinę przyjemności.",
            "8": "",
            "9": "Kompaktowy kształt i bardzo drobne wymiary doceni każdy sprzedawca, zauważając jak niewiele miejsca zajmuje na ladzie.",
            "10": "",
            "11": "Ingenico Lane 3000 stawia wysoko poprzeczkę modelom konkurencyjnym. Zaawansowana funkcjonalność i bezpieczeństwo schowana w niepozornie wyglądającym urządzeniu została doceniona przez wielu przedsiębiorców, a co ważniejsze – przez wielu klientów.",
            "12": "",
            "13": "Wbudowany moduł NFC umożliwia obsługę każdej płatności mobilnej i zbliżeniowej, a systemowy interfejs sprawia, że korzystanie z Lane 3000 jest łatwe i intuicyjne.",
            "14": "",
            "15": "Pin-Pad Ingenico Lane 3000",
            "16": "Certyfikacja PCI-PTS 5 gwarantuje maksymalny poziom bezpieczeństwa podczas każdej płatności,",
            "17": "Oszczędność miejsca na ladzie,",
            "18": "Zachwycający design,",
            "19": "Kolorowy wyświetlacz LCD oraz ergonomiczną klawiaturę numeryczną,",
            "20": "Obsługa płatności zbliżeniowych i mobilnych."
        },                
        url: ImagesPayTerminals.lane3000_1w_1200_small
            //url: "src/components/widgets/sortofneedfromnovitu/ItemPhotos/paytele/lane3000_1,w_1200,_small.jpg",
    },
];

//wagi
export const arrayScales = [
    {
        name: "Waga DIBAL W-025",
        terminal: false,
        scales: true,
        printer_register: false,
        cash_register: false,
        online: false,
        new_product: false,
        describe:{
            "0": "Waga DIBAL W-025T:",
            "1": "",
            "2": "możliwość łączenia wag w sieci",
            "3": "komunikacja TCP/IP",
            "4": "możliwość zaprogramowania 5000 kodów PLU",
            "5": "podświetlane, przyjazne dla klienta i obsługi wyświetlacze LCD (segmentowe superlux) ułatwiające programowanie wagi „z klawiatury”",
            "6": "przyjazna struktura menu",
            "7": "doskonała konstrukcja drukarki - niezawodność, łatwa konserwacja i zakładanie papieru",
            "8": "możliwość drukowania na różnych rozmiarach etykiet – od najmniejszych 3 x 3 cm do największych 6 x 10 cm, co ze względu na fakt, że cena etykiet jest proporcjonalna do ich powierzchni, pozwala uzyskać duże oszczędności na materiałach eksploatacyjnych",
            "9": "wiele możliwych trybów pracy - podstawowy, samoobsługowy, automatyczne ważenie, praca z etykietami, paragonami, papierem ciągłym i samoprzylepnym itp.",
            "10": "20 fabrycznych i 20 programowalnych formatów etykiety",
            "11": "przystosowanie do wszystkich faz wprowadzenia EURO",
            "12": "możliwość programowania wag z komputera w dowolnej chwili niezależnie od czynności wykonywanej na wadze",
            "13": "nowy, łatwy w obsłudze program DFS pod Windows do programowania wag",
            "14": "znany, bezpłatny program RMS pod Windows do programowania wag z możliwością graficznego programowania formatu etykiet",
            "15": "współpraca z programami sprzedaży, takimi jak: KC Firma, Hipermarket, Small Business, WF-MAG, Sklep, PC-Market, Magnat i innymi",
            "16": "",

        },
        url: ImagesScales.dibalW025t
            //url: "src/components/widgets/sortofneedfromnovitu/ItemPhotos/Inne/dibal_w025t.jpg",
    },
    {//ok
        name: "Waga DIBAL BH",
        scales: true,
        printer_register: false,
        cash_register: false,
        online: false,
        new_product: false,
        describe:{
            "0": "Waga hakowa DIBAL BH jest przeznaczona do zastosowania w różnego rodzaju zakładach przemysłowych, hurtowniach, przeładowniach oraz na magazynach. Jest ona dostępna w dwóch zakresach ważenia: do 2000 kg lub do 5000 kg. Urządzenie jest wyposażone w bezprzewodowy pilot sterujący.",
            "1": "",
            "2": "Cechy produktu:",
            "3": "wyraźny czerwony wyświetlacz LED",
            "4": "możliwość pracy w temperaturach ujemnych",
            "5": "zakres ważenia do 2000 kg lub 5000 kg",
            "6": "bezprzewodowy pilot sterujący",
            "7": "waga hakowa",
            "8": "",
            "9": "ZALETY WAGI DIBAL BH:",
            "10": "wyraźny czerwony wyświetlacz LED",
            "11": "możliwość pracy w temperaturach ujemnych",
            "12": "bezprzewodowy pilot sterujący",
            "13": "zasilanie akumulatorowe",
            "14": "Waga nie jest legalizowana.",
            "15": "",

        }
        ,
        url: ImagesScales.dibalBH
            //url: "src/components/widgets/sortofneedfromnovitu/ItemPhotos/Scales/dibal_bh.jpeg",
    },
    {
        name: "Waga DIBAL BH",
        scales: true,
        printer_register: false,
        cash_register: false,
        online: false,
        new_product: false,
        describe:{
            "0": "Waga T-SCALE AHW to waga kontrolna przeznaczona do wykorzystania w małych placówkach handlowych oraz w gastronomii. Urządzenie wyposażone jest w pojedynczy podświetlany wyświetlacz LCD. Może być zasilane z sieci lub z wbudowanego akumulatora. Posiada ważną legalizację. Oprócz standardowych waga wyposażona jest w szereg dodatkowych funkcji jak: liczenie sztuk, ważenie procentowe, programowanie limitów.",
            "1": "",
            "2": "Cechy produktu:",
            "3": "możliwość zasilania z sieci lub wewnętrznego akumulatora",
            "4": "zakres ważenia: 6/15 kg",
            "5": "możliwość pracy w temperaturach ujemnych",
            "6": "niewielkie rozmiary",
            "7": "szalka ze stali nierdzewnej",
            "8": "",
            "9": "ZALETY WAGI T-SCALE AHW:",
            "10": "wbudowany akumulator zapewniający do 70 godzin pracy bez zasilania sieciowego",
            "11": "możliwość pracy w temperaturach ujemnych – do –10o C",
            "12": "niewielkie rozmiary",
            "13": "szalka ze stali nierdzewnej",
            "14": "dodatkowe funkcje: liczenie sztuk, ważenie procentowe, programowanie limitów",
            "15": "",

        },
        url: ImagesScales.dibalAHW_T
            //url: "src/components/widgets/sortofneedfromnovitu/ItemPhotos/Scales/ahwT-Scale.jpg",
    },
    {
        name: "Waga T-SCALE BWS 40x50",
        scales: true,
        printer_register: false,
        cash_register: false,
        online: false,
        new_product: false,
        describe:{
            "0": "Waga T-SCALE BWS 40x50 to nowa waga platformowa o zakresie ważenia do 60 kg lub do 150 kg. Jest ona wyposażona w funkcję liczenia sztuk oraz programowania limitów. Urządzenie to doskonale sprawdza się wszędzie tam, gdzie konieczne jest przeprowadzanie remanentu towarów składowanych luzem oraz prawidłowe rozliczanie magazynu. Obudowa wagi i czujnik wykonane są ze stali nierdzewnej.",
            "1": "",
            "2": "Cechy produktu:",
            "3": "wyraźny, podświetlany wyświetlacz LCD",
            "4": "stabilna konstrukcja",
            "5": "nierdzewna obudowa oraz czujnik wagowy",
            "6": "możliwość pracy w temperaturach  ujemnych",
            "7": "zakres ważenia do 60 kg lub do 150 kg",
            "8": "wodoodporność - IP65",
            "9": "",
            "10": "ZALETY WAGI T-SCALE BWS 40x50:",
            "11": "wyraźny, podświetlany wyświetlacz LCD ułatwiający odczyt ciężaru towarów",
            "12": "stabilna konstrukcja",
            "13": "nierdzewna obudowa oraz czujnik wagowy",
            "14": "możliwość pracy w ujemnych temperaturach",
            "15": "wodoodporność - IP65",
            "16": "",

        },
        url: ImagesScales.t_scale_bws_40_50
            //url: "src/components/widgets/sortofneedfromnovitu/ItemPhotos/Scales/t-scale_bws_40_50.jpg",
    },
    {
        name: "Waga T-SCALE BWS 32x36",
        scales: true,
        printer_register: false,
        cash_register: false,
        online: false,
        new_product: false,
        describe:{
            "0": "Waga T-SCALE BWS 32x36 to mała waga platformowa o zakresie ważenia do 30 kg. Jest ona wyposażona w funkcję liczenia sztuk oraz programowania limitów. Urządzenie to doskonale sprawdza się wszędzie tam, gdzie konieczne jest przeprowadzanie remanentu towarów składowanych luzem oraz prawidłowe rozliczanie magazynu. Obudowa wagi i czujnik wykonane są ze stali nierdzewnej.",
            "1": "",
            "2": "Cechy produktu:",
            "3": "wyraźny, podświetlany wyświetlacz LCD",
            "4": "stabilna konstrukcja",
            "5": "nierdzewna obudowa oraz czujnik wagowy",
            "6": "możliwość pracy w temperaturach  ujemnych",
            "7": "zakres ważenia do 30 kg",
            "8": "wodoodporność - IP65",
            "9": "",
            "10": "ZALETY WAGI T-SCALE BWS 32x36:",
            "11": "wyraźny, podświetlany wyświetlacz LCD ułatwiający odczyt ciężaru towarów",
            "12": "stabilna konstrukcja",
            "13": "nierdzewna obudowa oraz czujnik wagowy",
            "14": "możliwość pracy w ujemnych temperaturach",
            "15": "wodoodporność - IP65",
            "16": "",

        },
        url: ImagesScales.t_scale_bws_mala
            //url: "src/components/widgets/sortofneedfromnovitu/ItemPhotos/Scales/t-scale_bws_-_mala.jpg",
    },
    {
        name: "Waga SATURN 2M",
        scales: true,
        printer_register: false,
        cash_register: false,
        online: false,
        new_product: false,
        describe:{
            "0": "Waga SATURN 2M to nowa wersja dobrze przyjętej na rynku wagi SATURN dedykowana do współpracy z ladowymi skanerami kodów kreskowych MAGELLAN 2300HS. Dzięki zamknięciu skanera w obudowie wagi poprawia się komfort i szybkość pracy kasjera, ponieważ operacja ważenia i skanowania odbywa się w tym samym miejscu. Waga może współpracować z terminalem kasowym lub z kasą fiskalną. Jest wykonana ze stali nierdzewnej.",
            "1": "",
            "2": "Cechy produktu:",
            "3": "waga współpracuje z czytnikiem MAGELLAN 2300HS lub MAGELLAN 3300HS 2D",
            "4": "możliwość wbudowania wagi w boksy kasowe znajdujące się przy wyjściu ze sklepu",
            "5": "współpraca z kasami typu  ECR i POS",
            "6": "graficzny wyświetlacz LCD",
            "7": "kosz montażowy w zestawie",
            "8": "",
            "9": "Aktualnie waga dostępna jest także w zestawie ze skanerem kodów kreskowych:",
            "10": "",
            "14": "ZALETY WAGI SATURN 2M:",
            "15": "możliwość współpracy z kasami typu ECR i POS",
            "16": "stabilny i odporny na przeciążenia układ pomiarowy",
            "17": "graficzny wyświetlacz LCD umieszczony na obrotowym słupku o regulowanej wysokości",
            "18": "kosz montażowy w zestawie",
            "19": "dwuzakresowość",
            "20": "",
            "21": "Aktualnie waga dostępna jest także w zestawie ze skanerem kodów kreskowych:",
            "22": "",

        },
        url: ImagesScales.saturn_2
            //url: "src/components/widgets/sortofneedfromnovitu/ItemPhotos/Scales/saturn_2.jpg",
    },
    {
        name: "Waga T-SCALE AHC",
        scales: true,
        printer_register: false,
        cash_register: false,
        online: false,
        new_product: false,
        describe:{
            "0": "Waga T-SCALE AHC to waga kontrolna przeznaczona do wykorzystania w małych placówkach handlowych oraz w gastronomii. Urządzenie wyposażone jest w pojedynczy podświetlany wyświetlacz LCD. Może być zasilane z sieci lub z wbudowanego akumulatora. Posiada ważną legalizację. Oprócz standardowych waga wyposażona jest w szereg dodatkowych funkcji jak: rozbudowane liczenie sztuk, ważenie procentowe, programowanie limitów oraz sumowanie mas poszczególnych produktów.",
            "1": "",
            "2": "Cechy produktu:",
            "3": "możliwość zasilania z sieci lub wewnętrznego akumulatora",
            "4": "zakres ważenia: 6/15 kg",
            "5": "możliwość pracy w temperaturach ujemnych",
            "6": "niewielkie rozmiary",
            "7": "szalka ze stali nierdzewnej",
            "8": "",
            "9": "ZALETY WAGI T-SCALE AHC:",
            "10": "wbudowany akumulator zapewniający do 70 godzin pracy bez zasilania sieciowego",
            "11": "możliwość pracy w temperaturach ujemnych – do –10o C",
            "12": "niewielkie rozmiary",
            "13": "szalka ze stali nierdzewnej",
            "14": "dodatkowe funkcje: rozbudowane liczenie sztuk, ważenie procentowe, programowanie limitów, sumowanie mas poszczególnych produktów",
            "15": "",

        },            
        url: ImagesScales.t_scale_ahc
            //url: "src/components/widgets/sortofneedfromnovitu/ItemPhotos/Scales/t-scale_ahc.jpg",
    },
    {
        name: "Waga Vega 2",
        scales: true,
        printer_register: false,
        cash_register: false,
        online: false,
        new_product: false,
        describe:{
            "0": "Waga VEGA 2 to nowa wersja dobrze przyjętej na rynku wagi VEGA. Jest ona przeznaczona jest do pracy w obiektach handlowych różnej wielkości. Może być wykorzystywana jako wolnostojąca waga kontrolna lub waga zabudowana na stanowisku sprzedaży. Urządzenie może współpracować z terminalem kasowym lub z kasą fiskalną. Jest wykonane ze stali nierdzewnej.",
            "1": "",
            "2": "Cechy produktu:",
            "3": "graficzny wyświetlacz LCD umieszczony na obrotowym  słupku",
            "4": "szalka wykonana ze stali nierdzewnej",
            "5": "możliwość współpracy z kasą fiskalną lub terminalowym punktem sprzedaży",
            "6": "kosz montażowy w zestawie",
            "7": "",
            "8": "ZALETY WAGI VEGA 2:",
            "9": "możliwość współpracy z terminalowym punktem sprzedaży (TPV) lub z kasą fiskalną (ECR)",
            "10": "graficzny wyświetlacz LCD umieszczony na obrotowym słupku o regulowanej wysokości",
            "11": "szalka ze stali nierdzewnej o wymiarach 333 x 253 mm",
            "12": "w zestawie kosz montażowy",
            "13": "dwuzakresowość",
            "14": "",

        },
        url: ImagesScales.vega_2
            //url: "src/components/widgets/sortofneedfromnovitu/ItemPhotos/Scales/vega_2.jpg",
    },
    {
        name: "Waga EP-200T RS",
        scales: true,
        printer_register: false,
        cash_register: false,
        online: false,
        new_product: false,
        describe:{
            "0": "Waga EP-200T RS to pierwsza w ofercie firmy NOVITUS waga kalkulacyjna nowej marki - PRIS. Jest dedykowana dla wszystkich, którzy potrzebują niezawodnego urządzenia ważącego do sprzedaży detalicznej lub kontroli dostaw towarów ważonych. Jest wyposażona w podświetlane wyświetlacze LCD  oraz wewnętrzny akumulator co umożliwia jej wykorzystywanie również w sprzedaży obwoźnej. Wersja z wysięgnikiem oraz wbudowanym interfejsem RS232 i protokołem komunikacyjnym AP-1.",
            "1": "",
            "2": "Cechy produktu:",
            "3": "możliwość zasilania z sieci lub z wewnętrznego akumulatora",
            "4": "wyraźne podświetlane wyświetlacze LCD",
            "5": "zakres ważenia do 6/15 kg z dokładnością do 2/5 g",
            "6": "w zestawie dwie szalki: plastikowa i metalowa",
            "7": "śruba zabezpieczająca czujnik wagi na czas transportu",
            "8": "interfejs RS232 umożliwiajacy współpracę z kasą fiskalną i komputerem",
            "9": "",
            "10": "ZALETY WAGI PRIS EP-200T RS:",
            "11": "wbudowany akumulator zapewniający ok 20 godzin nieprzerwanej pracy bez zasilania sieciowego",
            "12": "wyraźne podświetlane wyświetlacze ciekłokrystaliczne",
            "13": "2 szalki w zestawie: plastikowa oraz metalowa",
            "14": "śruba zabezpieczająca czujnik wagi na czas transportu",
            "15": "interfejs RS232 umożliwiający współpracę z kasą fiskalną i komputerem",
            "16": "",

        },
        url: ImagesScales.waga_pris_ep200_4_ekran
            //url: "src/components/widgets/sortofneedfromnovitu/ItemPhotos/Scales/waga_pris_ep200_4_ekran.png",
    },
    {
        name: "Waga EP-200T",
        scales: true,
        printer_register: false,
        cash_register: false,
        online: false,
        new_product: false,
        describe:{
            "0": "Waga EP-200T to pierwsza w ofercie firmy NOVITUS waga kalkulacyjna nowej marki - PRIS. Jest dedykowana dla wszystkich, którzy potrzebują niezawodnego urządzenia ważącego do sprzedaży detalicznej lub kontroli dostaw towarów ważonych. Jest wyposażona w podświetlane wyświetlacze LCD  oraz wewnętrzny akumulator co umożliwia jej wykorzystywanie również w sprzedaży obwoźnej. Wersja z wysięgnikiem.",
            "1": "",
            "2": "Cechy produktu:",
            "3": "możliwość zasilania z sieci lub z wewnętrznego akumulatora",
            "4": "wyraźne podświetlane wyświetlacze LCD",
            "5": "zakres ważenia do 6/15 kg z dokładnością do 2/5 g",
            "6": "w zestawie dwie szalki: plastikowa i metalowa",
            "7": "śruba zabezpieczająca czujnik wagi na czas transportu",
            "8": "",
            "9": "ZALETY WAGI PRIS EP-200T:",
            "10": "wbudowany akumulator zapewniający ok 20 godzin nieprzerwanej pracy bez zasilania sieciowego",
            "11": "wyraźne podświetlane wyświetlacze ciekłokrystaliczne",
            "12": "2 szalki w zestawie: plastikowa oraz metalowa",
            "13": "śruba zabezpieczająca czujnik wagi na czas transportu",
            "14": "",

        },
        url: ImagesScales.waga_pris_ep200_4_ekran
            //url: "src/components/widgets/sortofneedfromnovitu/ItemPhotos/Scales/waga_pris_ep200_4_ekran.png",
    },
    {
        name: "Waga EW-200 RS",
        scales: true,
        printer_register: false,
        cash_register: false,
        online: false,
        new_product: false,
        describe:{
            "0": "Waga EW-200 RS to najnowsza w ofercie firmy NOVITUS waga prosta nowej marki - PRIS. Urządzenie wyposażone jest w dwa podświetlane wyświetlacze LCD. Jest zasilane z sieci lub z akumulatora. Waga doskonale sprawdza się w małych i średnich placówkach handlowych oraz w gastronomii. Posiada ważną legalizację. Wersja w wbudowanym interfejsem RS232 i protokołem komunikacyjnym AP-1.",
            "1": "",
            "2": "Cechy produktu:",
            "3": "zasilanie sieciowe lub akumulatorowe",
            "4": "podświetlane wyświetlacze LCD",
            "5": "lekka konstrukcja i niewielkie rozmiary",
            "6": "śruba zabezpieczająca czujnik na czas transportu",
            "7": "interfejs RS232 umożliwiający współpracę z kasą fiskalną i komputerem",
            "8": "",
            "9": "ZALETY WAGI PRIS EW-200 RS:",
            "10": "możliwość pracy w temperaturach ujemnych – do –10o C",
            "11": "niewielkie rozmiary",
            "12": "szalka wykonana ze stali nierdzewnej",
            "13": "wyraźne, podświetlane wyświetlacze ciekłokrystaliczne",
            "14": "śruba zabezpieczająca czujnik na czas transportu",
            "15": "interfejs RS232 umożliwiający współpracę z kasą fiskalną i komputerem",
            "16": "",

        },
        url: ImagesScales.waga_pris_ew200_2_ekran
            //url: "src/components/widgets/sortofneedfromnovitu/ItemPhotos/Scales/waga_pris_ew200_2_ekran.png",
    },
    {
        name: "Waga EP-210S RS",
        scales: true,
        printer_register: false,
        cash_register: false,
        online: false,
        new_product: false,
        describe:{
            "0": "Waga EP-210S RS to pierwsza w ofercie firmy NOVITUS waga kalkulacyjna nowej marki - PRIS. Jest dedykowana dla wszystkich, którzy potrzebują niezawodnego urządzenia ważącego do sprzedaży detalicznej lub kontroli dostaw towarów ważonych. Jest wyposażona w podświetlane wyświetlacze LCD  oraz wewnętrzny akumulator co umożliwia jej wykorzystywanie również w sprzedaży obwoźnej. Wersja z wbudowanym interfejsem RS232 i protokołem komunikacyjnym AP-1.",
            "1": "",
            "2": "Cechy produktu:",
            "3": "możliwość zasilania z sieci lub z wewnętrznego akumulatora",
            "4": "wyraźne podświetlane wyświetlacze LCD",
            "5": "zakres ważenia do 6/15 kg z dokładnością do 2/5 g",
            "6": "w zestawie dwie szalki: plastikowa i metalowa",
            "7": "śruba zabezpieczająca czujnik wagi na czas transportu",
            "8": "interfejs RS232 umożliwiajacy współpracę z kasą fiskalną i komputerem",
            "9": "",
            "10": "ZALETY WAGI PRIS EP-210S RS:",
            "11": "wbudowany akumulator zapewniający ok 20 godzin nieprzerwanej pracy bez zasilania sieciowego",
            "12": "wyraźne podświetlane wyświetlacze ciekłokrystaliczne",
            "13": "2 szalki w zestawie: plastikowa oraz metalowa",
            "14": "śruba zabezpieczająca czujnik wagi na czas transportu",
            "15": "interfejs RS232 umożliwiający współpracę z kasą fiskalną i komputerem",
            "16": "",

        },
        url: ImagesScales.waga_pris_ep200_1_ekran
            //url: "src/components/widgets/sortofneedfromnovitu/ItemPhotos/Scales/waga_pris_ep200_1_ekran.png",
    },
    {
        name: "Waga NEPTUN 2 + MAGELLAN 8400 S zestaw",
        scales: true,
        printer_register: false,
        cash_register: false,
        online: false,
        new_product: false,
        describe:{
            "0": "Waga NEPTUN 2 to najnowsza wersja dobrze przyjętej na rynku wagi NEPTUN przeznaczona do zastosowania w boksach kasowych w dużych placówkach handlowych oraz super i hipermarketach. Jest ona przystosowana do wbudowania do wnętrza skanera. Dzięki temu umożliwia zarówno kontrolowanie masy sprzedawanych towarów jak i sczytywanie umieszczonych na nich kodów kreskowych. Waga może współpracować z terminalem kasowym lub z kasą fiskalną.",
            "1": "",
            "2": "Cechy produktu:",
            "3": "waga współpracuje ze skanerem Bi-optycznym MAGELLAN 8300, 8400, 9300i, 9400i i 9800i",
            "4": "możliwość wbudowania wagi w boksy kasowe znajdujące się przy wyjściu ze sklepu",
            "5": "obrotowa podstawa wyświetlacza",
            "6": "",
            "7": "Aktualnie waga dostępna jest także w zestawach ze skanerami kodów kreskowych:",
            "8": "",
            "9": "NEPTUN 2 + MAGELLAN 8400 S - 4 690,00 zł netto",
            "10": "",
            "11": "ZALETY WAGI NEPTUN 2:",
            "12": "możliwość współpracy z terminalowym punktem sprzedaży (TPV) lub z kasą fiskalną (ECR)",
            "13": "oddzielony od szalki wyświetlacz ciekłokrystaliczny z obrotową podstawą",
            "14": "możliwość wskazywania masy, ceny, należności lub tylko masy",
            "15": "waga współpracuje ze skanerem MAGELLAN 8300, 8400, 9300i, 9400i oraz 9800i",
            "16": "",
            "17": "Aktualnie waga dostępna jest także w zestawach ze skanerami kodów kreskowych:",
            "18": "",

        },
        url: ImagesScales.neptun_2__magellan
            //url: "src/components/widgets/sortofneedfromnovitu/ItemPhotos/Scales/neptun_2__magellan.jpg",
    },
    {
        name: "Waga EP-200S",
        scales: true,
        printer_register: false,
        cash_register: false,
        online: false,
        new_product: false,
        describe:{
            "0": "Waga EP-200S to pierwsza w ofercie firmy NOVITUS waga kalkulacyjna nowej marki - PRIS. Jest dedykowana dla wszystkich, którzy potrzebują niezawodnego urządzenia ważącego do sprzedaży detalicznej lub kontroli dostaw towarów ważonych. Jest wyposażona w podświetlane wyświetlacze LCD  oraz wewnętrzny akumulator co umożliwia jej wykorzystywanie również w sprzedaży obwoźnej.",
            "1": "",
            "2": "Cechy produktu:",
            "3": "możliwość zasilania z sieci lub z wewnętrznego akumulatora",
            "4": "wyraźne podświetlane wyświetlacze LCD",
            "5": "zakres ważenia do 6/15 kg z dokładnością do 2/5 g",
            "6": "w zestawie dwie szalki: plastikowa i metalowa",
            "7": "śruba zabezpieczająca czujnik wagi na czas transportu.",
            "8": "",
            "9": "ZALETY WAGI PRIS EP-200S:",
            "10": "wbudowany akumulator zapewniający ok 20 godzin nieprzerwanej pracy bez zasilania sieciowego",
            "11": "wyraźne podświetlane wyświetlacze ciekłokrystaliczne",
            "12": "2 szalki w zestawie: plastikowa oraz metalowa",
            "13": "śruba zabezpieczająca czujnik wagi na czas transportu",

        },
        url: ImagesScales.waga_pris_ep200_1_ekran
            //url: "src/components/widgets/sortofneedfromnovitu/ItemPhotos/Scales/t-scale_tp-i.jpg",
    },
    {
        name: "Waga DIBAL SPC-S",
        scales: true,
        printer_register: false,
        cash_register: false,
        online: false,
        new_product: false,
        describe:{
            "0": "Waga DIBAL SPC-S to najnowsza w ofercie firmy NOVITUS waga kalkulacyjna dedykowana dla wszystkich, którzy potrzebują niezawodnego urządzenia ważącego do sprzedaży detalicznej lub kontroli dostaw towarów ważonych. Jest ona wyposażona w wewnętrzny akumulator co umożliwia jej wykorzystywanie również w sprzedaży obwoźnej.",
            "1": "",
            "2": "Cechy produktu:",
            "3": "możliwość zasilania z sieci lub z wewnętrznego akumulatora",
            "4": "wyraźne podświetlane wyświetlacze LCD",
            "5": "zakres ważenia do 6/15 kg z dokładnością do 2/5 g lub do 15/30 kg z dokładnością do 5/10 g",
            "6": "w zestawie dwie szalki: plastikowa i metalowa",
            "7": "",
            "8": "ZALETY WAGI DIBAL SPC-S:",
            "9": "wbudowany akumulator zapewniający do 100 godzin pracy bez zasilania sieciowego",
            "10": "wyraźne podświetlane wyświetlacze ciekłokrystaliczne",
            "11": "2 szalki w zestawie: plastikowa oraz metalowa",
            "12": "",
            "13": "",

        },
        url: ImagesScales.dibal_spc_s
            //url: "src/components/widgets/sortofneedfromnovitu/ItemPhotos/Scales/dibal_spc-s.jpeg",
    },
    {
        name: "Waga samoobsługowa DIBAL M-525SS",
        scales: true,
        printer_register: false,
        cash_register: false,
        online: false,
        new_product: false,
        describe:{
            "0": "Waga samoobsługowa DIBAL M-525SS jest uzupełnieniem oferty wag etykietujących serii 500 - MISTRAL. Jest ona zaprojektowana pod kątem pracy w warunkach największego obciążenia i z tego względu jest dedykowana do grupy najbardziej wymagających klientów. Wprowadzenie tej wagi umożliwiło przedstawienie im kompleksowej i jednolitej oferty. W wadze zastosowano zintegrowany na płycie głównej interfejs Ethernet. Urządzenie jest wyposażone w podświetlany wyświetlacz graficzny oraz nowy typ klawiatury.",
            "1": "",
            "2": "Cechy produktu:",
            "3": "nowoczesny design i funkcjonalność",
            "4": "możliwość łączenia w sieci wag",
            "5": "5000 PLU",
            "6": "wersja samoobsługowa",
            "7": "100 klawiszy bezpośrednich",
            "8": "podświetlane wyświetlacze graficzne L",
            "9": "możliwość drukowania na różnych rozmiarach etykiet",
            "10": "Ethernet",
            "11": "",
            "12": "ZALETY WAGI DIBAL M-525SS:",
            "13": "możliwość łączenia wag w sieci",
            "14": "komunikacja TCP/IP",
            "15": "opcjonalnie komunikacja Wi-Fi",
            "16": "możliwość zaprogramowania 5000 kodów towarów",
            "17": "podświetlane, przyjazne dla klienta i obsługi graficzne wyświetlacze LCD ułatwiające programowanie wagi „z klawiatury”",
            "18": "przyjazna struktura menu",
            "19": "doskonała konstrukcja drukarki – niezawodność, łatwa konserwacja i zakładanie papieru",
            "20": "kasetowy system wymiany etykiet",
            "21": "możliwość drukowania na różnych rozmiarach etykiet – od najmniejszych 3 x 3 cm do największych 6 x 10 cm, co ze względu na fakt, że cena etykiet jest proporcjonalna do ich powierzchni, pozwala uzyskać duże oszczędności na materiałach eksploatacyjnych",
            "22": "wiele możliwych trybów pracy - podstawowy, samoobsługowy, automatyczne ważenie, praca z etykietami, paragonami, papierem ciągłym i samoprzylepnym itp.",
            "23": "20 fabrycznych i 20 dowolnie programowalnych formatów etykiety",
            "24": "przystosowanie do wszystkich faz wprowadzenia EURO",
            "25": "możliwość programowania wag z komputera w dowolnej chwili niezależnie od czynności wykonywanej na wadze",
            "26": "nowy, łatwy w obsłudze program DFS pod Windows do programowania wag",
            "27": "znany, bezpłatny program RMS pod Windows do programowania wag z możliwością graficznego programowania formatu etykiet",
            "28": "współpraca z programami sprzedaży, takimi jak: KC Firma, Hipermarket, Small Business, WF-MAG, Sklep, PC-Market, Magnat i innymi",
            "29": "",

        },
        url: ImagesScales.dibal_m_525ss
            //url: "src/components/widgets/sortofneedfromnovitu/ItemPhotos/Scales/dibal_m-525ss.jpg",
    },
    {
        name: "Waga DIBAL G-305",
        scales: true,
        printer_register: false,
        cash_register: false,
        online: false,
        new_product: false,
        describe:{
            "0": "Waga DIBAL G-305 to najnowsza propozycja firmy NOVITUS jeśli chodzi o wagi proste. Urządzenie wyposażone jest w dwa podświetlane wyświetlacze LCD oraz interfejs RS232. Jest zasilane z sieci lub z akumulatora. Waga doskonale sprawdza się w małych i średnich placówkach handlowych oraz w gastronomii. Może być wykorzystywana zarówno, jako waga płaska, jak również, po zamontowaniu dołączonej do każdego opakowania wieży, jako waga z wysięgnikiem.",
            "1": "",
            "2": "Cechy produktu:",
            "3": "zasilanie sieciowe lub akumulatorowe",
            "4": "podświetlane wyświetlacze LCD",
            "5": "możliwość pracy z kasą fiskalną",
            "6": "lekka konstrukcja i niewielkie rozmiary",
            "7": "",
            "8": "ZALETY WAGI DIBAL G-305:",
            "9": "możliwość pracy w temperaturach ujemnych – do –10 C",
            "10": "niewielkie rozmiary",
            "11": "szalka wykonana ze stali nierdzewnej",
            "12": "możliwość współpracy z kasami fiskalnymi",
            "13": "wyraźne, podświetlane wyświetlacze ciekłokrystaliczne",
            "14": "możliwość umieszczenia wyświetlacza dla klienta w obudowie wagi lub na dołączonym wysięgniku",
            "15":"",

        },
        url: ImagesScales.dibal_g_300__305
            //url: "src/components/widgets/sortofneedfromnovitu/ItemPhotos/Scales/dibal_g-300__305.jpeg",
    },
    {
        name: "Waga DIBAL D-POS",
        scales: true,
        printer_register: false,
        cash_register: false,
        online: false,
        new_product: false,
        describe:{
            "0": "Waga DIBAL D-POS to rozwiązanie przeznaczone do zastosowania w boksach kasowych. Jest ona wykorzystywana przede wszystkim w dużych placówkach handlowych oraz super i hipermarketach. Umożliwia kontrolę masy sprzedawanych towarów bezpośrednio na stanowisku kasowym. Waga może współpracować z terminalem kasowym lub z kasą fiskalną.",
            "1": "",
            "2": "Cechy produktu:",
            "3": "możliwość  współpracy z terminalowym punktem sprzedaży (TPV) lub z kasą fiskalną (ECR)",
            "4": "możliwość wbudowania wagi w boksy kasowe znajdujące się przy wyjściu ze sklepu",
            "5": "możliwość umieszczenia wyświetlaczy w obudowie wagi (wersja płaska) lub na wysięgniku",
            "6": "obrotowa podstawa wysięgnika z wyświetlaczem",
            "7": "",
            "8": "ZALETY WAGI DIBAL D-POS:",
            "9": "możliwość współpracy z terminalowym punktem sprzedaży (TPV) lub z kasą fiskalną (ECR)",
            "10": "możliwość wbudowania w boks kasowy",
            "11": "możliwość umieszczenia wyświetlaczy w obudowie wagi (wersja płaska) lub na oddzielonym od szalki wysięgniku z obrotową podstawą",
            "12": "możliwość wskazywania masy, ceny, należności lub tylko masy",
            "13": "szalka ze stali nierdzewnej lub szkła hartowanego o wymiarach 345 x 275 mm",
            "14": "pamięć typu Flash umożliwiająca aktualizację programu wagi bezpośrednio z komputera",
            "15": "",

        },
        url: ImagesScales.dibal_d_pos_wysiegnik_0
            //url: "src/components/widgets/sortofneedfromnovitu/ItemPhotos/Scales/t-scale_tp-i.jpg",
    },
    {
        name: "Waga DIBAL W-025S",
        scales: true,
        printer_register: false,
        cash_register: false,
        online: false,
        new_product: false,
        describe:{
            "0": "Waga DIBAL W-025S to przedstawiciel najnowszej linii produktów Dibala w ofercie Novitus – serii 500. Nowoczesne wzornictwo, atrakcyjny wygląd, zadziwiająca funkcjonalność oraz prostota programowania i obsługi to główne atuty nowej serii wag etykietujących. Jako kontynuacja znanej i docenianej serii K jest ona również dedykowana do średnich i dużych placówek handlowych, w których wagi pracują w rozbudowanych sieciach.",
            "1": "",
            "2": "Cechy produktu:",
            "3": "nowoczesny design i funkcjonalność",
            "4": "możliwość łączenia w sieci wag",
            "5": "5000 PLU",
            "6": "wersja płaska",
            "7": "podświetlane, alfanumeryczne wyświetlacze LCD",
            "8": "możliwość drukowania na różnych rozmiarach etykiet",
            "9": "Ethernet",
            "10": "",
            "11": "ZALETY WAGI DIBAL W-025S:",
            "12": "możliwość łączenia wag w sieci",
            "13": "komunikacja TCP/IP",
            "14": "możliwość zaprogramowania 5000 kodów PLU",
            "15": "podświetlane, przyjazne dla klienta i obsługi wyświetlacze LCD (segmentowe superlux) ułatwiające programowanie wagi „z klawiatury”",
            "16": "przyjazna struktura menu",
            "17": "doskonała konstrukcja drukarki - niezawodność, łatwa konserwacja i zakładanie papieru",
            "18": "możliwość drukowania na różnych rozmiarach etykiet – od najmniejszych 3 x 3 cm do największych 6 x 10 cm, co ze względu na fakt, że cena etykiet jest proporcjonalna do ich powierzchni, pozwala uzyskać duże oszczędności na materiałach eksploatacyjnych",
            "19": "wiele możliwych trybów pracy - podstawowy, samoobsługowy, automatyczne ważenie, praca z etykietami, paragonami, papierem ciągłym i samoprzylepnym itp.",
            "20": "20 fabrycznych i 20 programowalnych formatów etykiety",
            "21": "przystosowanie do wszystkich faz wprowadzenia EURO",
            "22": "możliwość programowania wag z komputera w dowolnej chwili niezależnie od czynności wykonywanej na wadze",
            "23": "nowy, łatwy w obsłudze program DFS pod Windows do programowania wag",
            "24": "znany, bezpłatny program RMS pod Windows do programowania wag z możliwością graficznego programowania formatu etykiet",
            "25": "współpraca z programami sprzedaży, takimi jak: KC Firma, Hipermarket, Small Business, WF-MAG, Sklep, PC-Market, Magnat i innymi",
            "26": "",

        },
        url: ImagesScales.dibal_w025s
            //url: "src/components/widgets/sortofneedfromnovitu/ItemPhotos/Scales/dibal_w025s.jpg",
    },
    {
        name: "Waga DIBAL W-025T",
        scales: true,
        printer_register: false,
        cash_register: false,
        online: false,
        new_product: false,
        describe:{
            "0": "Waga DIBAL W-025T to kolejny przedstawiciel najnowszej linii produktów Dibala serii 500. Nowoczesne wzornictwo, atrakcyjny wygląd, zadziwiająca funkcjonalność oraz prostota programowania i obsługi to główne atuty tego urządzenia. Waga jest dedykowana do średnich i dużych placówek handlowych, w których wagi pracują w rozbudowanych sieciach. Wersja z wyświetlaczem na wysięgniku.",
            "1": "",
            "2": "Cechy produktu:",
            "3": "nowoczesny design i funkcjonalność",
            "4": "możliwość łączenia w sieci wag",
            "5": "5000 PLU",
            "6": "podświetlane, alfanumeryczne wyświetlacze LCD",
            "7": "możliwość drukowania na różnych rozmiarach etykiet",
            "8": "Ethernet",
            "9": "",
            "10": "ZALETY WAGI DIBAL W-025T:",
            "11": "możliwość łączenia wag w sieci",
            "12": "komunikacja TCP/IP",
            "13": "możliwość zaprogramowania 5000 kodów PLU",
            "14": "podświetlane, przyjazne dla klienta i obsługi wyświetlacze LCD (segmentowe superlux) ułatwiające programowanie wagi „z klawiatury”",
            "15": "przyjazna struktura menu",
            "16": "doskonała konstrukcja drukarki - niezawodność, łatwa konserwacja i zakładanie papieru",
            "17": "możliwość drukowania na różnych rozmiarach etykiet – od najmniejszych 3 x 3 cm do największych 6 x 10 cm, co ze względu na fakt, że cena etykiet jest proporcjonalna do ich powierzchni, pozwala uzyskać duże oszczędności na materiałach eksploatacyjnych",
            "18": "wiele możliwych trybów pracy - podstawowy, samoobsługowy, automatyczne ważenie, praca z etykietami, paragonami, papierem ciągłym i samoprzylepnym itp.",
            "19": "20 fabrycznych i 20 programowalnych formatów etykiety",
            "20": "przystosowanie do wszystkich faz wprowadzenia EURO",
            "21": "możliwość programowania wag z komputera w dowolnej chwili niezależnie od czynności wykonywanej na wadze",
            "22": "nowy, łatwy w obsłudze program DFS pod Windows do programowania wag",
            "23": "znany, bezpłatny program RMS pod Windows do programowania wag z możliwością graficznego programowania formatu etykiet",
            "24": "współpraca z programami sprzedaży, takimi jak: KC Firma, Hipermarket, Small Business, WF-MAG, Sklep, PC-Market, Magnat i innymi",
            "25": "",

        },
        url: ImagesScales.dibal_w025t
            //url: "src/components/widgets/sortofneedfromnovitu/ItemPhotos/Scales/dibal_w025t.jpg",
    },
    {
        name: "Waga DIBAL SPC-T",
        scales: true,
        printer_register: false,
        cash_register: false,
        online: false,
        new_product: false,
        describe:{
            "0": "Waga DIBAL SPC-T to najnowsza w ofercie firmy NOVITUS waga kalkulacyjna dedykowana dla wszystkich, którzy potrzebują niezawodnego urządzenia ważącego do sprzedaży detalicznej lub kontroli dostaw towarów ważonych. Jest ona wyposażona w wewnętrzny akumulator co umożliwia jej wykorzystywanie również w sprzedaży obwoźnej. Dodatkowo model T wyposażony jest w wygodny wysięgnik z wyświetlaczem.",
            "1": "",
            "2": "Cechy produktu:",
            "3": "możliwość zasilania z sieci lub z wewnętrznego akumulatora",
            "4": "wyraźne podświetlane wyświetlacze LCD",
            "5": "zakres ważenia do 6/15 kg z dokładnością do 2/5 g lub do 15/30 kg z dokładnością do 5/10 g",
            "6": "w zestawie dwie szalki: plastikowa i metalowa",
            "7": "",
            "8": "ZALETY WAGI DIBAL SPC-T:",
            "9": "wbudowany akumulator zapewniający do 100 godzin pracy bez zasilania sieciowego",
            "10": "wyraźne podświetlane wyświetlacze ciekłokrystaliczne",
            "11": "2 szalki w zestawie: plastikowa oraz metalowa",
            "12": "",

        },
        url: ImagesScales.dibal_spc_t
            //url: "src/components/widgets/sortofneedfromnovitu/ItemPhotos/Scales/dibal_spc-t.jpg",
    },
    {
        name: "Waga DIBAL S-545DB",
        scales: true,
        printer_register: false,
        cash_register: false,
        online: false,
        new_product: false,
        describe:{
            "0": "Waga DIBAL S-545DB to pierwszy przedstawiciel najnowszej linii produktów Dibala w ofercie NOVITUS - wag serii 500 - STAR. Waga jest wyposażona w zintegrowany na płycie głównej interfejs Ethernet oraz bardzo wydajny mechanizm drukujący. To w połączeniu z nowoczesnym wzornictwem, funkcjonalnością oraz prostotą programowania i obsługi sprawia, że jest to idealne urządzenie dla placówek handlowych średniej i dużej wielkości. Dodatkowym atutem wagi są 7\" wyświetlacze TFT.",
            "1": "",
            "2": "Cechy produktu:",
            "3": "nowoczesny design i funkcjonalność",
            "4": "możliwość łączenia w sieci wag",
            "5": "10000 PLU",
            "6": "wersja z klawiaturą i wyświetlaczem na wysięgniku",
            "7": "wyświetlacze TFT",
            "8": "możliwość drukowania na różnych rozmiarach etykiet",
            "9": "Ethernet",
            "10": "",
            "11": "ZALETY WAGI DIBAL S-545DB:",
            "12": "możliwość łączenia wag w sieci",
            "13": "komunikacja TCP/IP",
            "14": "opcjonalnie komunikacja Wi-Fi",
            "15": "7\" wyświetlacze TFT",
            "16": "przyjazna struktura menu",
            "17": "doskonała konstrukcja drukarki – niezawodność, łatwa konserwacja i zakładanie papieru",
            "18": "kasetowy system wymiany etykiet",
            "19": "możliwość drukowania na różnych rozmiarach etykiet – od najmniejszych 3 x 3 cm do największych 6 x 10 cm, co ze względu na fakt, że cena etykiet jest proporcjonalna do ich powierzchni, pozwala uzyskać duże oszczędności na materiałach eksploatacyjnych",
            "20": "wiele możliwych trybów pracy - podstawowy, samoobsługowy, automatyczne ważenie, praca z etykietami, paragonami, papierem ciągłym i samoprzylepnym itp.",
            "21": "20 fabrycznych i 20 dowolnie programowalnych formatów etykiety",
            "22": "przystosowanie do wszystkich faz wprowadzenia EURO",
            "23": "możliwość programowania wag z komputera w dowolnej chwili niezależnie od czynności wykonywanej na wadze",
            "24": "nowy, łatwy w obsłudze program DFS pod Windows do programowania wag",
            "25": "znany, bezpłatny program RMS pod Windows do programowania wag z możliwością graficznego programowania formatu etykiet",
            "26": "współpraca z programami sprzedaży, takimi jak: KC Firma, Hipermarket, Small Business, WF-MAG, Sklep, PC-Market, Magnat i innymi",
            "27": "",

        },
        url: ImagesScales.dibal_s_545db
            //url: "src/components/widgets/sortofneedfromnovitu/ItemPhotos/Scales/dibal_s-545db.jpg",
    },
    {
        name: "Waga DIBAL PVC-50 42x52",
        scales: true,
        printer_register: false,
        cash_register: false,
        online: false,
        new_product: false,
        describe:{
            "0": "Waga DIBAL PVC-50 42x52 to wyposażona w szalkę o rozmiarach 420 x 520 mm wersja wagi PVK-310. Jest ona dostępna w 3 zakresach ważenia: do 60 kg, do 150 kg oraz do 300 kg. Waga posiada funkcję liczenia sztuk oraz programowania limitów. Dodatkowo jest wyposażona  w wewnętrzny akumulator.",
            "1": "",
            "2": "Cechy produktu:",
            "3": "podświetlany wyświetlacz LCD",
            "4": "stabilna, konstrukcja i nierdzewna platforma",
            "5": "możliwość pracy w temperaturach  ujemnych",
            "6": "zakres ważenia do 60 kg, do 150 kg lub do 300  kg",
            "7": "zasilanie akumulatorowe",
            "8": "",
            "9": "ZALETY WAGI DIBAL PVC-50 42x52:",
            "10": "podświetlany wyświetlacz LCD ułatwiający odczyt ciężaru towarów",
            "11": "stabilna konstrukcja",
            "12": "nierdzewna platforma",
            "13": "możliwość pracy w ujemnych temperaturach",
            "14": "wbudowany akumulator zapewniający do 100 godzin pracy bez zasilania sieciowego",
            "15": "",

        },
        url: ImagesScales.dibal_pvc_50
            //url: "src/components/widgets/sortofneedfromnovitu/ItemPhotos/Scales/dibal_pvc-50.jpeg",
    },
    {
        name: "Mobilna waga paletowa T-SCALE TP-I",
        scales: true,
        printer_register: false,
        cash_register: false,
        online: false,
        new_product: false,
        describe:{
            "0": "Mobilna waga paletowa T-SCALE TP-I jest przeznaczona do zastosowań przemysłowych. Waga jest wykonana ze stali malowanej proszkowo. Jest ona wyposażona w cztery precyzyjne czujniki tensometryczne DMS. Posiada ważną legalizację.",
            "1": "",
            "2": "Cechy produktu:",
            "3": "wózek paletowy z wbudowaną wagą",
            "4": "zakres ważenia do 2000 kg",
            "5": "konstrukcja stalowa z płaskownika stalowego, malowana proszkowo",
            "6": "wbudowany miernik z tworzywa",
            "7": "",
            "8": "ZALETY WAGI T-SCALE TP-I:",
            "9": "6-pozycyjny wyświetlacz LCD z podświetlanym tłem",
            "10": "możliwość pracy w temperaturach ujemnych",
            "11": "wbudowany akumulator 6Vz czasem pracy do ok. 56 godzin.",
            "12": "wbudowany wyświetlacz",
            "13": "12-miesięczna gwarancja",
            "14": "",

        },
        url: ImagesScales.t_scale_tp_i
            //url: "src/components/widgets/sortofneedfromnovitu/ItemPhotos/Scales/t-scale_tp-i.jpg",
    },
    {
        name: "Mobilna waga paletowa T-SCALE TP-I",
        scales: true,
        printer_register: false,
        cash_register: false,
        online: false,
        new_product: false,
        describe:{
            "0": "Mobilna waga paletowa T-SCALE TP-I jest przeznaczona do zastosowań przemysłowych. Waga jest wykonana ze stali malowanej proszkowo. Jest ona wyposażona w cztery precyzyjne czujniki tensometryczne DMS. Posiada ważną legalizację.",
            "1": "",
            "2": "Cechy produktu:",
            "3": "wózek paletowy z wbudowaną wagą",
            "4": "zakres ważenia do 2000 kg",
            "5": "konstrukcja stalowa z płaskownika stalowego, malowana proszkowo",
            "6": "wbudowany miernik z tworzywa",
            "7": "",
            "8": "ZALETY WAGI T-SCALE TP-I:",
            "9": "6-pozycyjny wyświetlacz LCD z podświetlanym tłem",
            "10": "możliwość pracy w temperaturach ujemnych",
            "11": "wbudowany akumulator 6Vz czasem pracy do ok. 56 godzin.",
            "12": "wbudowany wyświetlacz",
            "13": "12-miesięczna gwarancja",
            "14": "",

        },
        url: ImagesScales.t_scale_tp_i
            //url: "src/components/widgets/sortofneedfromnovitu/ItemPhotos/Scales/t-scale_tp-i.jpg",
    },

];

//szuflady
export const arrayDrawers = [
    {
        name: "Szuflada duża",
        terminal: true,
        scales: true,
        printer_register: false,
        cash_register: false,
        online: false,
        new_product: false,
        drawer: true,
        describe:{
            "0": "Szuflada duża:",
            "1": "Szuflada EC-410, występuje w wersjach do:",
            "2": "",
            "3": "Kas PS3000 (przewód zakończony wtykiem HU2)",
            "4": "Drukarek DELIO, QUARTO (przewód zakończony wtykiem DB9 \"męski\")",
            "5": "Drukarka HD E, BONO E, NEXT, Soleo Lan E, Sento LAN E (przewód zakończony wtykiem RJ6)",
            "6": "Oporność cewki: ok. 12-13 Ohm",
            "7": "",
            "8": "Wyzwalanie napięciem: 12 - 24V",
            "9": "",
            "10": "Wymiary: · szerokość - 410 mm ·",
            "11": "",
            "12": "głębokość - 417 mm ·",
            "13": "",
            "14": "wysokość - 100 mm (110 mm z nóżkami)",
            "15": "",
            "16": "Waga: 6,20 kg",
            "17": "",
            "18": "Wkład: 5 przedziałów na banknoty i 8 przedziałów na monety.",
            "19": "",
            "20": "Wkład na monety – wyjmowany, pod spodem dodatkowe miejsce na przechowywanie banknotów, dokumentów itp.",
            "21": "",
            "22": "Możliwość regulacji szerokości przedziałów na banknoty. Zamek trójpozycyjny (otwarcie szuflady, otwieranie elektromagnesem, zablokowanie) z kluczem - dwa klucze w komplecie. Szczelina (podwójna) do wsuwania czeków, bonów i talonów bez otwierania szuflady. Możliwość awaryjnego otwierania (dźwignia umieszczona w od spodu szuflady).",
            "23": "",
        },                
        url: ImageDrawers.duza_szuflada,
        //url: "src/components/widgets/sortofneedfromnovitu/ItemPhotos/drawers/szyflada-duza.jpg",
    },
    {
        name: "Szuflada średnia",
        terminal: false,
        scales: false,
        printer_register: false,
        cash_register: false,
        online: false,
        new_product: false,
        drawer: true,
        describe:{
            "0": "Szuflada średnia:",
            "1": "Wymiary: 350/104/405 [mm]. Cztery przegrody z dociskami na banknoty, 8 przedziałów na bilon. Przegrody i przedziały wyjmowane i o regulowanej pojemności. Przycisk awaryjnego otwierania pod szufladą. Zabezpieczenie(zamknięcie) szuflady kluczykiem (2 szt. w komplecie). Kolor grafit. Napięcie elektromagnesu szuflady ok. 6 [V].",
            "2": "",
            "3": "Występuje w wersjach do:",
            "4": "",
            "5": "Kas PS3000, Soleo Plus, Soleo E (przewód zakończony wtykiem HU2)",
            "6": "Drukarek DELIO, QUARTO (przewód zakończony wtykiem DB9 \"męski\")",
            "7": "Drukarka HD E, BONO E, NEXT, Soleo Lan E, Sento LAN E (przewód zakończony wtykiem RJ6)",
            "8": "",
        },                
        url: ImageDrawers.srednia_szuflada,
        //url: "src/components/widgets/sortofneedfromnovitu/ItemPhotos/drawers/szuflada_srednia.jpg",
    },
    {
        name: "Szuflada średnia aktywna",
        terminal: false,
        scales: false,
        printer_register: false,
        cash_register: false,
        online: false,
        new_product: false,
        drawer: true,
        describe:{
            "0": "Szuflada średnia aktywna:",
            "1": "Szuflada aktywna podłączana do kasy i sterowana sygnałem \"logicznym\" ze złącza RS232 (RJ45), zasilana zasilaczem kasy. Wymiary: szerokość: 350 mm, głębokość: 405 mm, wysokość: 105 mm. Cztery przegrody z dociskami na banknoty, 6 przedziałów na bilon. Przegrody i przedziały wyjmowane i o regulowanej pojemności. Przycisk awaryjnego otwierania pod szufladą. Oporność elektromagnesu szuflady ok. 6 Ohm. Kolor grafit.",
            "2": "",
            "3": "Współpracuje z kasami: Mała / Mała Plus / Mała Plus E / Lupo / Lupo E / Frigo II / Bravo / Sento E, Sento LAN E",
            "4": "",
        },                
        url: ImageDrawers.aktywna_srednia_szuflada,
        //url: "src/components/widgets/sortofneedfromnovitu/ItemPhotos/drawers/szuflada_srednia_aktywna4.jpg",
    },
    {
        name: "Szuflada średnia pasywna Sento LAN E",
        terminal: false,
        scales: false,
        printer_register: false,
        cash_register: false,
        online: false,
        new_product: false,
        drawer: true,
        describe:{
            "0": "Szuflada średnia pasywna do Sento LAN E:",
            "1": "Szuflada pasywna, sterowana napięciem. Dedykowana do kasy Sento LAN E. Oporność cewki elektromagnesu ok. 6 Ohm. Wymiary: szerokość: 350 mm, głębokość: 405 mm, wysokość: 105 mm. Cztery przegrody z dociskami na banknoty, 8 przedziałów na bilon. Przegrody i przedziały wyjmowane. Możliwość  regulacji szerokości przegród na banknoty. Przycisk awaryjnego otwierania pod szufladą. Otwieranie szuflady elektromagnesem oraz jednym z dwóch dołączonych kluczy. Zabezpieczenie (zamknięcie, bez możliwości otwarcia elektromagnesem) szuflady kluczykiem. Kolor grafit. W górnej powierzchni szuflady zamontowane dwa zaczepy do zamontowania na niej kasy Sento LAN E. Przewód szuflada-kasa zakończony złączem RJ6 (telefonicznym) do bezpośredniego podłączenia do kasy Sento LAN E.",
            "2": "",
        },                
        url: ImageDrawers.pasywna_szuflada_sentoLanE,
        //url: "src/components/widgets/sortofneedfromnovitu/ItemPhotos/drawers/szuflada_srednia.jpg",
    },
    {
        name: "Szuflada aktywna do Nano E / Deon E",
        terminal: false,
        scales: false,
        printer_register: false,
        cash_register: false,
        online: false,
        new_product: false,
        drawer: true,
        describe:{
            "0": "Szuflada aktywna do Nano E / Deon E:",
            "1": "Wymiary: 350/104/405 [mm]. Cztery przegrody z dociskami na banknoty, 8 przedziałów na bilon. Przegrody i przedziały wyjmowane i o regulowanej pojemności. Dwa klucze do zamknięcia szuflady. Przycisk awaryjnego otwierania pod szufladą. Szuflada \"aktywna\" podłączana do złącza RS232 (RJ4) kasy/drukarki i sterowana sygnałem \"logicznym\" , zasilana własnym zasilaczem 9V (w komplecie z szufladą). Napięcie elektromagnesu szuflady ok. 8-12 [V]. Kolor grafit. Uwaga! Przy podłączonej szufladzie aktywnej współpraca kasy/drukarki z komputerem odbywa się przez port USB!",
            "2": "",
        },                
        url: ImageDrawers.aktywna_szuflada_deon_e_nano_e,
        //url: "src/components/widgets/sortofneedfromnovitu/ItemPhotos/drawers/szuflada_aktywna_do_deon_e_nano_e_9.jpg",
    },
    {
        name: "Szuflada typu Flip-Top",
        terminal: false,
        scales: false,
        printer_register: false,
        cash_register: false,
        online: false,
        new_product: false,
        drawer: true,
        describe:{
            "0": "Szuflada typu Flip-Top:",
            "1": "Szuflada otwierana „do góry”. Posiada kluczyk otwierający szufladę lub blokujący ją przed otwarciem „z urządzenia fiskalnego”. Wewnątrz szuflady kaseta na pieniądze z drugim kluczykiem. Kaseta wyjmowana z obudowy. Po otwarciu kluczykiem i zdjęciu pokrywy kaseta posiada 8 pojemników na bilon oraz 6 przegród na banknoty. Kolor: grafit, Napięcie sterowania 24 V. Wymiary: 460 x 170 x 100 mm",
            "2": "",
        },                
        url: ImageDrawers.flip_top_szuflada,
        //url: "src/components/widgets/sortofneedfromnovitu/ItemPhotos/drawers/szuflada_typu_flip_top_16_0.jpg",
    },
];

//metkownice
export const arrayLabellers = [
    {
        name: "Metkownica MX 5500",
        scales: false,
        printer_register: false,
        cash_register: false,
        online: false,
        new_product: false,
        labeller: true,
        describe:{
            "0": "MX 5500 to najtańsza na rynku polskim metkownica jednowierszowa, co w połączeniu z dobrą jakością jej wykonania sprawia, że od wielu lat cieszy się ona niesłabnącym popytem. MX 5500 może drukować 8 znaków w rzędzie. Rozmiar metki 22 x 12 mm (jedno nacięcie – pomiędzy metkami).",
            "1": "",
            "2": "Metkownica jednowierszowa",
            "3": "Możliwość druku 8 cyfr w wierszu",
            "4": "Możliwość druku znaków specjalnych",
            "5": "Wymiar metki 22 x 12 mm",
            "6": "Taśma: MHK1",
            "7": "",
            "8": "DOSTĘPNE ZNAKI I SYMBOLE",
            "9": "Cyfry:",
            "10": "Od 0 do 9",
            "11": "Znaki specjalne:",
            "12": "„$” „€” „₤” „zł” „SK” „Kč” „Ft” „kg” „g” „¢” „EA” „RS” „PC” „F” „.” „-„ „/” „½”",
            "13": "",
        },
        url: ImagesLabellers.mx5500_0
            //url: "src/components/widgets/sortofneedfromnovitu/ItemPhotos/Labellers/mx_5500_0.jpeg",
    },
    {
        name: "Metkownica MX2616",
        scales: false,
        printer_register: false,
        cash_register: false,
        online: false,
        new_product: false,
        labeller: true,
        describe:{
            "0": "Metkownica dwuwierszowa MX2616 znajduje się w ofercie firmy NOVITUS już od wielu lat. Przez cały ten okres, ze względu na prostotę obsługi oraz przystępną cenę cieszy się dużym popytem. Metkownica może drukować po 10 znaków w rzędzie górnym i dolnym. Rozmiar metki 26 x 16 mm (dwa nacięcia - pomiędzy metkami).",
            "1": "",
            "2": "Metkownica dwuwierszowa",
            "3": "Możliwość druku 10 cyfr w wierszu górnym",
            "4": "Możliwość druku 10 cyfr w wierszu dolnym",
            "5": "Możliwość druku znaków specjalnych",
            "6": "Wymiar metki 26 x 16 mm",
            "7": "Taśma: TOVEL 2",
            "8": "",
            "9": "DOSTĘPNE ZNAKI I SYMBOLE",
            "10": "Cyfry:",
            "11": "Od 0 do 9",
            "12": "Znaki specjalne:",
            "13": "„$” „€” „₤” „zł” „SK” „Kč” „Ft” „kg” „g” „¢” „EA” „RS” „PC” „F” „.” „-„ „/” „½”",
            "14": "",
        },
        url: ImagesLabellers.mx_2616
            //url: "src/components/widgets/sortofneedfromnovitu/ItemPhotos/Labellers/mx_2616.jpeg",
    },
    {
        name: "Metkownica METO EAGLE 2026",
        scales: false,
        printer_register: false,
        cash_register: false,
        online: false,
        new_product: false,
        labeller: true,
        describe:{
            "0": "METO 2026 to metkownica dwuwierszowa, wyposażona w nowoczesny system prowadzenia etykiet za pomocą trzech noży. System ten zapewnia niezawodny przesuw taśmy z etykietami nawet przy intensywnym użytkowaniu. Metkownica może drukować po 10 znaków w rzędzie górnym i dolnym. Rozmiar metki 26 x 16 mm (bez nacięć).",
            "1": "",
            "2": "Metkownica dwuwierszowa",
            "3": "Możliwość druku 10 cyfr w wierszu górnym",
            "4": "Możliwość druku 10 cyfr w wierszu dolnym",
            "5": "Możliwość druku znaków specjalnych",
            "6": "Wymiar metki 26 x 16 mm",
            "7": "Taśma: METO 2",
            "8": "",
            "9": "DOSTĘPNE ZNAKI I SYMBOLE",
            "10": "Cyfry:",
            "11": "Od 0 do 9",
            "12": "Znaki specjalne:",
            "13": "możliwość wydruku np.: wiersz górny: data, nr stoiska, pojemność; wiersz dolny: waga, cena, znaki: DM, $, zł, I, kg",
            "14": "",
        },
        url: ImagesLabellers.meto_eagle_2026
            //url: "src/components/widgets/sortofneedfromnovitu/ItemPhotos/Labellers/meto_eagle_2026.jpg",
    },

];

//systemyPOS
export const arrayPOS = [

    /**
     Tablety i ekrany do obsługi zamówień,
     */
    {
        name: "Tablet STORYOUS 'Ello'",
        scales: false,
        printer_register: false,
        cash_register: false,
        online: false,
        new_product: false,
        labeller: false,
        storyous: true,//change to
        pos: true,//change to 
        describe:{
            "0": "Tablet i jednoczesnie ekran do obsługi zamówień od firmy STORYOUS",
            "1": "",
            "2": "",
           
        },
        url: ImagePOS.POS_ello
            //url: "src/components/widgets/sortofneedfromnovitu/ItemPhotos/STORYOUS/ello.png",
    },
    {
        name: "Kelner mobilny (STORYOUS)",
        scales: false,
        printer_register: false,
        cash_register: false,
        online: false,
        new_product: false,
        labeller: false,
        storyous: true,//to change
        pos: true,//to change
        describe:{
            "0": "Tablet i jednoczesnie ekran do obsługi zamówień- Kelner mobilny od firmy STORYOUS",
            "1": "",
            "2": "",
           
        },
        url: ImagePOS.POS_kelnermobilny
            //url: "src/components/widgets/sortofneedfromnovitu/ItemPhotos/STORYOUS/kelnermobilny.png",
    },
    {
        name: "Kelner kitchenscreen 2x OD STORYOUS, 22cale",
        scales: false,
        printer_register: false,
        cash_register: false,
        online: false,
        new_product: false,
        labeller: false,
        storyous: true, //to change
        pos: true, //to change
        describe:{
            "0": 'Dotykowy wyświetlacz na kuchnię wielkości 22 cali od firmy STORYOUS',
            "1": "",
            "2": "",
           
        },
        url: ImagePOS.POS_kitchen_screen2x_1
            //url: "src/components/widgets/sortofneedfromnovitu/ItemPhotos/STORYOUS/kitchen-screen2x_1.png",
    },
    {
        name: "Kelner lenovo10 (STORYOUS)",
        scales: false,
        printer_register: false,
        cash_register: false,
        online: false,
        new_product: false,
        labeller: false,
        storyous: true, //to change
        pos: true, //to change
        describe:{
            "0": "Tablet i jednoczesnie ekran do obsługi zamówień- Kelner mobilny od firmy STORYOUS",
            "1": "",
            "2": ""
        },
        url: ImagePOS.POS_lenovo10
            //url: "src/components/widgets/sortofneedfromnovitu/ItemPhotos/STORYOUS/lenovo10-e1596785156810.png",
    },
    //Soga
    {
        name: "Zestaw gastronomiczny SOGA TAB",
        scales: false,
        printer_register: false,
        cash_register: false,
        online: false,
        new_product: false,
        labeller: false,
        pos: true,
        describe:{
            "0": "Zestaw gastronomiczny SOGA TAB",
            "1": "",
            "2": "W skład zestawu wchodzą:",
            "3": "tablet 10\"",
            "4": "stojak nablatowy N-TAB",
            "5": "drukarka kuchenna NPOS",
            "6": "program gastronomiczny SOGA XS wersja 1-stanowiskowa",
            "7": "aplikacja SOGAcloud",
            "8": "system operacyjny Microsoft Windows10",
            "9": "",
            "10": "Tablet 10\" z Windows10",
            "11": "",
            "12": "Tablet o przekątnej ekranu 10\" wyposażony Intel® Celeron® Processor N3450 (2M Cache, up to 2.2 GHz)",
            "13": "",
            "14": "",
            "15": "Stojak nablatowy (uchwyt) N-TAB",
            "16": "",
            "17": "Dedykowany uchwyt umożliwiający zamocowanie tabletu oraz drukarki dzięki czemu otrzymujemy niewielkich rozmiarów stanowisko sprzedażowe.",
            "18": "",
            "19": "",
            "20": "Drukarka kuchenna NPOS",
            "21": "Uniwersalna!   Niezawodna!   Stylowa!",
            "22": "",
            "23": "Szybki wydruk do 260 mm/sek w połączeniu z 3 portami komunikacyjnymi w standardzie plasuje drukarkę w gronie najlepszych ofert na rynku. Dostępna również opcja z komunikacją bezprzewodową WiFi (za dopłatą).",
            "24": "",
            "25": "",
            "26": "Program gastronomiczny SOGA XS",
            "27": "",
            "28": "Ekonomiczna wersja programu SOGA przeznaczona dla Klientów, dla których wystarczającą będzie organiczona wersja programu. Szczegółowe porównanie pomiędzy wersjami znajduje się w opisie programu SOGA XS.",
            "29": "",
            "30": "SOGA to idealne rozwiązanie wszędzie tam gdzie szybka i sprawna obsługa klienta jest najwyższym priorytetem. Intuicyjność obsługi w połączeniu z nowoczesnym interfejsem zapewniają najwyższą ergonomię i wygodę pracy. System został zbudowany w taki sposób, aby maksymalnie ułatwić pracę na ekranie dotykowym i dlatego został wyposażony w odpowiednio duże przyciski zapewniające wygodę i przyspieszenie obsługi klienta.",
            "31": "",
            "32": "Przejrzysty i bardzo czytelny układ programu umożliwia niezwykle szybkie opanowanie go przez pracowników - także tych mniej obytych z komputerem. Elastyczna konstrukcja programu zapewnia zarządzającemu systemem dowolność sposobu konfiguracji, odzwierciedlając rzeczywisty układ stolików czy budowę wygodnego menu.",
            "33": "",
            "34": "SOGA jako system modułowy daje możliwość wyboru i swobodnej konfiguracji. Nie musisz już kupować ogromu funkcji nieprzydatnych w Twoim lokalu. Zapłać wyłącznie za to czego rzeczywiście potrzebujesz.",
            "35": "",
            "36": "Aplikacja SOGAcloud",
            "37": "Aplikacja chmurowa umożliwiająca zdalny nadzór właściciela nad funkcjonowaniem lokalu.",

        },
        url: ImagePOS.SOGApos_tablet_zestaw_soga_900px
            //url: "src/components/widgets/sortofneedfromnovitu/ItemPhotos/Soga/npos_tablet_zestaw-soga_900px.jpg",
    },
    {
        name: "Zestaw gastronomiczny SMALL SOGA",
        scales: false,
        printer_register: false,
        cash_register: false,
        online: false,
        new_product: false,
        labeller: false,
        pos: true,
        describe:{
            "0": "Zestaw gastronomiczny SMALL",
            "1": "",
            "2": "W skład zestawu wchodzą:",
            "3": "",
            "4": "urządzenie z ekranem dotykowym POS INTENSA",
            "5": "drukarka kuchenna NPOS",
            "6": "program gastronomiczny SOGA XS wersja 1-stanowiskowa",
            "7": "system operacyjny Microsoft Windows10 IoT",
            "8": "",
            "9": "",
            "10": "POS INTENSA",
            "11": "Zintegrowany system komputerowy z 15\" ekranem dotykowym PCAP. Wydajność, cicha praca w połączeniu z nowatorskim wyglądem urządzenia czynią z niego idealną propozycję zarówno dla sklepów jak i restauracji czy barów. Opcjonalny monitor LCD do wyświetlania reklam dla klientów może stanowić źródło dodatkowego przychodu dla każdego lokalu.",
            "12": "",
            "13": "",
            "14": "Drukarka kuchenna NPOS",
            "15": "",
            "16": "Uniwersalna!   Niezawodna!   Ekonomiczna!",
            "17": "",
            "18": "Szybki wydruk do 300 mm/sek w połączeniu z 3 portami komunikacyjnymi w standardzie plasuje drukarkę w gronie najlepszych ofert na rynku. Możliwość umieszczenia na ścianie. Sygnalizacja wydruku świetlno-dźwiękowa. Dodatkowa pokrywa zabezpieczająca. Teraz to wszystko w niespotykanie niskiej cenie.",
            "19": "",
            "20": "",
            "21": "Program gastronomiczny SOGA XS",
            "22": "",
            "23": "Ekonomiczna wersja programu SOGA przeznaczona dla Klientów, dla których wystarczającą będzie organiczona wersja programu. Szczegółowe porównanie pomiędzy wersjami znajduje się w opisie programu SOGA XS.",
            "24": "",
            "25": "SOGA to idealne rozwiązanie wszędzie tam gdzie szybka i sprawna obsługa klienta jest najwyższym priorytetem. Intuicyjność obsługi w połączeniu z nowoczesnym interfejsem zapewniają najwyższą ergonomię i wygodę pracy. System został zbudowany w taki sposób, aby maksymalnie ułatwić pracę na ekranie dotykowym i dlatego został wyposażony w odpowiednio duże przyciski zapewniające wygodę i przyspieszenie obsługi klienta.",
            "26": "",
            "27": "Przejrzysty i bardzo czytelny układ programu umożliwia niezwykle szybkie opanowanie go przez pracowników - także tych mniej obytych z komputerem. Elastyczna konstrukcja programu zapewnia zarządzającemu systemem dowolność sposobu konfiguracji, odzwierciedlając rzeczywisty układ stolików czy budowę wygodnego menu.",
            "28": "",
            "29": "SOGA jako system modułowy daje możliwość wyboru i swobodnej konfiguracji. Nie musisz już kupować ogromu funkcji nieprzydatnych w Twoim lokalu. Zapłać wyłącznie za to czego rzeczywiście potrzebujesz.",
            "30": "",
            "31": "",
            "32": "System operacyjny Microsoft",
            "33": "",
            "34": "System operacyjny Windows 10IoT został stworzony przez firmę Microsoft z myślą o urządzeniach z ekranem dotykowym typu POS.",
            "35": "",
        },
        url: ImagePOS.SOGApos_zdj_quant2_thumb_SMALL
            //url: "src/components/widgets/sortofneedfromnovitu/ItemPhotos/Soga/zdj_quant2_thumb_SMALL.jpg",
    },
    {
        name: "Zestaw gastronomiczny MEDIUM",
        scales: false,
        printer_register: false,
        cash_register: false,
        online: false,
        new_product: false,
        labeller: false,
        pos: true,
        describe:{
            "0": "Zestaw gastronomiczny MEDIUM SOGA",
            "1": "",
            "2": "",
            "3": "W skład zestawu wchodzą:",
            "4": "",
            "5": "urządzenie z ekranem dotykowym POS QUANT",
            "6": "drukarka kuchenna NPOS",
            "7": "program gastronomiczny SOGA wersja 1-stanowiskowa",
            "8": "system operacyjny Microsoft Windows 10IoT",
            "9": "",
            "10": "",
            "11": "POS QUANT",
            "12": "",
            "13": "Zintegrowany system komputerowy z 15\" ekranem dotykowym PCAP. Wydajność, cicha praca w połączeniu z nowatorskim wyglądem urządzenia czynią z niego idealną propozycję zarówno dla sklepów jak i restauracji czy barów. Opcjonalny monitor LCD do wyświetlania reklam dla klientów może stanowić źródło dodatkowego przychodu dla każdego lokalu.",
            "14": "",
            "15": "",
            "16": "Drukarka kuchenna NPOS",
            "17": "",
            "18": "Uniwersalna!   Niezawodna!   Ekonomiczna!",
            "19": "",
            "20": "Szybki wydruk do 300 mm/sek w połączeniu z 3 portami komunikacyjnymi w standardzie plasuje drukarkę w gronie najlepszych ofert na rynku. Możliwość umieszczenia na ścianie. Sygnalizacja wydruku świetlno-dźwiękowa. Dodatkowa pokrywa zabezpieczająca. Teraz to wszystko w niespotykanie niskiej cenie.",
            "21": "",
            "22": "",
            "23": "",
            "24": "Program gastronomiczny SOGA",
            "25": "",
            "26": "Profesjonalny system obsługi punktów gastronomicznych SOGA stanowi niezwykle przydatne narzędzie wspierające funkcjonowanie każdej restauracji, baru, kawiarni, etc.",
            "27": "",
            "28": "SOGA to idealne rozwiązanie wszędzie tam gdzie szybka i sprawna obsługa klienta jest najwyższym priorytetem. Intuicyjność obsługi w połączeniu z nowoczesnym interfejsem zapewniają najwyższą ergonomię i wygodę pracy. System został zbudowany w taki sposób, aby maksymalnie ułatwić pracę na ekranie dotykowym i dlatego został wyposażony w odpowiednio duże przyciski zapewniające wygodę i przyspieszenie obsługi klienta.",
            "29": "",
            "30": "Przejrzysty i bardzo czytelny układ programu umożliwia niezwykle szybkie opanowanie go przez pracowników - także tych mniej obytych z komputerem. Elastyczna konstrukcja programu zapewnia zarządzającemu systemem dowolność sposobu konfiguracji, odzwierciedlając rzeczywisty układ stolików czy budowę wygodnego menu.",
            "31": "",
            "32": "SOGA jako system modułowy daje możliwość wyboru i swobodnej konfiguracji. Nie musisz już kupować ogromu funkcji nieprzydatnych w Twoim lokalu. Zapłać wyłącznie za to czego rzeczywiście potrzebujesz.",
            "33": "",
            "34": "",
            "35": "",
            "36": "System operacyjny Microsoft",
            "37": "",
            "38": "System operacyjny Windows 10IoT został stworzony przez firmę Microsoft z myślą o urządzeniach z ekranem dotykowym typu POS.",
            "39": "",
            "40": "",
        },
        url: ImagePOS.SOGApos_zdj_quant2_900px_0_MEDIUM
            //url: "src/components/widgets/sortofneedfromnovitu/ItemPhotos/Soga/zdj_quant2_thumb_0_MEDIUM.jpg",
    },
    {
        name: "Zestaw gastronomiczny LARGE SOGA",
        scales: false,
        printer_register: false,
        cash_register: false,
        online: false,
        new_product: false,
        labeller: false,
        pos: true,
        describe:{
            "0": "Zestaw gastronomiczny LARGE",
            "1": "",
            "2": "",
            "3": "W skład zestawu wchodzą:",
            "4": "",
            "5": "urządzenie z ekranem dotykowym POS QUANT",
            "6": "drukarka paragonowa NPOS",
            "7": "program gastronomiczny SOGA wersja 1-stanowiskowa",
            "8": "obsługa zaplecza SOGA moduł magazynowy",
            "9": "system operacyjny Microsoft Windows 10IoT",
            "10": "",
            "11": "",
            "12": "POS QUANT",
            "13": "",
            "14": "Zintegrowany system komputerowy z 15\" ekranem dotykowym PCAP. Wydajność, cicha praca w połączeniu z nowatorskim wyglądem urządzenia czynią z niego idealną propozycję zarówno dla sklepów jak i restauracji czy barów. Opcjonalny monitor LCD do wyświetlania reklam dla klientów może stanowić źródło dodatkowego przychodu dla każdego lokalu.",
            "15": "",
            "16": "",
            "17": "",
            "18": "Program gastronomiczny SOGA",
            "19": "",
            "20": "Profesjonalny system obsługi punktów gastronomicznych SOGA stanowi niezwykle przydatne narzędzie wspierające funkcjonowanie każdej restauracji, baru, kawiarni, etc.",
            "21": "",
            "22": "SOGA to idealne rozwiązanie wszędzie tam gdzie szybka i sprawna obsługa klienta jest najwyższym priorytetem. Intuicyjność obsługi w połączeniu z nowoczesnym interfejsem zapewniają najwyższą ergonomię i wygodę pracy. System został zbudowany w taki sposób, aby maksymalnie ułatwić pracę na ekranie dotykowym i dlatego został wyposażony w odpowiednio duże przyciski zapewniające wygodę i przyspieszenie obsługi klienta.",
            "23": "",
            "24": "Przejrzysty i bardzo czytelny układ programu umożliwia niezwykle szybkie opanowanie go przez pracowników - także tych mniej obytych z komputerem. Elastyczna konstrukcja programu zapewnia zarządzającemu systemem dowolność sposobu konfiguracji, odzwierciedlając rzeczywisty układ stolików czy budowę wygodnego menu.",
            "25": "",
            "26": "SOGA jako system modułowy daje możliwość wyboru i swobodnej konfiguracji. Nie musisz już kupować ogromu funkcji nieprzydatnych w Twoim lokalu. Zapłać wyłącznie za to czego rzeczywiście potrzebujesz.",
            "27": "",
            "28": "",
            "29": "Moduł magazynowy SOGA",
            "30": "",
            "31": "Najważniejszy z szerokiej gamy modułów dodatkowych uzupełniających działanie całego systemu. Rozszerza wersję podstawową o szereg funkcjonalności związanych z obsługą zaplecza restauracyjnego. Dzięki niemu otrzymujemy możliwość szczegółowego rozliczania stanów magazynowych oraz wartości magazynu. Ponadto w łatwy i intuicyjny sposób przygotujemy receptury dla potraw, obsłużymy produkcję czy skalkulujemy imprezę. Co ważne – szczególnie dla większych lokali – bez względu na ilość zamawianych stanowisk magazynowych opłata jest tylko za jedno!",
            "32": "",
            "33": "",
            "34": "System operacyjny Microsoft",
            "35": "",
            "36": "System operacyjny Windows 10IoT został stworzony przez firmę Microsoft z myślą o urządzeniach z ekranem dotykowym typu POS.",
            "37": "",
            "38": "",
            "39": "Drukarka paragonowa NPOS",
            "40": "",
            "41": "Szybka, niezawodna, wytrzymała, uniwersalna - tp w największym skrócie drukarka paragonowa NPOS.",
            "42": "",
            "43": "Wydruk z prędkoscią do 300mm/sek., trzy porty komunikacyjne w standardzie (USB, RS, Ethernet), zintegrowany obcinacz papieru ... to największe atuty naszego produktu.",
            "44": "",
            "45": "",
        },
        url: ImagePOS.SOGApos_zdj_quant_thumb_LARGE
            //url: "src/components/widgets/sortofneedfromnovitu/ItemPhotos/Soga/zdj_quant_900px_0_LARGE.jpg",
    },
    {
        name: "Zestaw gastronomiczny MEGA SOGA",
        scales: false,
        printer_register: false,
        cash_register: false,
        online: false,
        new_product: false,
        labeller: false,
        pos: true,
        describe:{
            "0": "Zestaw gastronomiczny MEGA",
            "1": "",
            "2": "W skład zestawu wchodzą:",
            "3": "",
            "4": "urządzenie z ekranem dotykowym POS VELOX",
            "5": "drukarka paragonowa N-POS",
            "6": "program gastronomiczny SOGA zawierający wszystkie moduły dodatkowe",
            "7": "system operacyjny Microsoft Windows 10IoT",
            "8": "",
            "9": "POS VELOX",
            "10": "",
            "11": "Najnowszy model POS w ofercie NOVITUS. Procesor Intel i-3 zapewnia znakomitą wydajność i komfort pracy. Wyposażony w podstawę 2-przegubową pozwala na dowolne dostosowanie ekranu urządzenia do warunków fizycznych obsługi.",
            "12": "",
            "13": "",
            "14": "Program gastronomiczny SOGA",
            "15": "",
            "16": "Profesjonalny system obsługi punktów gastronomicznych SOGA stanowi niezwykle przydatne narzędzie wspierające funkcjonowanie każdej restauracji, baru, kawiarni, etc.",
            "17": "",
            "18": "SOGA to idealne rozwiązanie wszędzie tam gdzie szybka i sprawna obsługa klienta jest najwyższym priorytetem. Intuicyjność obsługi w połączeniu z nowoczesnym interfejsem zapewniają najwyższą ergonomię i wygodę pracy. System został zbudowany w taki sposób, aby maksymalnie ułatwić pracę na ekranie dotykowym i dlatego został wyposażony w odpowiednio duże przyciski zapewniające wygodę i przyspieszenie obsługi klienta.",
            "19": "",
            "20": "Przejrzysty i bardzo czytelny układ programu umożliwia niezwykle szybkie opanowanie go przez pracowników - także tych mniej obytych z komputerem. Elastyczna konstrukcja programu zapewnia zarządzającemu systemem dowolność sposobu konfiguracji, odzwierciedlając rzeczywisty układ stolików czy budowę wygodnego menu.",
            "21": "",
            "22": "SOGA jako system modułowy daje możliwość wyboru i swobodnej konfiguracji. Nie musisz już kupować ogromu funkcji nieprzydatnych w Twoim lokalu. Zapłać wyłącznie za to czego rzeczywiście potrzebujesz.",
            "23": "",
            "24": "",
            "25": "Moduły programu SOGA",
            "26": "",
            "27": "Decydując się na wybór naszego zestawu gastronomicznego MEGA nie muszą się Państwo zastanawiać, który z modułów będzie potrzebny. W cenie otrzymają Państwo dowolnie wybrany zestaw modułów dodatkowych.",
            "28": "",
            "29": "Moduły dodatkowe programu SOGA to:",
            "30": "",
            "31": "moduł magazynowy",
            "32": "obsługa bonownika z systemem Android",
            "33": "moduł raportów własnych",
            "34": "system lojalnościowy",
            "35": "eksport danych do programu księgowego",
            "36": "obsługa drugiej drukarki fiskalnej",
            "37": "system VIU (Visual Information Utility umożliwiający obsługę ekranów kuchennych KDS i ekranów Klienta RDS)",
            "38": "moduł RCP (rejestracja czasu pracy)",
            "39": "",
            "40": "",
            "41": "System operacyjny Microsoft",
            "42": "",
            "43": "System operacyjny Windows10IoT został stworzony przez firmę Microsoft z myślą o urządzeniach z ekranem dotykowym typu POS.",
            "44": "",
            "45": "",
            "46": "Drukarka paragonowa N-POS",
            "47": "",
            "48": "Uniwersalna i ekonomiczna w użytkowaniu drukarka paragonowa o prędkości wydruku do 300mm/sekundę. Doskonałą elastyczność połączeń zapewniają trzy porty komunikacyjne (USB, RS-232, Ethernet) już w standardzie.",
            "49": "",

        },
        url: ImagePOS.SOGApos__zdj_quant_thumb_MEGA
            //url: "src/components/widgets/sortofneedfromnovitu/ItemPhotos/Soga/_zdj_quant_thumb_MEGA.jpg",
    }
];

//akcesorieDlaGastronomii
export const arrayGastroAccesories = [
    {
        name: "Przywoływacz (with label- z etykietą)",
        scales: false,
        printer_register: false,
        cash_register: false,
        online: false,
        new_product: false,
        labeller: false,
        other: true,
        describe:{
            "0": "Przywoływacz wydawany klientowi po przyjęciu zamówienia sygnalizuje za pomocą wibracji i migotania gotowość zamówienia do odbioru. Istnieje możliwość personalizacji za pomocą naklejek umieszczanych na urządzeniu.",
            "1": "",
            "2": "",

        },
        url: ImagePagers.PAGER_sticker_client
            //url: "src/components/widgets/sortofneedfromnovitu/ItemPhotos/accesories_gastro/przywolywacz_(number_sticker).jpg",
    },
    {
        name: "Przywoływacz biały",
        scales: false,
        printer_register: false,
        cash_register: false,
        online: false,
        new_product: false,
        labeller: false,
        other: true,
        describe:{
            "0": "Przywoływacz obsługi w formie przycisku instalowanego na stoliku. Wyposażony w dwa przyciski „call” – przywołaj i „cancell” – odwołaj. Konstrukcja umożliwia mocowanie przycisku do wyposażenia za pomocą wkrętów.",
            "1": "",
            "2": "Wymiary: 86mm (S) x 86mm (G) 17.5mm (W). Średnica przycisku „call” 50mm",
            "3": "Zasięg w pomieszczeniu: 40-60m",
            "4": "Zasięg na zewnątrz: 500-800m",
            "5": "Wartości orientacyjne uzależnione od struktury obiektu, materiałów budowlanych i urządzeń emitujących zakłócenia znajdujących się w pobliżu.",
            "6":"",
            "7": "Częstotliwość: 433.92MHz",
            "8": "Odchylenie częstotliwości: 0.5 MHz",
            "9": "Zasilanie: bateria 12V/23A – 2szt",
            "10": "",

        },
        url: ImagePagers.PAGER_white_client
            //url: "./sortofneedfromnovitu/ItemPhotos/accesories_gastro/przywolywacz_bialy_przycisk_klienta.jpg",
    },
    {
        name: "Baza przywolywacz",
        scales: false,
        printer_register: false,
        cash_register: false,
        online: false,
        new_product: false,
        labeller: false,
        other: true,
        describe:{ 
                   "0": "Przywoływacz kelnerski - baza", 
                   "1": "", 
                },
        url: ImagePagers.PAGER_base
            //url: "src/components/widgets/sortofneedfromnovitu/ItemPhotos/accesories_gastro/sc15-3_baza.jpg",
    },
    {
        name: "Przywoływacz, watch-zegarek",
        scales: false,
        printer_register: false,
        cash_register: false,
        online: false,
        new_product: false,
        labeller: false,
        other: true,
        describe:{
            "0": "Zdenerwowany klient oczekujący na podejście kelnera? Ktoś w przymierzali potrzebujący inny zmierzyć rozmiar? Klient szukający pomocy w dużym markecie?",
            "1": "We wszystkich tych sytuacjach pager dla obsługi w formie stylowego zegarka będzie rozwiązaniem.",
            "2": "Wyświetlacz: OLED",
            "3": "Wyświetlanie: do 10 numerów przywołujących",
            "4": "Informacja wyświetlana w formacie: litery A-Z oraz cyfry 0-9",
            "5": "Pamięć numerów przywołujących: 500",
            "6": "Wibracje: 1-15 sekund",
            "7": "Funkcje dodatkowe: zegar, kalendarz, alarm",
            "8": "Częstotliwość: 433.92MHz",
            "9": "Czułość odbiornika: -114dbm",
            "10": "Zasilanie: mini akumulator 3.7V / pojemność: 150mAh",
            "11": "Żywotność po 1-krotnym naładowaniu: 3 dni (funkcja standby)",
            "12": "",
        },
        url: ImagePagers.PAGER_watch_client
            //url: "src/components/widgets/sortofneedfromnovitu/ItemPhotos/accesories_gastro/sc15-3_baza.jpg",
    },
    {
        name: "Charger- ładowarka do przywoływaczy",
        scales: false,
        printer_register: false,
        cash_register: false,
        online: false,
        new_product: false,
        labeller: false,
        other: true,
        describe:{ 
                   "0": "Uzupełnia poziom baterii przywoływacza. Jedna ładowarka pozwala na obsługę do 10 przywoływaczy.",
                   "1": "", 
                },
        url: ImagePagers.PAGER_charger
            //url: "src/components/widgets/sortofneedfromnovitu/ItemPhotos/accesories_gastro/ladowarka1.jpg",
    },
    {
        name: "Przywoływacz klienta- programator(transmiter)",
        scales: false,
        printer_register: false,
        cash_register: false,
        online: false,
        new_product: false,
        labeller: false,
        other: true,
        describe:{ 
                   "0": "Przywoływacz klienta- programator(transmiter)", 
                   "1": "Umożliwia zakodowanie numeru zamówienia na przywoływaczu oraz wysłanie powiadomienia o gotowości zamówienia do odbioru.", 
                   "2": "", 
                },
        url: ImagePagers.PAGER_transmiter
            //url: "src/components/widgets/sortofneedfromnovitu/ItemPhotos/accesories_gastro/programator(transmiter).jpg",
    },
];

//metkownice
export const arrayMetkownice = [
    {
        name: "Metkownica METO EAGLE 2026",
        scales: false,
        printer_register: false,
        cash_register: false,
        online: false,
        new_product: false,
        labeller: true,
        describe:{
            "0": "METO 2026 to metkownica dwuwierszowa, wyposażona w nowoczesny system prowadzenia etykiet za pomocą trzech noży. System ten zapewnia niezawodny przesuw taśmy z etykietami nawet przy intensywnym użytkowaniu. Metkownica może drukować po 10 znaków w rzędzie górnym i dolnym. Rozmiar metki 26 x 16 mm (bez nacięć).",
            "1": "",
            "2": "Metkownica dwuwierszowa",
            "3": "Możliwość druku 10 cyfr w wierszu górnym",
            "4": "Możliwość druku 10 cyfr w wierszu dolnym",
            "5": "Możliwość druku znaków specjalnych",
            "6": "Wymiar metki 26 x 16 mm",
            "7": "Taśma: METO 2",
            "8": "",
            "9": "DOSTĘPNE ZNAKI I SYMBOLE",
            "10": "Cyfry:",
            "11": "Od 0 do 9",
            "12": "Znaki specjalne:",
            "13": "możliwość wydruku np.: wiersz górny: data, nr stoiska, pojemność; wiersz dolny: waga, cena, znaki: DM, $, zł, I, kg",
            "14": "",
        },
        url: ImagesLabellers.meto_eagle_2026
            //url: "src/components/widgets/sortofneedfromnovitu/ItemPhotos/Labellers/meto_eagle_2026.jpg",
    },
    {
        name: "Metkownica METO EAGLE 2026",
        scales: false,
        printer_register: false,
        cash_register: false,
        online: false,
        new_product: false,
        labeller: true,
        describe:{
            "0": "METO 2026 to metkownica dwuwierszowa, wyposażona w nowoczesny system prowadzenia etykiet za pomocą trzech noży. System ten zapewnia niezawodny przesuw taśmy z etykietami nawet przy intensywnym użytkowaniu. Metkownica może drukować po 10 znaków w rzędzie górnym i dolnym. Rozmiar metki 26 x 16 mm (bez nacięć).",
            "1": "",
            "2": "Metkownica dwuwierszowa",
            "3": "Możliwość druku 10 cyfr w wierszu górnym",
            "4": "Możliwość druku 10 cyfr w wierszu dolnym",
            "5": "Możliwość druku znaków specjalnych",
            "6": "Wymiar metki 26 x 16 mm",
            "7": "Taśma: METO 2",
            "8": "",
            "9": "DOSTĘPNE ZNAKI I SYMBOLE",
            "10": "Cyfry:",
            "11": "Od 0 do 9",
            "12": "Znaki specjalne:",
            "13": "możliwość wydruku np.: wiersz górny: data, nr stoiska, pojemność; wiersz dolny: waga, cena, znaki: DM, $, zł, I, kg",
            "14": "",
        },
        url: ImagesLabellers.meto_eagle_2026
            //url: "src/components/widgets/sortofneedfromnovitu/ItemPhotos/Labellers/meto_eagle_2026.jpg",
    },
    {
        name: "Metkownica METO EAGLE 2026",
        scales: false,
        printer_register: false,
        cash_register: false,
        online: false,
        new_product: false,
        labeller: true,
        describe:{
            "0": "METO 2026 to metkownica dwuwierszowa, wyposażona w nowoczesny system prowadzenia etykiet za pomocą trzech noży. System ten zapewnia niezawodny przesuw taśmy z etykietami nawet przy intensywnym użytkowaniu. Metkownica może drukować po 10 znaków w rzędzie górnym i dolnym. Rozmiar metki 26 x 16 mm (bez nacięć).",
            "1": "",
            "2": "Metkownica dwuwierszowa",
            "3": "Możliwość druku 10 cyfr w wierszu górnym",
            "4": "Możliwość druku 10 cyfr w wierszu dolnym",
            "5": "Możliwość druku znaków specjalnych",
            "6": "Wymiar metki 26 x 16 mm",
            "7": "Taśma: METO 2",
            "8": "",
            "9": "DOSTĘPNE ZNAKI I SYMBOLE",
            "10": "Cyfry:",
            "11": "Od 0 do 9",
            "12": "Znaki specjalne:",
            "13": "możliwość wydruku np.: wiersz górny: data, nr stoiska, pojemność; wiersz dolny: waga, cena, znaki: DM, $, zł, I, kg",
            "14": "",
        },
        url: ImagesLabellers.meto_eagle_2026
            //url: "src/components/widgets/sortofneedfromnovitu/ItemPhotos/Labellers/meto_eagle_2026.jpg",
    },      
];

//czytniki
export const arrayCzytniki1 = [
    {
        name: "Czytnik kodów Cipherlab 1560P",
        scales: false,
        printer_register: false,
        cash_register: false,
        online: false,
        new_product: false,
        labeller: false,
        scanner: true,
        describe:{
                "0": "Cipherlab 1560P",
                "1": "Najważniejsze cechy czytnika",
                "2": "",
                "3": "Komunikacja bezprzewodowa Bluetooth® do 90m",
                "4": "Odporność na upadek z 1,2 m",
                "5": "Norma szczelności IP30,",
                "6": "Do 36 godzin pracy bez ładownia",
                "7": "Możliwość pracy automatycznej na podstawce",
                "8": "Przycisk wyzwalający odczyt",
                "9": "CipherLab 1560P to bezprzewodowy czytnik z komunikacją Bluetooth® o zasięgu do 90 m. Jeśli czytnik znajdzie się poza zasięgiem bazy bufor skanera może pomieścić do 256 kodów, które automatycznie zostaną przetransmitowane po ponownym znalezieniu się czytnika w zasięgu bazy. Moduł skanujący Linear Imager umożliwia szybki i bezbłędny odczyt kodów kreskowych o znacznej szerokości. Wydajny akumulator zapewnia do 24 godzin pracy bez konieczności ładowania. Oprogramowanie ScanMaster umożliwia łatwe i intuicyjne programowanie i konfigurację skanera z poziomu komputera PC.",
                "10": "DANE TECHNICZNE",
                "11": "Nazwa:",
                "12": "CipherLab 1560P",
                "13": "Rodzaj czytnika:",
                "14": "Linear Imager, bezprzewodowy",
                "15": "Źródło światła:",
                "16": "Linear Imager",
                "17": "Odległość odczytu:",
                "18": "Do 40 cm (w zależności od rodzaju i gęstości kodu kreskowego)",
                "19": "Rozdzielczość:",
                "20": "3 mils",
                "21": "Szybkość odczytu:",
                "22": "Do 520 skanów/s",
                "23": "Warunki pracy:",
                "24": "Temp. 0 – 50 C, wilgotność 10 – 90 %",
                "25": "Wymiary:",
                "26": "153 x 61 x 93 mm",
                "27": "Waga:",
                "28": "173 g (czytnik)",
                "29": "Dostępne interfejsy:",
                "30": "RS232, KBW, USB",
                "31": "Inne:",
                "32": "Łączność radiowa Bluetooth®, przycisk wyzwalający odczyt,",
                "33": "Zastosowanie:",
                "34": "Handel, biuro, urzędy, biblioteki, stacje benzynowe, magazyn",
                "35": "Zasięg:",
                "36": "do 90m w terenie otwartym",
                "37": "Odczytywane kody:",
                "38": "1D",
                "39": "Zasięg:",
                "40": ">50m"
        },
        url: ImagesScaners.cipherlab_15601_Cipherlab_1560P
            //url: "/src/components/widgets/sortofneedfromnovitu/ItemPhotos/product_scanners/cipherlab_15601_Cipherlab_1560P.jpg",
    },
    {
        name: "Czytnik kodów Cipherlab 1564A",
        scales: false,
        printer_register: false,
        cash_register: false,
        online: false,
        new_product: false,
        labeller: false,
        scanner:true,
        describe:{
            "0": "Cipherlab 1564A",
            "1": "Najważniejsze cechy czytnika",
            "2": "",
            "3": "Odczyt kodów 2D",
            "4": "Komunikacja bezprzewodowa Bluetooth® do 90m",
            "5": "Odporność na upadek z 1,2 m",
            "6": "Norma szczelności IP30,",
            "7": "Do 36 godzin pracy bez ładownia",
            "8": "Możliwość pracy automatycznej na podstawce",
            "9": "Przycisk wyzwalający odczyt",
            "10": "CipherLab 1564A to bezprzewodowy czytnik kodów 2Dz komunikacją Bluetooth® o zasięgu do 90 m. Jeśli czytnik znajdzie się poza zasięgiem bazy bufor skanera może pomieścić do 256 kodów, które automatycznie zostaną przetransmitowane po ponownym znalezieniu się czytnika w zasięgu bazy. Moduł skanujący Linear Imager umożliwia szybki i bezbłędny odczyt kodów kreskowych o znacznej szerokości. Wydajny akumulator zapewnia do 24 godzin pracy bez konieczności ładowania. Oprogramowanie ScanMaster umożliwia łatwe i intuicyjne programowanie i konfigurację skanera z poziomu komputera PC.",
            "11": "DANE TECHNICZNE",
            "12": "Nazwa:",
            "13": "CipherLab 1564A",
            "14": "Rodzaj czytnika:",
            "15": "Area Imager, bezprzewodowy",
            "16": "Źródło światła:",
            "17": "Linear Imager",
            "18": "Odległość odczytu:",
            "19": "Do 30 cm (w zależności od rodzaju i gęstości kodu kreskowego)",
            "20": "Rozdzielczość:",
            "21": "3 mils",
            "22": "Szybkość odczytu:",
            "23": "nie dotyczy",
            "24": "Warunki pracy:",
            "25": "Temp. 0 – 50 C, wilgotność 10 – 90 %",
            "26": "Wymiary:",
            "27": "153 x 61 x 93 mm",
            "28": "Waga:",
            "29": "185 g (czytnik)",
            "30": "Dostępne interfejsy:",
            "31": "RS232, KBW, USB",
            "32": "Inne:",
            "33": "Łączność radiowa Bluetooth®, przycisk wyzwalający odczyt,",
            "34": "Zastosowanie:",
            "35": "Handel, biuro, urzędy, biblioteki, stacje benzynowe, magazyn",
            "36": "Zasięg:",
            "37": "do 90m w terenie otwartym",
            "38": "Odczytywane kody:",
            "39": "2D",
            "40": "Zasięg:",
            "41": ">50m"
        },
        url: ImagesScaners.Cipherlab_1564A
            //url: "src/components/widgets/sortofneedfromnovitu/ItemPhotos/product_scanners/Cipherlab_1564A.jpg",
    },
    {
        name: "Czytnik kodów Cipherlab 1660",
        scales: false,
        printer_register: false,
        cash_register: false,
        online: false,
        new_product: false,
        labeller: false,
        scanner: true,
        describe:{
            "0": "Cipherlab 1660",
            "1": "Najważniejsze cechy czytnika",
            "2": "",
            "3": "Niewielkie rozmiary i waga",
            "4": "Komunikacja Bluetooth zasięg do 10 m",
            "5": "ScanMaster – oprogramowanie do konfiguracji skanera",
            "6": "Cipherlab 1660 to wszechstronny, bezprzewodowy czytnik o rozmiarach mniejszych od telefonu komórkowego. Umożliwia on odczyt tysięcy kodów na jednej parze baterii AAA (30 godzin pracy). Kiedy czytnik znajdzie się poza zasięgiem Bluetooth wewnętrzna pamięć 256K pozwala na zapamiętanie kilkuset kodów i transmisje gdy czytnik ponownie znajdzie się w zasięgu Bluetooth. Cipherlab 1660 może komunikować się z dowolnym urządzeniem wyposażonym w Bluetooth lub z dedykowanym transponderem Cipherlab 3610 z interfejsem USB.",
            "7": "DANE TECHNICZNE",
            "8": "Nazwa:",
            "9": "Cipherlab 1660",
            "10": "Rodzaj czytnika:",
            "11": "Ręczny, bezprzewodowy, Linear Imager",
            "12": "Źródło światła:",
            "13": "Red LED 625 nm",
            "14": "Odległość odczytu:",
            "15": "3,5 - 38 cm (w zależności od gęstości kodu kreskowego)",
            "16": "Rozdzielczość:",
            "17": "3 mil",
            "18": "Szybkość odczytu:",
            "19": "100 skanów na sekundę",
            "20": "Warunki pracy:",
            "21": "Temp. 0 – 50 C, wilgotność względna 10 – 90 %",
            "22": "Wymiary:",
            "23": "95 x 35 x 20 mm",
            "24": "Waga:",
            "25": "50 g",
            "26": "Dostępne interfejsy:",
            "27": "Przycisk wyzwalający odczyt, ScanMaster – oprogramowanie do konfiguracji skanera",
            "28": "Inne:",
            "29": "Przycisk wyzwalający odczyt, ScanMaster – oprogramowanie do konfiguracji skanera",
            "30": "Zastosowanie:",
            "31": "Handel",
            "32": "Zasięg:",
            "33": "10 m"
        },
        url: ImagesScaners.cipherlab_1660
            //url: "src/components/widgets/sortofneedfromnovitu/ItemPhotos/product_scanners/cipherlab_1660.jpg",
    },
    {
        name: "Czytnik kodów Cipherlab 1664",
        scales: false,
        printer_register: false,
        cash_register: false,
        online: false,
        new_product: false,
        labeller: false,
        scanner: true,
        describe:{
            "0": "Cipherlab 1664",
            "1": "Najważniejsze cechy czytnika",
            "2": "",
            "3": "Odczyt kodów 2D",
            "4": "Niewielkie rozmiary i waga",
            "5": "Komunikacja Bluetooth zasięg do 10 m",
            "6": "ScanMaster – oprogramowanie do konfiguracji skanera",
            "7": "Cipherlab 1664 to wszechstronny, bezprzewodowy czytnik o rozmiarach mniejszych od telefonu komórkowego. Umożliwia on odczyt tysięcy kodów na jednej parze baterii AAA (24 godziny pracy). Kiedy czytnik znajdzie się poza zasięgiem Bluetooth wewnętrzna pamięć 256K pozwala na zapamiętanie kilkuset kodów i transmisje gdy czytnik ponownie znajdzie się w zasięgu Bluetooth. Cipherlab 1664 może komunikować się z dowolnym urządzeniem wyposażonym w Bluetooth lub z dedykowanym transponderem Cipherlab 3610 z interfejsem USB.",
            "8": "DANE TECHNICZNE",
            "9": "Nazwa:",
            "10": "Cipherlab 1664",
            "11": "Rodzaj czytnika:",
            "12": "Ręczny, bezprzewodowy, Area Imager",
            "13": "Źródło światła:",
            "14": "Red LED 625 nm",
            "15": "Odległość odczytu:",
            "16": "3,5 - 30 cm (w zależności od gęstości kodu kreskowego)",
            "17": "Rozdzielczość:",
            "18": "3 mil",
            "19": "Szybkość odczytu:",
            "20": "nie dotyczy",
            "21": "Warunki pracy:",
            "22": "Temp. 0 – 50 C, wilgotność względna 10 – 90 %",
            "23": "Wymiary:",
            "24": "115 x 45 x 30 mm",
            "25": "Waga:",
            "26": "106 g",
            "27": "Dostępne interfejsy:",
            "28": "Przycisk wyzwalający odczyt, ScanMaster – oprogramowanie do konfiguracji skanera",
            "29": "Inne:",
            "30": "Przycisk wyzwalający odczyt, ScanMaster – oprogramowanie do konfiguracji skanera",
            "31": "Zastosowanie:",
            "32": "Handel",
            "33": "Zasięg:",
            "34": "20 m",
            "35": "Odczytywane kody:",
            "36": "2D",
            "37": "Zasięg:",
            "38": "11m – 30m"
        },
        url: ImagesScaners.Cipherlab_1664
            //url: "src/components/widgets/sortofneedfromnovitu/ItemPhotos/product_scanners/111226pl_1664_72dpi_small_Cipherlab_1664.jpg",
    },////
    {
        name: "Czytnik kodów Datalogic GRYPHON GM4100",
        scales: false,
        printer_register: false,
        cash_register: false,
        online: false,
        new_product: false,
        labeller: false,
        scanner: true,
        describe:{
            "0": "Datalogic GRYPHON GM4100",
            "1": "",
            "2": "Najważniejsze cechy czytnika",
            "3": "Łączność bezprzewodowa: radio 433 MHz o zasięgu do 30 m",
            "4": "Baza umożliwiająca pracę czytnika w trybie automatycznym",
            "5": "Bezbłędny i szybki odczyt kodów kreskowych z odległości do 80 cm",
            "6": "Przycisk wyzwalający odczyt",
            "7": "Potwierdzenie odczytu bezpośrednio na odczytywanym kodzie system „green spot”",
            "8": "Odporny na upadek z 1,8 m, norma szczelności IP52",
            "9": "Czytnik Gryphon GM4100 zapewnia bezbłędny i szybki odczyt kodów w różnorodnych zastosowaniach. Łączność bezprzewodowa 433 MHz zapewniał zasięg do 30 m w terenie otwartym. Szeroki kąt odczytu pozawala na odczyt szerokich kodów a ulepszone możliwości dekodowania zwiększają odczyt zniszczonych i niskiej jakości kodów. Wszystkie te cechy w połączeniu z odczytem gęstych kodów (3 mils) czynią Gryphona GM4100 wszechstronnym rozwiązaniem do odczytu kodów kreskowych. System Green Spot zapewnia potwierdzenie odczytu bezpośrednio na odczytywanym kodzie, co jest szczególnie przydatne w hałaśliwych środowiskach pracy. Unikatową cechą bazy komunikacyjnej jest możliwość wykorzystania jej jako podstawki do pracy automatycznej przy jednoczesnym ładowaniu akumulatora czytnika. Gryphon GM4100 jest doskonałym rozwiązaniem do zastosowań w handlu, zastosowaniach biurowych, służbie zdrowia i farmacji oraz na magazynach i w lekkich zastosowaniach przemysłowych.",
            "10": "DANE TECHNICZNE",
            "11": "Nazwa:",
            "12": "GRYPHON GM4100",
            "13": "Rodzaj czytnika:",
            "14": "Bezprzewodowy. Linear Imager",
            "15": "Źródło światła:",
            "16": "LED 630-670 nm",
            "17": "Odległość odczytu:",
            "18": "2 – 80 cm (w zależności od gęstości kodu kreskowego)",
            "19": "Rozdzielczość:",
            "20": "3 mils",
            "21": "Szybkość odczytu:",
            "22": "325 skanów/s",
            "23": "Warunki pracy:",
            "24": "Temp. 0 – 50°C, wilgotność względna 5-95 %",
            "25": "Wymiary:",
            "26": "181 x 71 x 100 mm (czytnik)",
            "27": "Waga:",
            "28": "246 g (czytnik)",
            "29": "Dostępne interfejsy:",
            "30": "RS232, KBW, USB, IBM46xx",
            "31": "Inne:",
            "32": "Wbudowane 3 interfejsy, przycisk wyzwalający odczyt, odłączalny kabel transmisyjny",
            "33": "Zastosowanie:",
            "34": "Handel, biuro, urzędy, biblioteki, służba zdrowia, magazyn, produkcja",
            "35": "Zasięg:",
            "36": "do 30 m"
        },
        url: ImagesScaners.Datalogic_GRYPHON_GM4100
            //url: "./src/components/widgets/sortofneedfromnovitu/ItemPhotos/product_scanners/gryphon-gm4100_Datalogic_GRYPHON_GM4100.jpg",
    },
    {
        name: "Czytnik kodów Datalogic GRYPHON GM4400 2D",
        scales: false,
        printer_register: false,
        cash_register: false,
        online: false,
        new_product: false,
        labeller: false,
        scanner: true,
        describe:{
            "0": "Datalogic GRYPHON GM4400 2D",
            "1": "Najważniejsze cechy czytnika",
            "2": "Łączność bezprzewodowa: radio 433 MHz o zasięgu do 30 m",
            "3": "Baza umożliwiająca pracę czytnika w trybie automatycznym",
            "4": "Bezbłędny i szybki odczyt kodów kreskowych z odległości do 40 cm",
            "5": "Przycisk wyzwalający odczyt",
            "6": "Potwierdzenie odczytu bezpośrednio na odczytywanym kodzie system „green spot”",
            "7": "Odporny na upadek z 1,8 m, norma szczelności IP52",
            "8": "Czytnik Gryphon GM4400 zapewnia bezbłędny i szybki odczyt kodów w różnorodnych zastosowaniach. Łączność bezprzewodowa 433 MHz zapewniał zasięg do 30 m w terenie otwartym. Szeroki kąt odczytu pozawala na odczyt szerokich kodów a ulepszone możliwości dekodowania zwiększają odczyt zniszczonych i niskiej jakości kodów.  System Green Spot zapewnia potwierdzenie odczytu bezpośrednio na odczytywanym kodzie, co jest szczególnie przydatne w hałaśliwych środowiskach pracy. Unikatową cechą bazy komunikacyjnej jest możliwość wykorzystania jej jako podstawki do pracy automatycznej przy jednoczesnym ładowaniu akumulatora czytnika. Gryphon GM4400 jest doskonałym rozwiązaniem do zastosowań w handlu, zastosowaniach biurowych, służbie zdrowia i farmacji oraz na magazynach i w lekkich zastosowaniach przemysłowych.",
            "9": "DANE TECHNICZNE",
            "10": "Nazwa:",
            "11": "GRYPHON GM4400",
            "12": "Rodzaj czytnika:",
            "13": "Bezprzewodowy, Imager 2D",
            "14": "Źródło światła:",
            "15": "VGA: 752 x 480 pixels",
            "16": "Odległość odczytu:",
            "17": "1 – 40 cm (w zależności od gęstości kodu kreskowego)",
            "18": "Rozdzielczość:",
            "19": "4 mils",
            "20": "Warunki pracy:",
            "21": "Temp. 0 – 50°C, wilgotność względna 0-90 %",
            "22": "Wymiary:",
            "23": "181 x 71 x 100 mm (czytnik)",
            "24": "Waga:",
            "25": "246 g (czytnik)",
            "26": "Dostępne interfejsy:",
            "27": "RS232, KBW, USB, IBM46xx",
            "28": "Inne:",
            "29": "Wbudowane 3 interfejsy, przycisk wyzwalający odczyt, odłączalny kabel transmisyjny",
            "30": "Zastosowanie:",
            "31": "Handel, biuro, urzędy, biblioteki, służba zdrowia, magazyn, produkcja",
            "32": "Zasięg:",
            "33": "do 30 m",
            "34": "Odczytywane kody:",
            "35": "1D",
            "36": "2D",
            "37": "Zasięg:",
            "38": "11m – 30m"
        },
        url: ImagesScaners.Datalogic_GRYPHON_GM4400_2D
            //url: "./src/components/widgets/sortofneedfromnovitu/ItemPhotos/product_scanners/gm_4400_2d-blf-cradleup_Datalogic_GRYPHON_GM4400_2D.jpg",
    },
    {
        name: "Czytnik kodów Datalogic GRYPHON GM4500 2D",
        scales: false,
        printer_register: false,
        cash_register: false,
        online: false,
        new_product: false,
        labeller: false,
        scanner: true,
        describe:{
            "0": "Datalogic GRYPHON GM4500 2D",
            "1": "",
            "2": "Najważniejsze cechy:",
            "3": "",
            "4": "- interfejs: USB, KBW (PS/2) lub RS232,",
            "5": "",
            "6": "- skaner: 2D, imager,",
            "7": "",
            "8": "- odczyt kodów: 1D, 2D,",
            "9": "",
            "10": "- odporność na upadek z wysokości do 1,8 m",
            "11": "Jedną z najważniejszych innowacji technicznych, które osiągają bezprzewodowe czytniki Gryphon 4500, jest bezprzewodowy system ładowania baterii, który zwiększa niezawodność całego rozwiązania, jednocześnie obniżając całkowity koszt posiadania (nie ma potrzeby czyszczenia styków ani procedur konserwacyjnych). Maksymalna niezawodność oferowana przez technologię ładowania bezprzewodowego Gryphon oznacza 24 godziny na dobę, 7 dni w tygodniu, bez przestojów, na konserwację lub naprawę oraz najwyższą wydajność pracy i wydajność.",
            "12": "",
            "13": "Modele Gryphon 4500 są również dostępne w modelach Healthcare, wyposażonych w obudowy do dezynfekcji i antybakteryjne do zastosowań w branży opieki zdrowotnej.",
            "14": "DANE TECHNICZNE",
            "15": "Nazwa:",
            "16": "GRYPHON GM4500",
            "17": "Rodzaj czytnika:",
            "18": "Bezprzewodowy, Imager 2D",
            "19": "Odległość odczytu:",
            "20": "do 71 cm",
            "21": "Warunki pracy:",
            "22": "Temp. 0 – 50°C, wilgotność względna 0-95 %",
            "23": "Wymiary:",
            "24": "166 x 68 x 109 mm (czytnik)",
            "25": "Waga:",
            "26": "235 g (czytnik)",
            "27": "Dostępne interfejsy:",
            "28": "RS232, KBW, USB",
            "29": "Zastosowanie:",
            "30": "Handel, biuro, urzędy, biblioteki, służba zdrowia, magazyn, produkcja",
            "31": "Zasięg:",
            "32": "do 50 cm",
            "33": "Odczytywane kody:",
            "34": "1D",
            "35": "2D"
        },
        url: ImagesScaners.Datalogic_GRYPHON_GM4500_2D
            //url: "src/components/widgets/sortofneedfromnovitu/ItemPhotos/product_scanners/plp-gryphongbt_gm4500-in-stand-black-left-facing_0_Datalogic_GRYPHON_GM4500_2D.jpg",
    },
    {
        name: "Czytnik kodów Datalogic QUICKSCAN QBT2400",
        scales: false,
        printer_register: false,
        cash_register: false,
        online: false,
        new_product: false,
        labeller: false,
        scanner: true,
        describe:{
            "0": "Datalogic QUICKSCAN QBT2400",
            "1": "Najważniejsze cechy czytnika",
            "2": "Odczyt kodów 2D",
            "3": "Łączność bezprzewodowa Bluetooth™ do 25 m.",
            "4": "Multi-interfejs: USB, RS, KBW",
            "5": "Łatwa, beznarzędziowa wymiana baterii",
            "6": "Kompatybilność z urządzniami z systemem Android i iOS poprzez profil Bluetooth HID",
            "7": "Solidna konstrukcja zapewni niezawodność i wytrzymałość",
            "8": "",
            "9": "Czytnik Datalogic QUICKSCAN I QBT2400 (2D) idealnie sprawdza się w punktach obsługi klienta, sprzedaży, gdzie może również być wykorzystany do inwentaryzacji, sprawdzania cen itp. Czytnik wyposażony jest w łączność radiową Bluetooth o zasięgu do 25m.",
            "10": "",
            "11": "DANE TECHNICZNE",
            "12": "Nazwa:",
            "13": "QUICKSCAN QBT2400",
            "14": "Rodzaj czytnika:",
            "15": "Ręczny, bezprzewodowy, area imager",
            "16": "Odległość odczytu:",
            "17": "0,5 – 35 cm (w zależności od gęstości kodu kreskowego)",
            "18": "Rozdzielczość:",
            "19": "4 mils",
            "20": "Wzór skanujący:",
            "21": "n.d.",
            "22": "Warunki pracy:",
            "23": "Temp. 0 – 50°C, wilgotność względna 0 - 90 %",
            "24": "Wymiary:",
            "25": "163 x 91 x 41 mm",
            "26": "Waga:",
            "27": "200 g (bez baterii)",
            "28": "Kabel komunikacyjny:",
            "29": "b.d.",
            "30": "Dostępne interfejsy:",
            "31": "RS232, KBW, USB",
            "32": "Inne:",
            "33": "Przycisk wyzwalający odczyt, możliwość pracy w czasie ładowania w bazie",
            "34": "Zastosowanie:",
            "35": "Handel, biuro, urzędy, biblioteki, służba zdrowia, apteki",
            "36": "Zasięg:",
            "37": "Do 25 m",
            "38": "Odczytywane kody:",
            "39": "2D",
            "40": "Zasięg:",
            "41": "11m – 30m"
        },
        url: ImagesScaners.Datalogic_QUICKSCAN_I_QBT2400_2D
            //url: "src/components/widgets/sortofneedfromnovitu/ItemPhotos/product_scanners/qbt2131_czarny_lewy_profil_Czytnik_Datalogic_QUICKSCAN_I_QBT2400_(2D).jpg",
    },
    {
        name: "Czytnik kodów Datalogic QUICKSCAN QM2131",
        scales: false,
        printer_register: false,
        cash_register: false,
        online: false,
        new_product: false,
        labeller: false,
        scanner: true,
        describe:{
            "0": "Datalogic QUICKSCAN QM2131",
            "1": "Najważniejsze cechy czytnika",
            "2": "",
            "3": "Łączność bezprzewodowa Datalogic Star 2.0 Cordless System™ do 25 m.",
            "4": "Multi-interfejs: USB, RS, KBW",
            "5": "Łatwa, beznarzędziowa wymiana baterii",
            "6": "Solidna konstrukcja zapewni niezawodność i wytrzymałość",
            "7": "Długie kody kreskowe 1D ciągle są powszechnie obecne w wielu zastosowaniach, np.: w identyfikacji rachunków, przetwarzaniu dokumentów i opakowań.",
            "8": "",
            "9": "Wyjątkowo szerokie pole widzenia oraz wyjątkowo długa linia skanowania wyposażonego w mechanizm Linear Imager QuickScan QM2131 sprawia, że czytanie długich kodów kreskowych w krótszej odległości jest znacznie łatwiejsze. Wyraźna, bardzo widoczna linia skanowania sprawia, że skaner jest bardziej przyjazny dla użytkownika w obsłudze, a zwiększona głębia ostrości pozwala na intuicyjny odczyt kodów kreskowych przez użytkownika.",
            "10": "",
            "11": "Wyposażony w system STAR 2.0 Cordless Datalogic o paśmie łączności radiowej 433 MHz, QM2131 zapewnia bezpieczną komunikację radiową bez ryzyka zakłóceń od infrastruktury Wi-Fi i bardzo niskiej wrażliwości na przeszkody, takie jak ściany betonowe, drewno lub metalowe półki.",
            "12": "",
            "13": "DANE TECHNICZNE",
            "14": "Nazwa:",
            "15": "QUICKSCAN QM2131",
            "16": "Rodzaj czytnika:",
            "17": "Ręczny, bezprzewodowy, linear imager",
            "18": "Źródło światła:",
            "19": "Dioda CCD linear imager",
            "20": "Odległość odczytu:",
            "21": "1 – 70 cm (w zależności od gęstości kodu kreskowego)",
            "22": "Rozdzielczość:",
            "23": "4 mils",
            "24": "Szybkość odczytu:",
            "25": "400 skanów/s",
            "26": "Wzór skanujący:",
            "27": "n.d.",
            "28": "Warunki pracy:",
            "29": "Temp. 0 – 50°C, wilgotność względna 0 - 90 %",
            "30": "Wymiary:",
            "31": "163 x 91 x 41 mm",
            "32": "Waga:",
            "33": "190 g (bez baterii)",
            "34": "Kabel komunikacyjny:",
            "35": "b.d.",
            "36": "Dostępne interfejsy:",
            "37": "RS232, KBW, USB",
            "38": "Inne:",
            "39": "Przycisk wyzwalający odczyt, możliwość pracy w czasie ładowania w bazie",
            "40": "Zastosowanie:",
            "41": "Handel, biuro, urzędy, biblioteki, służba zdrowia, apteki",
            "42": "Zasięg:",
            "43": "Do 25 m",
            "44": "Odczytywane kody:",
            "45": "1D",
            "46": "Zasięg:",
            "47": "11m – 30m"
        },
        url: ImagesScaners.Datalogic_QUICKSCAN_I_QM2131
            //url: "src/components/widgets/sortofneedfromnovitu/ItemPhotos/product_scanners/Datalogic_QUICKSCAN_I_QM2131.jpg",
    },
    {
        name: "Czytnik kodów Datalogic QUICKSCAN QBT2400",
        scales: false,
        printer_register: false,
        cash_register: false,
        online: false,
        new_product: false,
        labeller: false,
        scanner: true,
        describe:{
            "0":"Datalogic QUICKSCAN QM2400",
            "1": "Najważniejsze cechy czytnika",
            "2": "Odczyt kodów 2D",
            "3": "Łączność bezprzewodowa Datalogic Star 2.0 Cordless System™ do 25 m.",
            "4": "Multi-interfejs: USB, RS, KBW",
            "5": "Łatwa, beznarzędziowa wymiana baterii",
            "6": "Solidna konstrukcja zapewni niezawodność i wytrzymałość",
            "7": "Czytnik Datalogic QUICKSCAN I QM2400 (2D) idealnie sprawdza się w punktach obsługi klienta, sprzedaży, gdzie może również być wykorzystany do inwentaryzacji, sprawdzania cen itp. Czytnik wyposażony jest w łączność radiową Datalogic Star 2.0 Cordless System o zasięgu do 25m.",
            "8": "DANE TECHNICZNE",
            "9": "Nazwa:",
            "10": "QUICKSCAN QM2400",
            "11": "Rodzaj czytnika:",
            "12": "Ręczny, bezprzewodowy, area imager",
            "13": "Odległość odczytu:",
            "14": "0,5 – 35 cm (w zależności od gęstości kodu kreskowego)",
            "15": "Rozdzielczość:",
            "16": "4 mils",
            "17": "Wzór skanujący:",
            "18": "n.d.",
            "19": "Warunki pracy:",
            "20": "Temp. 0 – 50°C, wilgotność względna 0 - 90 %",
            "21": "Wymiary:",
            "22": "163 x 91 x 41 mm",
            "23": "Waga:",
            "24": "200 g (bez baterii)",
            "25": "Kabel komunikacyjny:",
            "26": "b.d.",
            "27": "Dostępne interfejsy:",
            "28": "RS232, KBW, USB",
            "29": "Inne:",
            "30": "Przycisk wyzwalający odczyt, możliwość pracy w czasie ładowania w bazie",
            "31": "Zastosowanie:",
            "32": "Handel, biuro, urzędy, biblioteki, służba zdrowia, apteki",
            "33": "Zasięg:",
            "34": "Do 25 m",
            "35": "Odczytywane kody:",
            "36": "2D",
            "37": "Zasięg:",
            "38": "11m – 30m"
        },
        url: ImagesScaners.Datalogic_QUICKSCAN_I_QM2400
            //url: "src/components/widgets/sortofneedfromnovitu/ItemPhotos/product_scanners/Datalogic_QUICKSCAN_I_QM2400.jpg",
    },
    {
        name: "Czytnik kodów Datalogic QUICKSCAN QBT2131",
        scales: false,
        printer_register: false,
        cash_register: false,
        online: false,
        new_product: false,
        labeller: false,
        scanner: true,
        describe:{
            "0": "Datalogic QUICKSCAN QBT2131",
            "1": "Najważniejsze cechy czytnika",
            "2": "",
            "3": "Łączność bezprzewodowa Bluetooth™ do 25 m.",
            "4": "Multi-interfejs: USB, RS, KBW",
            "5": "Łatwa, beznarzędziowa wymiana baterii",
            "6": "Kompatybilność z urządzniami z systemem Android i iOS poprzez profil Bluetooth HID",
            "7": "Solidna konstrukcja zapewni niezawodność i wytrzymałość",
            "8": "",
            "9": "Długie kody kreskowe 1D ciągle są powszechnie obecne w wielu zastosowaniach, np.: w identyfikacji rachunków, przetwarzaniu dokumentów i opakowań.",
            "10": "",
            "11": "Wyjątkowo szerokie pole widzenia oraz wyjątkowo długa linia skanowania wyposażonego w mechanizm Linear Imager QuickScan QBT2131 sprawia, że czytanie długich kodów kreskowych w krótszej odległości jest znacznie łatwiejsze. Wyraźna, bardzo widoczna linia skanowania sprawia, że skaner jest bardziej przyjazny dla użytkownika w obsłudze, a zwiększona głębia ostrości pozwala na intuicyjny odczyt kodów kreskowych przez użytkownika.",
            "12": "",
            "13": "Wyposażony w technologię bezprzewodową Bluetooth, skaner QuickScan QBT2131 może transmitować dane do hosta za pośrednictwem stacji bazowej, jak również do każdego urządzenia zgodnego z Bluetooth.",
            "14": "QBT2131 Linear Imager może być również podłączony do dowolnego tabletu przy użyciu obu systemów operacyjnych iOS lub Android ™ za pośrednictwem profilu Bluetooth HID.",
            "15": "",
            "16": "Dostępna jest także wersja QBT2101 bez stacji ładująco-komunikacyjnej. W zestawie znajduje się skaner z kablem USB. Jest to zestaw ułatwiajacy zastosowanie skanera np. z laptopami .",
            "17": "",
            "18": "DANE TECHNICZNE",
            "19": "Nazwa:",
            "20": "Datalogic QUICKSCAN QBT2131",
            "21": "Rodzaj czytnika:",
            "22": "Ręczny, bezprzewodowy, linear imager",
            "23": "Źródło światła:",
            "24": "Dioda CCD linear imager",
            "25": "Odległość odczytu:",
            "26": "1 – 70 cm (w zależności od gęstości kodu kreskowego)",
            "27": "Rozdzielczość:",
            "28": "4 mils",
            "29": "Szybkość odczytu:",
            "30": "400 skanów/s",
            "31": "Wzór skanujący:",
            "32": "n.d.",
            "33": "Warunki pracy:",
            "34": "Temp. 0 – 50°C, wilgotność względna 0 - 90 %",
            "35": "Wymiary:",
            "36": "163 x 91 x 41 mm",
            "37": "Waga:",
            "38": "190 g (bez baterii)",
            "39": "Kabel komunikacyjny:",
            "40": "b.d.",
            "41": "Dostępne interfejsy:",
            "42": "RS232, KBW, USB",
            "43": "Inne:",
            "44": "Przycisk wyzwalający odczyt, możliwość pracy w czasie ładowania w bazie",
            "45": "Zastosowanie:",
            "46": "Handel, biuro, urzędy, biblioteki, służba zdrowia, apteki",
            "47": "Zasięg:",
            "48": "Do 25 m",
            "49": "Odczytywane kody:",
            "50": "1D",
            "51": "Zasięg:",
            "52": "11m – 30m"
        },
        url: ImagesScaners.qbt2131_czarny_lewy_profil
            //url: "src/components/widgets/sortofneedfromnovitu/ItemPhotos/product_scanners/qbt2131_czarny_lewy_profil.jpg",
    },
    {
        name: "Czytnik kodów Honeywell Voyager 1202g",
        scales: false,
        printer_register: false,
        cash_register: false,
        online: false,
        new_product: false,
        labeller: false,
        scanner: true,
        describe:{
            "0": "Honeywell Voyager 1202g",
            "1": "Najważniejsze cechy:",
            "2": "",
            "3": "Odporność na upadek z wysokości 1,2 m",
            "4": "Odporność na warunki środowiskowe IP42",
            "5": "Zasięg baza-skaner do 30m",
            "6": "Długi czas pracy - do 12 godzin po naładowaniu akumulatora",
            "7": "",
            "8": "Voyager 1202g to bezprzewodowy czytnik kodów kreskowych 1D. Dzięki nowoczesnej baterii Li-ION umożliwia wykonanie 45000 skanów na jednym ładowaniu (spodziewany czas pracy to 12 godzin). Bluetooth Class 2 umożliwia komunikację pomiędzy skanerem a bazą w odległości do 30m w otwartej przestrzeni. Urządzenie jest odporne na wielokrotny upadek z wysokości 1,2 m.",
            "9": "DANE TECHNICZNE",
            "10": "Nazwa:",
            "11": "Voyager 1202g",
            "12": "Rodzaj czytnika:",
            "13": "Bezprzewodowy, Bluetooth",
            "14": "Źródło światła:",
            "15": "Dioda laserowa",
            "16": "Odległość odczytu:",
            "17": "b.d",
            "18": "Rozdzielczość:",
            "19": "b.d.",
            "20": "Szybkość odczytu:",
            "21": "Do 100 skanów na sekundę",
            "22": "Wzór skanujący:",
            "23": "n.d.",
            "24": "Warunki pracy:",
            "25": "Temp. 5 - 40°C, wilgotność względna 0 - 95 %",
            "26": "Wymiary:",
            "27": "180 x 66 x 92 mm (skaner)",
            "28": "Waga:",
            "29": "180 g (skaner) / 185 g (baza)",
            "30": "Dostępne interfejsy:",
            "31": "USB, PS2, RS232, IBM 46xx (RS485)",
            "32": "Zastosowanie:",
            "33": "Handel, biuro, urzędy, biblioteki, stacje benzynowe, apteki, magazyn",
            "34": "Zasięg:",
            "35": "do 10 m w otwartej przestrzeni"
        },
        url: ImagesScaners.Honeywell_Voyager_1202g
            //url: "src/components/widgets/sortofneedfromnovitu/ItemPhotos/product_scanners/voyager_1202g_p_01small_Honeywell_Voyager_1202g.jpg",
    },
    {
        name: "Czytnik kodów Honeywell Xenon 1902",
        scales: false,
        printer_register: false,
        cash_register: false,
        online: false,
        new_product: false,
        labeller: false,
        scanner: true,
        describe:{
            "0": "Honeywell Xenon 1902",
            "1": "",
            "2": "Najważniejsze cechy czytnika",
            "3": "Szybki odczyt kodów 1D i 2D",
            "4": "Akumulator Li-Ion umożliwiający 50000 skanów",
            "5": "Bluetooth Class 2 - zasięg 10m",
            "6": "Przycisk uruchamiający skanowanie",
            "7": "Xenon 1902 to bezprzewodowy czytnik kodów 1D i 2D. Dużej pojemności akumulator zapewnia możliwość wykonania do 50000 skanów. Mechanizm odczytu o rozdzielczości 838 x 640 pikseli umożliwia odczyt kodów dwuwymiarowych i wykonywanie zdjęć (funkcja przydatna np. do obsługi reklamacji w punkcie obsługi klienta).",
            "8": "",
            "9": "DANE TECHNICZNE",
            "10": "Nazwa:",
            "11": "Xenon 1902",
            "12": "Rodzaj czytnika:",
            "13": "Area Imager 2D",
            "14": "Rozdzielczość:",
            "15": "838 x 640 pikseli",
            "16": "Szybkość odczytu:",
            "17": "b.d.",
            "18": "Wzór skanujący:",
            "19": "n.d.",
            "20": "Warunki pracy:",
            "21": "Temp. 0 – 50°C, wilgotność względna 0 - 95 %",
            "22": "Wymiary:",
            "23": "104 x 71 x 160 mm (skaner)",
            "24": "Waga:",
            "25": "214g (skaner) / 179g (baza)",
            "26": "Kabel komunikacyjny:",
            "27": "n.d.",
            "28": "Dostępne interfejsy:",
            "29": "Bluetooth Class 2",
            "30": "Inne:",
            "31": "przycisk wyzwalający odczyt,",
            "32": "Zastosowanie:",
            "33": "Handel, biuro, bank, stacje paliw",
            "34": "Zasięg:",
            "35": "10 m"
        },
        url: ImagesScaners.Honeywell_Xenon_1902
            //url: "src/components/widgets/sortofneedfromnovitu/ItemPhotos/product_scanners/xenon_1902_product_01small_Honeywell_Xenon_1902.jpg",
    },
    {
        name: "Czytnik kodów NEWLAND BS8050-2V (2D) i BS8050-3V (1D) PIRANHA",
        scales: false,
        printer_register: false,
        cash_register: false,
        online: false,
        new_product: false,
        labeller: false,
        scanner: true,
        describe:{
            "0": "NEWLAND BS8050-2V (2D) i BS8050-3V (1D) PIRANHA",
            "1": "",
            "2": "Najważniejsze cechy czytnika",
            "3": "Komunikacja bezprzewodowa Bluetooth v.4 do 50 m",
            "4": "Do 12 godzin pracy bez ładowania",
            "5": "Odczyt kodów 1D i 2D (tylko wersja BS8050-2V)",
            "6": "Odporność na kurz i wilgoć IP42",
            "7": "",
            "8": "Czytnik kodów paskowych 2D (model BS8050-2V) o niewielkich, kieszonkowych rozmiarach z komunikacją Bluetooth klasy 4.0 odczytuje kody 1D i 2D. Ergonomiczny kształt o niewielkich wymiarach 88 x 46 x24.5mm sprawia, że urządzenie mieści się „w kieszeni”, wytrzymała bateria pozwala na pracę do 12 godzin. Zasięg w terenie otwartym do 50m. Dodatkowo pamięć 1 MB umożliwia zapamiętywanie kodów przy pracy poza zasięgiem odczytu.",
            "9": "Czytnik współpracuje z systemami iOS, Android oraz Windows. Przeznaczony jest do zastosowań mobilnych – praca z tabletami, smartfonami, terminalami danych wyposażonymi w moduł BT.",
            "10": "Urządzenie przeznaczone jest do pracy w warunkach temperatury w zakresie od -20° do 50° C.",
            "11": "",
            "12": "DANE TECHNICZNE",
            "13": "Nazwa:",
            "14": "NEWLAND BS8050-2V i -3V",
            "15": "Rodzaj czytnika:",
            "16": "Bezprzewodowy, CMOS (-2V) / Linear Imager (-3V)",
            "17": "Odległość odczytu:",
            "18": "do 33,5cm (-2V) / do 40cm (-3V)",
            "19": "Rozdzielczość:",
            "20": "5 mil",
            "21": "Warunki pracy:",
            "22": "Temp. -20 – 50 °C, wilgotność względna 5 – 95 %",
            "23": "Wymiary:",
            "24": "88,0 x 46,0 x 24,5 mm",
            "25": "Waga:",
            "26": "62 g (czytnik)",
            "27": "Kabel komunikacyjny:",
            "28": "n.d.",
            "29": "Dostępne interfejsy:",
            "30": "microUSB,",
            "31": "Inne:",
            "32": "przycisk wyzwalający odczyt",
            "33": "Zastosowanie:",
            "34": "handel, magazyn, praca mobilna",
            "35": "Zasięg:",
            "36": "do 50 m w otwartym terenie",
            "37": "Odczytywane kody:",
            "38": "2D",
            "39": "Zasięg:",
            "40": "31m - 50m"
        },
        url: ImagesScaners.bs8050_2v_NEWLAND_BS8050_2V_2D_i_BS8050_3V_1D_PIRANHA
            //url: "src/components/widgets/sortofneedfromnovitu/ItemPhotos/product_scanners/bs8050-2v_NEWLAND_BS8050-2V_(2D)_i_BS8050-3V_(1D)_PIRANHA.png",
    },
    {
        name: "Czytnik kodów NEWLAND HR1550-3E WAHOO",
        scales: false,
        printer_register: false,
        cash_register: false,
        online: false,
        new_product: false,
        labeller: false,
        scanner: true,
        describe:{
            "0": "NEWLAND HR1550-3E WAHOO",
            "1": "Najważniejsze cechy czytnika",
            "2": "Komunikacja bezprzewodowa 2,4 GHz do 50 m",
            "3": "Bateria 2200mAh",
            "4": "Odczyt kodów 1D i PDF417",
            "5": "Odporność na kurz i wilgoć IP52",
            "6": "",
            "7": "Czytnik HR1550 Wahoo to młodszy brat modelu HR3260 2D pracujący w standardzie ZigBee (komunikacja bezprzewodowa).  Oprócz kodów 1D odczytuje również kody PDF. Akumulator o pojemności 2200 mAh osiąga pełne naładowanie już po 3,5 godzinach i umożliwia odczyt około 60000 kodów przy jednym ładowaniu. Czytnik idelany do palcówek handlowych, magazynów.",
            "8": "",
            "9": "DANE TECHNICZNE",
            "10": "Nazwa:",
            "11": "NEWLAND HR1550-3E WAHOO",
            "12": "Rodzaj czytnika:",
            "13": "Bezprzewodowy,",
            "14": "Źródło światła:",
            "15": "LED 620nm",
            "16": "Odległość odczytu:",
            "17": "b.d.",
            "18": "Rozdzielczość:",
            "19": "5 mil",
            "20": "Szybkość odczytu:",
            "21": "350 skanów/sekundę",
            "22": "Warunki pracy:",
            "23": "Temp. -10 – 50 °C, wilgotność względna 5 – 95 %",
            "24": "Wymiary:",
            "25": "113,5 x 73 x 159 mm",
            "26": "Waga:",
            "27": "210 g (czytnik), 150g (baza)",
            "28": "Kabel komunikacyjny:",
            "29": "n.d.",
            "30": "Dostępne interfejsy:",
            "31": "RS232, USB,",
            "32": "Inne:",
            "33": "przycisk wyzwalający odczyt",
            "34": "Zastosowanie:",
            "35": "handel, magazyn, produkcja, spedycja",
            "36": "Zasięg:",
            "37": "do 50 m w otwartym terenie",
            "38": "Odczytywane kody:",
            "39": "1D",
            "40": "Zasięg:",
            "41": "31m - 50m"
        },
        url: ImagesScaners.NEWLAND_HR1550_3E_WAHOO
            //url: "src/components/widgets/sortofneedfromnovitu/ItemPhotos/product_scanners/hr1550_wahoo_NEWLAND_HR1550-3E_WAHOO.jpg",
    },
    {
        name: "Czytnik kodów NEWLAND HR3260-CS MARLIN",
        scales: false,
        printer_register: false,
        cash_register: false,
        online: false,
        new_product: false,
        labeller: false,
        scanner: true,
        describe:{
            "0": "NEWLAND HR3260-CS MARLIN",
            "1": "",
            "2": "Najważniejsze cechy czytnika",
            "3": "Komunikacja bezprzewodowa 2,4 GHz do 50 m",
            "4": "Odległość odczytu do 29 cm",
            "5": "Bateria 2200mAh",
            "6": "Odczyt kodów 1D i 2D",
            "7": "",
            "8": "Czytnik HR3260 Marlin to bezprzewodowy czytnik kodów kreskowych odczytujących kody zarówno 1D jak i 2D w technologii radiowej ZigBee. Komunikacja w tym systemie zapewnia pracę o zasięgu do 50 m. Mocna i pojemna bateria gwarantuje odczyt do 15 tysięcy kodów, a czas potrzebny do całkowitego naładowania baterii  to ok. 4,5 godz. Przeznaczony jest do zastosowań detalicznych, magazynów.",
            "9": "DANE TECHNICZNE",
            "10": "Nazwa:",
            "11": "NEWLAND HR3260-CS MARLIN",
            "12": "Rodzaj czytnika:",
            "13": "Bezprzewodowy, CMOS 752x480",
            "14": "Odległość odczytu:",
            "15": "30-290 mm (w zależności od rodzaju i gęstości kodu kreskowego)",
            "16": "Rozdzielczość:",
            "17": "5 mil",
            "18": "Warunki pracy:",
            "19": "Temp. -20 – 50 °C, wilgotność względna 5 – 93 %",
            "20": "Wymiary:",
            "21": "113,5 x 73 x 159 mm",
            "22": "Waga:",
            "23": "252 g (czytnik), 150g (baza)",
            "24": "Kabel komunikacyjny:",
            "25": "n.d.",
            "26": "Dostępne interfejsy:",
            "27": "RS232, USB,",
            "28": "Inne:",
            "29": "przycisk wyzwalający odczyt",
            "30": "Zastosowanie:",
            "31": "handel, magazyn, produkcja, spedycja",
            "32": "Zasięg:",
            "33": "do 50 m w otwartym terenie",
            "34": "Odczytywane kody:",
            "35": "2D"
        },
        url: ImagesScaners.NEWLAND_HR3260_CS_MARLIN
            //url: "src/components/widgets/sortofneedfromnovitu/ItemPhotos/product_scanners/hr3260_marlin_NEWLAND_HR3260-CS_MARLIN.jpg",
    },
    {
        name: "Czytnik kodów Zebra DS2278",
        scales: false,
        printer_register: false,
        cash_register: false,
        online: false,
        new_product: false,
        labeller: false,
        scanner: true,
        describe:{
            "0": "Zebra DS2278",
            "1": "",
            "2": "Najważniejsze cechy czytnika:",
            "3": "• Przycisk wyzwalający odczyt",
            "4": "• Odporny na upadek z wysokości 1,5 m",
            "5": "• Komunikacja Bluetooth",
            "6": "• Możliwość odczytu kodów 1D i 2D, także z wyświetlaczy telefonów",
            "7": "• Szeroki zakres odległości odczytu kodów kreskowych 1,3cm do 36cm",
            "8": "",
            "9": "DANE TECHNICZNE",
            "10": "Nazwa:",
            "11": "DS2278",
            "12": "Rodzaj czytnika:",
            "13": "Area Imager",
            "14": "Odległość odczytu:",
            "15": "1,3cm-36cm (w zależności od gęstości kodu)",
            "16": "Warunki pracy:",
            "17": "Temp. 0 – 50 C, wilgotność względna 5 – 85 %",
            "18": "Wymiary:",
            "19": "17,5 wys. x 6,6 szer. x 9.9 gł. [cm]",
            "20": "Dostępne interfejsy:",
            "21": "USB, RS232, PS2, IBM",
            "22": "Inne:",
            "23": "przycisk wyzwalający odczyt, praca automatyczna, możliwość ładowania za pomocą kabla micro USB",
            "24": "Zastosowanie:",
            "25": "Handel, biuro, urzędy, biblioteki, stacje benzynowe,",
            "26": "Odczytywane kody:",
            "27": "2D",
            "28": "Zasięg:",
            "29": ">50m"
        },
        url: ImagesScaners.Zebra_DS2278
            //url: "src/components/widgets/sortofneedfromnovitu/ItemPhotos/product_scanners/ds2278_in_cradle_135-web-72dpi_Zebra_DS2278.jpeg",
    },
    {
        name: "Czytnik kodów Zebra LI4278 - dawniej Motorola",
        scales: false,
        printer_register: false,
        cash_register: false,
        online: false,
        new_product: false,
        labeller: false,
        scanner: true,
        describe:{
            "0": "Zebra LI4278 - dawniej Motorola",
            "1": "Zebra LI4278 to bezprzewodowy skaner typu Linear Imager, umożliwiający odczyt typowych kodów EAN/UPC w odległości od 2,54cm do 76,20cm. Komunikacja Bluetooth umożliwia wymianę danych pomiędzy skanerem i bazą w odległości do 100m (w lini prostej, bez przeszkód).",
            "2": "DANE TECHNICZNE",
            "3": "Nazwa:",
            "4": "LI4278",
            "5": "Rodzaj czytnika:",
            "6": "Linear Imager",
            "7": "Odległość odczytu:",
            "8": "12,7cm - 6m (Code39, w zależności od gęstości kodu)",
            "9": "Wzór skanujący:",
            "10": "Pojedyncza linia skanująca",
            "11": "Warunki pracy:",
            "12": "Temp. 0 – 50 C, wilgotność względna 5 – 85 %",
            "13": "Wymiary:",
            "14": "98 x 70 x 186 mm",
            "15": "Waga:",
            "16": "224g",
            "17": "Dostępne interfejsy:",
            "18": "USB, RS232, PS2",
            "19": "Inne:",
            "20": "przycisk wyzwalający odczyt",
            "21": "Zastosowanie:",
            "22": "Handel, biuro, urzędy, biblioteki, stacje benzynowe,",
            "23": "Odczytywane kody:",
            "24": "1D",
            "25": "Zasięg:",
            "26": ">50m"
        },
        url: ImagesScaners.black_Zebra_LI4278_dawniej_Motorola
            //url: "src/components/widgets/sortofneedfromnovitu/ItemPhotos/product_scanners/li4278_linear_imager_-_black_Zebra_LI4278-dawniej_Motorola.jpg",
    },
];

//
export const arrayInfoForLaw = [
    {
        name: "Przywoływacz with label",
        scales: false,
        printer_register: false,
        cash_register: false,
        online: false,
        new_product: false,
        labeller: false,
        scanner: false,
        describe:{
            "0": "Przywoływacz wydawany klientowi po przyjęciu zamówienia sygnalizuje za pomocą wibracji i migotania gotowość zamówienia do odbioru. Istnieje możliwość personalizacji za pomocą naklejek umieszczanych na urządzeniu.",
            "1": "",
            "2": "",

        },
        url: ImagePagers.PAGER_sticker_client
            //url: "src/components/widgets/sortofneedfromnovitu/ItemPhotos/accesories_gastro/przywolywacz_(number_sticker).jpg",
    },
];

export const allArraysOfProductsNonUnique = [
    arrayCashier, 
    arrayCashierOnline, 
    arrayCzytniki1, 
    arrayDrawers,
    arrayGastroAccesories,
    arrayLabellers,
    arrayMetkownice,
    arrayPOS,
    arrayPayTerminals,
    arrayPrinters,
    arrayScales
    ].flatMap((a)=>{return a})

    function objCompare( thisObj, nextObj ) {
        return Object.keys( thisObj ).sort().toString() === Object.keys( nextObj )
        .sort().toString() && Object.values( thisObj )
        .sort().toString() === Object.values( nextObj )
        .sort().toString();
    }

    function filterDuplicates( array ) {
        return array.filter( ( element, index ) => {
           return index === array.findIndex( toCompare => objCompare( element, toCompare ) );
        });
    }

    //export const allArraysOfProductsUnique = Array.from(new Set(allArraysOfProductsNonUnique));???
    export const allArraysOfProductsUnique = filterDuplicates(allArraysOfProductsNonUnique);
    //export const allArraysOfProductsUnique = Array.from([...new Set(allArraysOfProductsNonUnique)]).flatMap((a)=>{return a});???
    //return [ ...new Set( array ) ];????