
export const Googl_map_provider = () => {

    const styles2={
      display: "flex",
      border: '.5px dotted green',
      width: '100%',
      padding: '10px 0',
      height: '700px',
      textAlign: 'center'
    }
    return(
    <div style={{background: 'olivedrab', border: '.5px dotted green'}}>
      <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2315.648674102283!2d18.53727531546471!3d54.52205709369691!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x46fda7321ee12fd5%3A0xdc041143b8d8f7ee!2sInvestim!5e0!3m2!1spl!2snl!4v1633999295747!5m2!1spl!2snl" style={styles2} allowfullscreen="" loading="lazy"></iframe>
    </div>
    );
};

 /*<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2315.648674102283!2d18.53727531546471!3d54.52205709369691!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x46fda7321ee12fd5%3A0xdc041143b8d8f7ee!2sInvestim!5e0!3m2!1sen!2snl!4v1633969828787!5m2!1sen!2snl" style={styles2} allowfullscreen="" loading="lazy">
    </iframe>*/