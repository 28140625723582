import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';


export class Products extends Component {
  constructor() {
    super();
    
    this.state = {
      showMenu: false,
    };
    
    this.stylesLink = {
      color: 'dark',
      textDecoration: 'none',
      background: 'whitesmoke',
      display: 'block',
      padding: '0.7vh',
      position: 'relative',
      opacity: '1'
    };
    
    this.stylelogoout = {
      color: 'white',
      textDecoration: 'none'
    };

    this.stylelogoout = {
      color: 'white',
      textDecoration: 'none',
      background: ''
    };
    
    this.dropdown={
      position:'absolute'
    };

    this.showMenu = this.showMenu.bind(this);
    this.closeMenu = this.closeMenu.bind(this);   
  }

  showMenu(event) {
    event.preventDefault();
    this.setState({ showMenu: true }, () => {
      console.log("showmenuu");
      document.addEventListener('click', this.closeMenu);
    });
  }
  
  closeMenu(event) {
    event.preventDefault();
    this.setState({ showMenu: false }, () => {
      console.log("closemenuu");
      document.removeEventListener('click', this.closeMenu);
    });     
  }
  
  render() {
    return (
      <div className="toggle">
      <div onClick={this.showMenu} onMouseMove={this.showMenu} onMouseLeave={this.closeMenu}>
        <li>
          <NavLink to="/productslist" id="navlinke" style={this.stylelogoout}>
            Produkty
          </NavLink>
        </li>
        {
          this.state.showMenu
            ? (
              <div
                style={this.dropdown}
                className="aboutmenu"
                ref={(element) => {
                  this.dropdownMenu = element;
                }}
              >
                <NavLink to="/cash_register" className="togglelinks" style={this.stylesLink}>Kasy fiskalne</NavLink>
                <NavLink to="/fiscal_printers" className="togglelinks" style={this.stylesLink}>Drukarki fiskalne</NavLink>
                <NavLink to="/online" className="togglelinks" style={this.stylesLink}>Kasy i drukarki Online</NavLink>
                <NavLink to="/payment_terminal" className="togglelinks" style={this.stylesLink}>Terminale</NavLink>
                <NavLink to="/scales" className="togglelinks" style={this.stylesLink}>Wagi</NavLink>
                <NavLink to="/drawers" className="togglelinks" style={this.stylesLink}>Szuflady</NavLink>
                <NavLink to="/scanners" className="togglelinks" style={this.stylesLink}>Czytniki</NavLink>
                <NavLink to="/pos" className="togglelinks" style={this.stylesLink}>Systemy POS</NavLink>
                <NavLink to="/labellers" className="togglelinks" style={this.stylesLink}>Metkownice</NavLink>
                <NavLink to="/other" className="togglelinks" style={this.stylesLink}>Inne</NavLink>
              </div>
            )
            : (null)
        }
      </div>
      </div>
    );
  }
}