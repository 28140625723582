import {arrayLabellers} from './widgets/JsonData';
import {GeneratorItems} from './widgets/GeneratorItems';

const styles = {
    background: 'darkorange',
    padding: '25px',
    marginBottom: '55px',
    opacity: '1',
    textAlign: 'justify',
    borderRadius: '5px'
};
const header = {
    background: "darkorange",
    paddingTop: "10px",
    paddingBottom: "10px",
    textAlign: "center",
    borderRadius: "10px",
    opacity: "1",
    fontSize: "large",
    color: "whitesmoke"
}

export const Labellers = () => (
    <div className="maincontent">
        <div style={header}>
            <h2 className="titleProducts">Metkownice: </h2>
        </div>
        <div style={styles}>
            {GeneratorItems(arrayLabellers)}
        </div>

    </div>
);