import {ImagesMaps} from './widgets/Images';
import {Googl_map_provider} from './widgets/providerGooglmap';

    
const styles = {
    background: 'antiquewhite',
    padding: '25px',
    marginBottom: '55px',
    opacity: '0.85',
    textAlign: 'justify',
    borderRadius: '5px'
};
const imgStyles = {
    margin: 'auto'
}

const frameCustomMap = {
    backgroundColor: 'whitesmoke',
    paddingBottom: '25px',
    paddingTop: '25px',
    textAlign: 'center'
}
const frameGoogleMap = {
    backgroundColor: 'orange',
    //paddingRight: '25px',
    //paddingleft: '25px',
    textAlign: 'center',
    //border:"solid limegreen 3px",
    borderRadius:"3px"
}
const contactContainer = {
    backgroundColor: 'olivedrab',
    opacity: '1',
    color: 'white',
    fontSize:'20px',
    listStyle: 'none',
    borderRadius:'5px',
    border:'.5px dotted green',
    padding:'5px'
 }

 const h2Style = {
    background: 'olivedrab',
    borderRadius: '3px',
    color: '#f3fdccc7',
    margin: 'auto',
    textAlign: 'center'
 }
 const contentContactStyle = {
    //width: '63%',
 }

export const Contact = () => (
    <div className="maincontent">
        <h2 style={h2Style}>Kontakt do biura i mapa dojazdu</h2>
        <div style={styles}>
            <ul style={contentContactStyle}>
                <div style={contactContainer}>
                <li>Telefon stacjonarny: 58 624 40 74</li>
                <li>Telefon kom: 501 735 351</li>
                <li>Telefon kom: 507 156 890</li>
                <li>E-mail: biuro@investim.pl</li>
                <li>Adres biura:<br/>ul. Władysława IV 7-15,<br/> 81-353 Gdynia</li>
                <p>Jesteśmy otwarci od poniedziałku do piątku w godzinach: 9.00-17.00</p>
                </div>
            </ul>
            <ul>
                <div style={frameGoogleMap}>
                    <span style={imgStyles}>{Googl_map_provider()}</span>
                    <img alt="logo" style={imgStyles} src={ImagesMaps.MapContact_gif}/>
                </div>
            </ul>
        </div>
    </div>
);